import React, {useEffect, useState} from "react";
import ResponseDigitalAccount from "../Models/Response/ResponseDigitalAccount";
import useApiCallService from "../../Common/Helpers/ApiCall";
import {Card, Col, Row} from "reactstrap";
import SortableList from "../../Common/Components/Lists/SortableList";
import {CustomButton} from "../../Common/Components/Button/CustomButton";
import {useTranslation} from "react-i18next";
import { ORGANIZATION_PATHS} from "../../config";
import {useNavigate} from "react-router-dom";
import ResponseAccountRowModel from "../Models/Response/ResponseAccountRowModel";
import {CardDigitalAccount} from "../Components/CardDigitalAccount";
import {ToCustomDecimal} from "../../AppSystem/Helpers/ToCustomDecimal";
import {UrlStructure} from "../../AppSystem/Helpers/UrlStructure";

export function DigitalAccount() {
    const { t } = useTranslation();
    const {ApiCall} = useApiCallService();
    const navigate = useNavigate();

    // API RESPONSES
    const [responseDigitalAccount, setResponseDigitalAccount] = useState(new ResponseDigitalAccount());

    //GET DIGITAL ACCOUNT
    useEffect(() => {
        //if(responseUserSettings !== null && responseUserSettings.clearingNumber === ''){
            const handleDigitalAccountResponse = (responseAccountRowList) => {
                if(responseAccountRowList !== null) {
                    setResponseDigitalAccount(responseAccountRowList);
                }
            };
            const DigitalAccountApiCall = async () => {
                try {
                    const apiCallOptions = {
                        method: "get",
                        url: "/digital-account",
                    };
                    await ApiCall(apiCallOptions, handleDigitalAccountResponse);
                } catch (error) {
                    console.log('error', error);
                }
            };
            DigitalAccountApiCall();
      //  }

    }, []);


    const renderActionColumn = (item) => {
        return (
            <>
                <CustomButton icon = "bx bx-show py-1" iconColor="#157553" hoverIconColor='white' hoverButtonColor='#A3C2B8' iconSize={4}  buttonColor="#cde0da"  tooltipText={t('button.info')} customClasses="rounded" buttonBorder={false} id={item.id} onClick={() => {
                    navigate(`${ORGANIZATION_PATHS.ACCOUNT_ROW_INFO}?id=${item.id}`);
                }} />
            </>
        );
    }

    const columns = [ "visualId", 'model','description' , 'createdAt','amount',];
    const listOptions = {
        "model": ResponseAccountRowModel,
        'columns':columns,
        "actions": {
            "actionColumn" : renderActionColumn,
        },
        "exportIsAvailable": true,
        "urlGetListItemsToExport": "/digital-account/account-row/search",
        "nameFile": t('digitalAccount.label'),
        "ignoreFields" : columns,
        "urlGetSearch" : "/digital-account/account-row/search",
    };

    return(
        <>
            <Col lg={12}>
                <Row className="pb-5">
                    <Col lg={12} className="p-4 pb-3 card-backend position-relative">
                        {responseDigitalAccount.visualId !== "" ?
                            <div className="position-absolute end-0 mt-4 me-4 top-0 fs-5">{responseDigitalAccount.visualId}</div>
                        : ""}
                        <h2 className="rokkitt-font">{t('digitalAccount.label')}</h2>
                        <Row>
                            <Col lg={4}>
                                <CardDigitalAccount data={responseDigitalAccount}/>
                            </Col>
                        </Row>
                    </Col>
                    {/*{responseAccountRowList != null ?*/} {/*See how to do this check, so that if they do not have an account row, the card does not appear*/}
                        <Col lg={12} className="mt-4 card-backend p-4">
                            <h2 className="fw-bold rokkitt-font">{t('digitalAccount.list')}</h2>
                            <SortableList
                                listOptions={listOptions}
                            />
                        </Col>
                    {/*: ""}*/}
                </Row>
            </Col>
        </>
    )

}