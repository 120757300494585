import * as yup from "yup";
import {string} from "yup";
import i18n from "i18next";

class RequestUserSettingsModel {
    constructor({
                    clearingNumber = '',
                    accountNumber = '',
                } = {}) {
        this.clearingNumber = clearingNumber;
        this.accountNumber = accountNumber;
    }

    static getAttributes(filter = null, validationSchema = null, optionsData = null) {
        const clearingNumber = i18n.t('userSetting.modelClearingNumber');
        const accountNumber = i18n.t('userSetting.modelAccountNumber');
        const enterClearingNumber = i18n.t('formPlaceholder.modelEnterClearingNumber');
        const enterAccountNumber = i18n.t('formPlaceholder.modelEnterAccountNumber');
        const fieldRequired = i18n.t('validationMessage.fieldRequired');
        const greaterO = i18n.t('validationMessage.greater0');
        const minDigit4 = i18n.t('validationMessage.minDigit4');
        const placeholderDigits = i18n.t('formPlaceholder.digits');

        const completeSchema = yup.object().shape({
            clearingNumber: string().required(fieldRequired).test('is-positive', minDigit4, value => {
                    const numValue = parseFloat(value/*.replace(',', '.')*/);
                    return !isNaN(numValue) && numValue > 0 && value.length >= 4 && /^\d+$/.test(value);
                }),
            accountNumber: string().required(fieldRequired).test('is-positive', greaterO, value => {
                const numValue = parseFloat(value/*.replace(',', '.')*/);
                return !isNaN(numValue) && numValue > 0;
            }),
        });

        const attributes = {
            clearingNumber :{
                'label': clearingNumber,
                'form': {
                    'type': 'text',
                    'placeholder': "4-5 " + placeholderDigits,
                    'validation': completeSchema.fields.clearingNumber,
                    "oneDecimal": false,
                },
                'sortable': false,
                'filter': false,
                'id':'clearingNumber',
                'name':'clearingNumber',
                'value': (item) => item.clearingNumber || "",
            },
            accountNumber :{
                'label': accountNumber,
                'form': {
                    'type': 'text',
                    'placeholder': "123456789012",
                    'validation': completeSchema.fields.accountNumber,
                    "oneDecimal": false,
                },
                'sortable': false,
                'filter': false,
                'id':'accountNumber',
                'name':'accountNumber',
                'value': (item) => item.accountNumber || "",
            },

        }

        if (validationSchema === true) {
            return completeSchema;
        }

        if (typeof filter === "string") {
            return { [filter]: attributes[filter] };
        }
        if (Array.isArray(filter)) {
            const filteredAttributes = {};
            filter.forEach(key => {
                filteredAttributes[key] = attributes[key];
            });
            return filteredAttributes;
        }
        return attributes;
    }
}
export default RequestUserSettingsModel;