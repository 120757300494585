import {
    Accordion,
    AccordionItem, Button,
    Col,
    Collapse,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane
} from "reactstrap";
import classnames from "classnames";
import {CustomInput} from "../../Common/Components/Input/CustomInput";
import RequestReimbursementFixed from "../Models/Request/RequestReimbursementFixed";
import React, {useEffect, useRef, useState} from "react";
import {CustomSubmitButton} from "../../Common/Components/Input/CustomSubmitButton";
import _ from "lodash";
import {useTranslation} from "react-i18next";
import {ToCustomDecimal} from "../../AppSystem/Helpers/ToCustomDecimal";
import {ChangeDateFormat} from "../../Common/Helpers/ChangeDateFormat";
import $ from "jquery";

export function ReimbursementFixed({data, dataFixed, onChange, onValidate, onCancel, editMode = false , showDate = false, isFixed = false}) {
    const { t } = useTranslation()
    // CANCEL STATE
    const [cancelModels, setCancelModels] = useState(null);
    const [cancelModel, setCancelModel] = useState(null);

    // STATES
    const [accordionStatus, setAccordionStatus] = useState('1');
    const [activeTab, setActiveTab] = useState("1");
    const [localDisplay, setLocalDisplay] = useState(false);

    // REF
    const formDivRefFixed = useRef(null);

    // TOGGLE ACCORDION
    const ToggleAccordion = (id) => {
        if (accordionStatus === id) {
            setAccordionStatus();
        } else {
            setAccordionStatus(id);
        }
    };

    // CANCEL MODEL
    useEffect(() => {
        if (editMode === true && cancelModels === null && cancelModel === null) {
            const copyModels = _.cloneDeep(data);
            setCancelModels(copyModels);
            const copyModel = _.cloneDeep(dataFixed);
            setCancelModel(copyModel);
            if (copyModels.length > 0) {
                if (copyModels[0].type === 'Fixed' || isFixed === true) {
                    setActiveTab('2');
                } else {
                    setActiveTab('1');
                }
            }
            setLocalDisplay(true);
        }
    }, [data]);

    if (editMode === true) {
        setTimeout(() => {
            data.length > 0 && data.map((item, index) => {
                if (item.hours === 0) {
                    $("#fixed" + item.salaryDate).find(".hourDiv").find("input").val("0");
                }
            });
        }, 80);
    }

    // DESIGN
    const HandleToggle = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    if (data.length > 0) {
        data.sort((a, b) => new Date(a.salaryDate) - new Date(b.salaryDate));
    }

    return(
        <>
            <Accordion id="accordionBordered" className="" open={accordionStatus} toggle={ToggleAccordion}>
                <AccordionItem className={`accordion-grey bg-green mb-4 rounded ${localDisplay === false ? 'border-0' : 'border-2 border-green'}`}>
                    <h2 className="accordion-header" id="accordionborderedExample1">
                        <button className={classnames(`py-2 pe-1 w-100 accordion-button d-flex justify-content-between ${localDisplay === false ? 'accordion-grey text-green' : 'bg-green text-white'} fw-bold border-bottom-0`, { collapsed: !localDisplay })} type="button" onClick={() => {setLocalDisplay(!localDisplay)}} style={{ cursor: "pointer" }}
                        >
                            <div>
                                {t('reimbursement.addSalary')}
                            </div>
                            {localDisplay === false ? <i className="bx bx-chevron-down fs-3"></i> : <i className="bx bx-chevron-up text-white fs-3"></i>}
                        </button>
                    </h2>
                    <Collapse isOpen={localDisplay} className="accordion-collapse accordion-grey bg-danger rounded" id="accor_borderedExamplecollapse1">
                        <div className="accordion-body" ref={formDivRefFixed}>
                            <Nav tabs className="nav nav-tabs nav-tabs-custom nav-success nav-justified mb-3">
                                <NavItem className="bg-grey-tab p-1">
                                    <NavLink style={{ cursor: "pointer" }}
                                            className={classnames("text-muted fw-bold",{
                                                active: activeTab === "1",
                                            })}
                                            onClick={() => {
                                                HandleToggle("1");
                                                onChange("", "hourly");
                                            }}
                                    >
                                        {t('reimbursement.movable')}
                                    </NavLink>
                                </NavItem>
                                <NavItem className="bg-grey-tab p-1">
                                    <NavLink style={{ cursor: "pointer" }}
                                            className={classnames("text-muted fw-bold", {
                                                active: activeTab === "2",
                                            })}
                                            onClick={() => {
                                                HandleToggle("2");
                                                onChange("", "fixed");
                                            }}
                                    >
                                        {t('reimbursement.fixed')}
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent
                                activeTab={activeTab}
                                className="text-muted"
                            >
                                <TabPane tabId="1">
                                    <div className="d-flex">
                                        <div className="flex-shrink-0">
                                            <i className="ri-checkbox-multiple-blank-fill text-success"></i>
                                        </div>
                                        <div className="flex-grow-1 ms-2">
                                            {data.length > 0 && data.map((item, index) => {
                                                return (
                                                    <Row key={"fixed" + item.salaryDate} id={"fixed" + item.salaryDate}>
                                                        {data.length > 1 && ((item.toDate && item.toDate !== "") || showDate === true) ?
                                                            <Col lg={4} className={`${index > 0 ? "pt-3" : "mt-4 pt-3"} text-center`}>
                                                                {ChangeDateFormat("date", item.salaryDate)}
                                                            </Col>
                                                            : ""}
                                                        <Col
                                                            lg={data.length > 1 && ((item.toDate && item.toDate !== "") || showDate === true) ? 4 : 8}
                                                            className={`${index > 0 ? "pt-1" : ""} hourDiv position-relative`}>
                                                            {index === 0 ?
                                                                <label>{t('modelLabel.hours')}:</label>
                                                                : ""}
                                                            <CustomInput model={RequestReimbursementFixed}
                                                                         attribute='hours'
                                                                         data={item.hours && item.hours !== 0 ? item.hours : ""}
                                                                         customChange={(e) => {
                                                                             onChange(e, "hours", index);
                                                                         }}/>
                                                            <div id="ErrorFormHours"
                                                                 className="error tooltip-custom show-custom d-none"
                                                                 style={{color: '#f56e6e'}}>{t('validationMessage.totalHourMustBeMoreThan0')}</div>
                                                        </Col>
                                                        <Col lg={1}
                                                             className={`${index > 0 ? "pb-3" : "pb-3"} align-self-end text-center`}>
                                                        =
                                                        </Col>
                                                        <Col /*md={3} */ lg={3} className={`${index > 0 ? "pb-1" : "pb-2"} align-self-end `}>
                                                            <div id="divAmountHourly"
                                                                 className='box-beig'>{item.amount !== 0 ? ToCustomDecimal(item.amount) : 0} kr
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                );
                                            })}
                                            <Row>
                                                <Col lg={12} className={`text-end pt-3 ${data.length === 0 ? "d-none" : ""}`}>
                                                    {editMode === true ? <Button className="bg-secondary me-3" onClick={() => onCancel(cancelModels, cancelModel)}>{t('button.cancel')}</Button> : ""}
                                                    <CustomSubmitButton text={editMode === true ? t('button.save') : t('reimbursement.addSalary')} customClick={() => onValidate(data)} formDivRef={formDivRefFixed} />
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </TabPane>
                                <TabPane tabId="2">
                                    <div className="d-flex">
                                        <div className="flex-shrink-0">
                                            <i className="ri-checkbox-multiple-blank-fill text-success"></i>
                                        </div>
                                        <div className="flex-grow-1 ms-2">
                                            <Row>
                                                <Col lg={12}>
                                                    <CustomInput model={RequestReimbursementFixed} attribute='amount' data={dataFixed.amount && dataFixed.amount !== 0 ? dataFixed.amount : ""}  customChange={(e) => onChange(e, "amount", null, "fixed")}/>
                                                </Col>
                                                <Col lg={12} className={`text-end pt-3 ${data.length === 0 ? "d-none" : ""}`}>
                                                    {editMode === true ? <Button className="bg-secondary me-3" onClick={() => onCancel(cancelModels, cancelModel)}>{t('button.cancel')}</Button> : ""}
                                                    <CustomSubmitButton text={editMode === true ? t('button.save') :  t('reimbursement.addSalary')} customClick={() => onValidate()} formDivRef={formDivRefFixed}/>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </TabPane>
                            </TabContent>
                        </div>
                    </Collapse>
                </AccordionItem>
            </Accordion>
        </>
    );
}