import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import {ORGANIZATION_PATHS, USER_PATHS} from '../../../config';
import {Link, useNavigate} from "react-router-dom";
import useApiCallService from "../../Helpers/ApiCall";
import {CustomReload} from "../../../AppSystem/Helpers/CustomReload";
import {Col, Row} from "reactstrap";
import {SaveLocalStorage} from "../../Helpers/SaveLocalStorage";
import {useTranslation} from "react-i18next";
import {CheckUserOrganization} from "../../../AppSystem/Helpers/CheckUserOrganization";


export const SelectOrganization = () => {
  const { t } = useTranslation();

  const organizationList = [];

 // const { selectOrganization } = useOrganizationService();
  const { ApiCall } = useApiCallService();
  const [apiResponseOrgList, setApiResponseOrgList] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {

    const handleApiResponse  = (apiResponseOrgList) => {

      setApiResponseOrgList(apiResponseOrgList);

    };
    const GetOrgList = async () => {
      try {
        const apiCallOptions = {
          method: "get",
          url: "/user/org-list",
        };

        await ApiCall(apiCallOptions, handleApiResponse );

      } catch (error) {
        console.log('error', error);
      }

    };

    GetOrgList();

  }, []);


  const [apiResponseOrgUser, setApiResponseOrgUser] = useState(null);

  useEffect(() => {

    const handleApiResponseGetOrgUser  = (apiResponseOrgUser) => {
      setApiResponseOrgUser(apiResponseOrgUser);

    };
    const GetOrgUser = async () => {
      try {

        const apiCallOptions = {
          method: "get",
          url: "/user/org",
        };

        await ApiCall(apiCallOptions, handleApiResponseGetOrgUser );

      } catch (error) {
        console.log('error', error);
      }

    };

    GetOrgUser();

  }, []);

  if (apiResponseOrgList) {
    apiResponseOrgList.forEach(org => {
      if (org && apiResponseOrgUser && apiResponseOrgUser.id !== undefined) {
      // if (org.id !== apiResponseOrgUser.id){
        organizationList.push({
          value: org.id,
          label: org.name +' (' + org.type +')' ,
         // type: org.type,
        });
      //}
    }
    });
  }

  // puts the selected organization first in the list

  organizationList.sort((a, b) => {
    if (a.value === apiResponseOrgUser.id) return -1;
    if (b.value === apiResponseOrgUser.id) return 1;
    return 0;
  });


const [responseSelectOrg, setResponseSelectOrg]= useState('');

  const handleOrganizationSelect = async (selectOptions) => {

      if(selectOptions.value !== '') {
        try {
          const apiCallOptions = {
            method: "post",
            url: `/org/select-org`,
            data:{ id: selectOptions.value }

          };
          await ApiCall(apiCallOptions, setResponseSelectOrg);
        } catch (error) {
          console.log('error', error);

        }
      }

  };

  useEffect(() => {
    if (responseSelectOrg !== '') {
      CustomReload(USER_PATHS.APP);
    }

  }, [responseSelectOrg]);
 // const checkUser = CheckUserOrganization()


// index to show the organization in the select
  //now it is not being used

  // let indexSelect = null;
  // if (apiResponseOrgList !== null && apiResponseOrgUser !== null ) {
  //   indexSelect = apiResponseOrgList.findIndex((option) => option.id === apiResponseOrgUser.id);
  // }

if (CheckUserOrganization()) {
  if (apiResponseOrgList) {
    return (
        <>
          {apiResponseOrgList.length > 1 ? (
              <Row id="selectOrganizationDiv" className="px-3 pt-0 pb-0  d-flex align-items-center"> {/*class without type organization pt-1*/}
                <Col lg={12} className='d-flex justify-content-center pb-0'>
                  <Link to={ORGANIZATION_PATHS.ORGANIZATION} className=" p-0" onClick={()=> {
                    SaveLocalStorage('headerTitle', t("modelLabel.orgDetails"));
                    localStorage.removeItem('currentPage');
                    localStorage.removeItem('itemsPerPage');

                    // REMOVE LOCAL STORAGE FROM INTERNAL TRANSACTION
                    if (window.location.href.includes("internal-transaction") === false) {
                      localStorage.removeItem('originOrgId');
                      localStorage.removeItem('message');
                    }
                  }}>
                    {apiResponseOrgUser ?
                        <>
                          <div id="selectOrgName" className='text-dark fw-bold font-size-17'>{apiResponseOrgUser?.name }</div>
                          <div className='text-center'>({apiResponseOrgUser?.type })</div>
                        </>


                        :''}
                  </Link>
                </Col>
                <Col lg={12} className='px-3'>
                  <Select
                      className="first-step-organization-profile "
                      options={organizationList}
                      //value={organizationList.length > 0 ? organizationList[indexSelect] : ''}
                      id="selectedOrganization"
                      isSearchable="true"
                      placeholder='Byt grupp'
                      styles={{ control: (styles) => ({ ...styles, backgroundColor: 'transparent', border:"none", textAlign:"center", boxShadow:'none', }) }}
                      onChange={selectedOption =>{
                        handleOrganizationSelect(selectedOption)
                        //CustomReload(USER_PATHS.APP);
                        //navigate(USER_PATHS.DASHBOARD)
                      }
                      }
                  />

                </Col>
              </Row>
          ) : (
              <Row id="selectOrganizationDiv" className="px-3 pt-3 pb-3">
                <Col lg={12} className='d-flex justify-content-center pb-0'>
                  <Link to={ORGANIZATION_PATHS.ORGANIZATION} className=" p-0 " onClick={()=>{
                      SaveLocalStorage('headerTitle', t("modelLabel.orgDetails"));
                      localStorage.removeItem('currentPage');
                      localStorage.removeItem('itemsPerPage');

                    // REMOVE LOCAL STORAGE FROM INTERNAL TRANSACTION
                    if (window.location.href.includes("internal-transaction") === false) {
                      localStorage.removeItem('originOrgId');
                      localStorage.removeItem('message');
                    }
                  }}>
                    {apiResponseOrgUser ?
                        <>
                          <div id="selectOrgName" className='text-dark fw-bold font-size-17'>{apiResponseOrgUser?.name }</div>
                          <div className='text-center'>({apiResponseOrgUser?.type })</div>
                        </>
                        :''}
                  </Link>
                </Col>
              </Row>
          )}
        </>
    );
  } else {
    return <></>;
  }
}

};
