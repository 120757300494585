import {CardInvoice} from "../Components/CardInvoice";
import {Button, Col, Row} from "reactstrap";
import React, {useEffect, useState} from "react";
import SortableList from "../../Common/Components/Lists/SortableList";
import {CustomButton} from "../../Common/Components/Button/CustomButton";
import {ADMIN_PATHS, ORGANIZATION_PATHS, REPORT_PROCEEDING_PATHS} from "../../config";
import ResponseAccountRowModel from "../../DigitalAccount/Models/Response/ResponseAccountRowModel";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import ResponseInvoice from "../Models/Response/ResponseInvoice";
import {CustomModal} from "../../Common/Components/Modal/CustomModal";
import useApiCallService from "../../Common/Helpers/ApiCall";
import {CustomReload} from "../../AppSystem/Helpers/CustomReload";
import {generateFileName} from "../../AppSystem/Helpers/GenerateFileName";
import {CheckUserPermission} from "../../AppSystem/Helpers/CheckUserPermission";

export function InvoiceList() {
    const { t } = useTranslation();
    const { ApiCall } = useApiCallService();
    const navigate = useNavigate();
    const [displayDeleteModal, setDisplayDeleteModal] = useState(false);

    const [deleteApiResponse, setDeleteApiResponse] = useState(null);
    const [invoiceId, setInvoiceId] = useState(null);

    let permissionCreate = false;
    if (CheckUserPermission("invoice.create") || CheckUserPermission("invoice")) {
        permissionCreate = true;
    }
    let permissionDelete = false;
    if (CheckUserPermission("invoice.delete") || CheckUserPermission("invoice")) {
        permissionDelete = true;
    }

    // HANDLE MODAL DELETE
    const handleModalDelete = async () => {
        const apiCallOptions = {
            method: "delete",
            url: '/invoice',
            data: {
                id: invoiceId
            }
        };
        try {
            await ApiCall(apiCallOptions, setDeleteApiResponse);
            setDisplayDeleteModal(false);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (deleteApiResponse !== null) {
            CustomReload(ORGANIZATION_PATHS.INVOICE_LIST);
        }
    }, [deleteApiResponse]);

   // const[testResponse, setTestResponse] = useState(null);
    const downloadFile = async (url, number) => {
        const handleDownloadApiResponse  = (response) => {
            let file;
            if (response instanceof Blob) {
                file = response;
            } else {
                file = new Blob([response]);
            }
            const newFileName = generateFileName('invoice', number);
            let fileName = newFileName;
            const urlToFile = URL.createObjectURL(file);
            const link = document.createElement('a');
            link.href = urlToFile;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }

        const apiCallOptions = {
            method: "get",
            url: url,
            dataTypes: 'blob',
            responseType: 'blob'
        };
        try {
            await ApiCall(apiCallOptions, (response) => handleDownloadApiResponse(response));
        } catch (error) {
            console.log(error);
        }
    }

    const renderActionColumn = (item) => {
        return (
            <>
                {item.status === "Förslag" ? (
                    <>
                        {
                            permissionDelete === true ?
                                <Button className="box-red border-0 position-relative me-2 rounded class-deny" onClick={() => {
                                    setDisplayDeleteModal(true);
                                    setInvoiceId(item.id);
                                    const allButtons = document.querySelectorAll('button');
                                    // Itera sobre cada botón y lo deshabilita
                                    allButtons.forEach(button => {
                                        if (button.classList.contains('class-approve')) {
                                            button.disabled = true;
                                            button.classList.remove('bg-green-light', 'text-green');
                                            button.classList.add('bg-grey-tab','text-secondary');
                                        } else if (button.classList.contains('class-deny')) {
                                            button.disabled = true;
                                            button.classList.remove('box-red', 'text-red');
                                            button.classList.add('bg-grey-tab','text-secondary');
                                        } else if (button.classList.contains('class-credit')) {
                                            button.disabled = true;
                                            button.classList.remove('box-orange');
                                            button.classList.add('bg-grey-tab','text-secondary');
                                        }
                                    });
                                }}>
                                    <i className="bx bx-x-circle fs-5 align-middle pb-1"/>
                                    <div className="tooltip-custom-button">
                                        {t("button.delete")}
                                    </div>
                                </Button>
                                : ''
                        }

                        {
                            permissionCreate === true ?
                                <Button className="bg-green-light text-green border-0 position-relative class-approve" onClick={() => {
                                    navigate(`${ORGANIZATION_PATHS.INVOICE_CREATE}?id=${item.id}`)
                                }}>
                                    <i className="bx bx-edit fs-5 align-middle pb-1"/>
                                    <div className="tooltip-custom-button">
                                        {t("button.edit")}
                                    </div>
                                </Button>
                                : ''
                        }
                    </>
                ) : (
                    <>
                        {
                            permissionCreate === true && item.type === "Faktura" && !item.creditInvoiceReferenceId ?
                                item.status !== "Attestera kö" ?
                                    <Button className="box-orange border-0 position-relative me-2 rounded class-credit" onClick={() => {
                                        navigate(`${ORGANIZATION_PATHS.INVOICE_CREDIT_CREATE}?id=${item.id}`)

                                    }}>
                                        <i className='bx bxs-file-import align-middle pb-1 fs-5'/>
                                        <div className="tooltip-custom-button">
                                            {t("invoice.creditInvoice")}
                                        </div>
                                    </Button>
                                    : ''
                            : ''

                        }
                        {item.status !== "Attestera kö" ?
                            <Button className="bg-green-light text-green border-0 position-relative class-approve" onClick={() => {
                                downloadFile(item.fileUrl ,item.visualId)
                            }}>
                                <i className="bx bx-down-arrow-alt fs-5 align-middle pb-1" />
                                <div className="tooltip-custom-button-table">
                                   {t('modelLabel.download')}
                                </div>
                            </Button>
                        : ''}
                    </>

                )}
            </>
        );
    }

    const renderButtonColumn = (item) => {
        return (
            <>
                {permissionCreate ?
                    <Col lg={12} className="mt-4 px-3">
                        <CustomButton customClasses="rounded" text={t('invoice.createInvoice')} icon="bx bx-plus pe-2" onClick={() => {
                           navigate(ORGANIZATION_PATHS.INVOICE_CREATE)
                        }}
                        />
                    </Col>

                    : ""}
            </>
        );
    }

    const modalContent = (
        <div>
            {`${t('validationMessage.modalVar')}?`}
        </div>
    );
    const modalFooter = (
        <>
            <Button className="box-green border-0" onClick={handleModalDelete}>
                {t('button.delete')}
            </Button>
            <Button className="box-red border-0" onClick={() => {
                setDisplayDeleteModal(false);
                const allButtons = document.querySelectorAll('button');
                // Itera sobre cada botón y lo deshabilita
                allButtons.forEach(button => {
                    if (button.classList.contains('class-approve')) {
                        button.disabled = false;
                        button.classList.add('bg-green-light', 'text-green');
                        button.classList.remove('bg-grey-tab','text-secondary');
                    } else if (button.classList.contains('class-deny')) {
                        button.disabled = false;
                        button.classList.add('box-red', 'text-red');
                        button.classList.remove('bg-grey-tab','text-secondary');
                    } else if (button.classList.contains('class-credit')) {
                        button.disabled = false;
                        button.classList.add('box-orange');
                        button.classList.remove('bg-grey-tab','text-secondary');
                    }
                });

            }}>{t('button.cancel')}</Button>
        </>
    );

    const columns = ["visualId", "invoiceNumber", 'clientName', 'expiringDate', 'type', 'status', 'createdAt', 'totalAmount'];

    const listOptions = {
        "model": ResponseInvoice,
        'columns': columns,
        "actions": {
            "actionColumn" : renderActionColumn,
            "addButton": renderButtonColumn,
        },
        "exportIsAvailable": true,
        "urlGetListItemsToExport": "/invoice/search",
        "nameFile": t('invoice.invoice'),
        "ignoreFields" : columns,
        "modelType" : "Invoice",
        "urlGetSearch" : "/invoice/search",
    };




    return(
        <Col lg={12}>
            <CustomModal isOpen={displayDeleteModal} toggle={() => setDisplayDeleteModal(!displayDeleteModal)}
                         body={modalContent} footer={modalFooter}/>
            <Row className="pb-5">
                <Col lg={12} className="p-4 pb-3 card-backend">
                   <h2 className="rokkitt-font">{t('invoice.invoice')} </h2>
                    {/* <Row>
                        <Col lg={4}>
                            <CardInvoice/>
                        </Col>
                        <Col lg={4}>
                            <CardInvoice/>
                        </Col>
                        <Col lg={4}>
                            <CardInvoice/>
                        </Col>
                    </Row> */}
                    <SortableList
                        listOptions={listOptions}
                    />
                </Col>       
            </Row>
        </Col>

    )
}