import * as yup from "yup";
import {string} from "yup";
import i18n from "i18next";

class AdminRequestAttestDenyModel {
    constructor({
                    id = '',
                    reason = '',
                    model = '',
                    modelId = '',

                } = {}) {
        this.id = id;
        this.reason = reason;
        this.model = model;
        this.modelId = modelId;
    }

    static getAttributes(filter = null, validationSchema = null, optionsData = null) {

        const completeSchema = yup.object().shape({
            id: string().required(),
            reason: string().required(i18n.t('validationMessage.fieldRequired')),
        });

        const attributes = {
            id :{
                'label': 'Id',
                'form': {
                    'type': 'text',
                    'placeholder': 'Enter Id',
                    'validation': completeSchema.fields.id,
                },
                'value': (item) => item.id || "",
            },
            reason :{
                'label': i18n.t('modelLabel.reason'),
                'form': {
                    'type': 'textarea',
                    'placeholder': i18n.t('formPlaceholder.reason'),
                    'validation': completeSchema.fields.reason,
                },
                'value': (item) => item.reason || "",
            },
            model :{
                'label': 'Id',
                'form': {
                    'type': 'text',
                    'placeholder': 'Enter Id',
                    'validation': completeSchema.fields.model,
                },
                'value': (item) => item.model || "",
            },
            modelId :{
                'label': 'Id',
                'form': {
                    'type': 'text',
                    'placeholder': 'Enter Id',
                    'validation': completeSchema.fields.modelId,
                },
                'value': (item) => item.modelId || "",
            },

        }

        if (validationSchema === true) {
            return completeSchema;
        }

        return attributes;
    }
}
export default AdminRequestAttestDenyModel;