import {useEffect, useState} from "react";

export function TreeCheckboxes  ({treeData, data, onChange}) {
    const [expandedCategories, setExpandedCategories] = useState({});
    const [expandedSecondChildren, setExpandedSecondChildren] = useState({});
    const [dataCheck, setDataCheck] = useState(data);

    useEffect(() => {
        if (data) {
            //First Child
            const resultArray = data.map(value => {
                if (value?.includes('.')) {
                    return value?.split('.')[0];
                }
            });
            const filteredArray = resultArray.filter(item => item !== undefined);

            if (filteredArray && filteredArray.length > 0) {
                const newExpandedCategories = filteredArray.reduce((acc, category) => {
                    if (acc[category] === undefined) {
                        acc[category] = true;
                    }
                    return acc;
                }, { ...expandedCategories });

                setExpandedCategories(newExpandedCategories);
            }

            //Second Child
            const resultArrayChild = data.map(value => {
                if (value?.split('.').length - 1 === 2) {
                    return value?.split('.').slice(0, 2).join('.');
                }
            });
            const filteredArrayChild = resultArrayChild.filter(item => item !== undefined);

            if (filteredArrayChild && filteredArrayChild.length > 0) {
                const newExpandedSecondChildren = filteredArrayChild.reduce((acc, category) => {
                    if (acc[category] === undefined) {
                        acc[category] = true;
                    }
                    return acc;
                }, { ...expandedSecondChildren });

                setExpandedSecondChildren(newExpandedSecondChildren);
            }

        }

    }, [dataCheck]);

    const toggleCategory = (category) => {
        setExpandedCategories({
            ...expandedCategories,
            [category]: !expandedCategories[category]
        });
    };
    const toggleSecondChildren = (child) => {
        setExpandedSecondChildren({
            ...expandedSecondChildren,
            [child]: !expandedSecondChildren[child]
        });
    };

    const handleParentChange = (category) => {
        if (dataCheck) {
            if (dataCheck.some(perm => perm === category)) {
                // Remove category if it is already in data
                setDataCheck(prevData => prevData.filter(perm => perm !== category));
            } else {
                // Add category if no data is available
                const isChildren = dataCheck.some(perm => perm.startsWith(category + '.'));
                if (isChildren) {
                    const newDataCheck = dataCheck.filter(perm => !perm.startsWith(category + '.'));
                    const updateDataCheck = [...newDataCheck, category];
                    setDataCheck(updateDataCheck);
                }else {
                    setDataCheck(prevData => [...prevData, category]);
                }

            }
        } else {
            setDataCheck( [category]);
        }


    };

    const handleChildChange = (perm) => {
        const level0 = perm.split('.')[0];
        const level1 = perm.split('.').slice(0, 2).join('.');
        const siblings = treeData.permissions.filter(p => p.startsWith(level0 + '.'));
        const siblings2 = treeData.permissions.filter(p => p.startsWith(level1 + '.'));

        let finalCheck = null;

        if (dataCheck) {
            if (dataCheck.includes(level0)) {
                const newDataCheck = dataCheck.filter(item => item !== level0);
                if (siblings2.length > 0) {
                    //Con hijos
                    const cleanSiblingChildren = Array.from(
                        new Set(siblings.map(child => child.split('.').slice(0, 2).join('.')))
                    );
                    const filterLevel1 = cleanSiblingChildren.filter(perm => perm !== level1);
                    const newDataCheck1 = [...newDataCheck, ...filterLevel1];
                    setDataCheck(newDataCheck1);
                    finalCheck = newDataCheck1;
                } else {
                    //Sin hijos
                    const updatedDataCheck = [...newDataCheck, ...siblings.filter(sibling => sibling !== perm)];
                    const cleanSiblingChildren = Array.from(
                        new Set(updatedDataCheck.map(child => child.split('.').slice(0, 2).join('.')))
                    );
                    const filterLevel1 = cleanSiblingChildren.filter(p => !p.startsWith(level1 + '.'));
                    const newDataCheck1 = [...filterLevel1];
                    setDataCheck(newDataCheck1);
                    finalCheck = newDataCheck1;
                }
            } else {
                if(dataCheck.includes(perm)) {
                    const cleanDataCheck = dataCheck.filter(p => !p.startsWith(level1 + '.'));
                    const newDataCheck = cleanDataCheck.filter(item => item !== perm);
                    setDataCheck(newDataCheck);
                    finalCheck = newDataCheck;
                } else {
                    const cleanDataCheck = dataCheck.filter(p => !p.startsWith(level1 + '.'));
                    let newDataCheck = [...cleanDataCheck, perm];

                    const cleanDataCheck2 = newDataCheck.filter(p => p.startsWith(level0 + '.'));
                    const cleanSiblingChildren = Array.from(
                        new Set(siblings.map(child => child.split('.').slice(0, 2).join('.')))
                    );

                    if(cleanSiblingChildren.length === cleanDataCheck2.length && cleanSiblingChildren.sort().every((item, index) => item === cleanDataCheck2.sort()[index])) {
                        const cleanDataCheck3 = newDataCheck.filter(p => !p.startsWith(level0 + '.'));
                        const newDataCheck2 = [...cleanDataCheck3, level0];
                        newDataCheck = newDataCheck2;
                    }
                    setDataCheck(newDataCheck);
                    finalCheck = newDataCheck


                }
            }
        } else {
            setDataCheck( [perm]);
            finalCheck = [perm];
        }

        // COMPROBAR ARRAY
        if (finalCheck != null) {

            const allElementsExist = siblings.every(element => finalCheck.includes(element));
            // EXISTEN TODOS LOS HIJOS
            if (allElementsExist) {
                const parent = siblings[0]?.split('.')[0];
                const updateDataCheck = finalCheck.map(item => siblings.includes(item) ? parent : item);
                const FinalData = Array.from(new Set(updateDataCheck));
                setDataCheck(FinalData);
            }
        }
    };
/// este es el tercer hijo
    const handleThirdChildChange = (perm) => {
        const level0 = perm.split('.')[0];
        const level1 = perm.split('.').slice(0, 2).join('.');
        const siblings = treeData.permissions.filter(p => p.startsWith(level1 + '.'));
        let finalCheck = null;
        if (dataCheck) {
            if (dataCheck.includes(level1)) {
                const newDataCheck = dataCheck.filter(item => item !== level1);
                const updatedDataCheck = [...newDataCheck, ...siblings.filter(sibling => sibling !== perm)];
                setDataCheck(updatedDataCheck);
                finalCheck = updatedDataCheck;
            } else {
                if(dataCheck.includes(perm)) {
                    const newDataCheck = dataCheck.filter(item => item !== perm);
                    setDataCheck(newDataCheck);
                    finalCheck = newDataCheck;
                } else {
                     if ( dataCheck.includes(level0)){
                         const newDataCheck = dataCheck.filter(item => item !== level0);
                         const siblingsChildren = treeData.permissions.filter(p => p.startsWith(level0 + '.'));
                         const cleanSiblingChildren = Array.from(
                             new Set(siblingsChildren.map(child => child.split('.').slice(0, 2).join('.')))
                         );
                         const filterLevel1 = cleanSiblingChildren.filter(perm => perm !== level1);
                         const addThirdPermission = siblings.filter(p => p !== perm);
                         const newDataCheck1 = [...newDataCheck, ...filterLevel1, ...addThirdPermission];
                         setDataCheck(newDataCheck1);
                         finalCheck = newDataCheck1;

                     } else {
                         let newDataCheck = [...dataCheck, perm];

                         const cleanDataCheck = newDataCheck.filter(p => p.startsWith(level0 + '.'));
                         if (siblings.every(item => cleanDataCheck.includes(item))) {
                             const newCleanDataCheck = newDataCheck.filter(p => !p.startsWith(level1 + '.'));
                             const newDataCheck2 = [...newCleanDataCheck, level1];
                             newDataCheck = newDataCheck2;
                         }

                         const cleanDataCheck2 = newDataCheck.filter(p => p.startsWith(level0 + '.'));
                         const siblingsLevel0 = treeData.permissions.filter(p => p.startsWith(level0 + '.'));
                         const cleanSiblingChildren = Array.from(
                             new Set(siblingsLevel0.map(child => child.split('.').slice(0, 2).join('.')))
                         );

                         if(cleanSiblingChildren.length === cleanDataCheck2.length && cleanSiblingChildren.sort().every((item, index) => item === cleanDataCheck2.sort()[index])) {
                             const cleanDataCheck3 = newDataCheck.filter(p => !p.startsWith(level0 + '.'));
                             const newDataCheck3 = [...cleanDataCheck3, level0];
                             newDataCheck = newDataCheck3;
                         }
                         setDataCheck(newDataCheck);
                         finalCheck = newDataCheck
                     }

                }
            }
        } else {
            setDataCheck( [perm]);
            finalCheck = [perm];
        }


        // COMPROBAR ARRAY
        if (finalCheck != null) {
            const allElementsExist = siblings.every(element => finalCheck.includes(element));
            // EXISTEN TODOS LOS HIJOS
            if (allElementsExist) {
                const parent = siblings[0].split('.').slice(0, 2).join('.');
                const updateDataCheck = finalCheck.map(item => siblings.includes(item) ? parent : item);
                const FinalData = Array.from(new Set(updateDataCheck));
                setDataCheck(FinalData);
            }
        }
    };

    useEffect(() => {
        if (dataCheck) {
            onChange(dataCheck , 'permissions');
        }
    }, [dataCheck]);

    //aqui se extraen los valores padre para el arbol
    //el new Set() elimina las palabras duplicadas
   // const categories = Array.from(new Set(treeData.permissions.map(perm => perm.split('.')[0])));


    let categories = [];
    if (treeData !== null) {
        categories = Array.from(
        new Set(
            treeData.permissions.map(perm => {
                const parts = perm.split('.');
                return parts.length > 1 ? parts[0] : perm;
            })
        ));
    }

    /*
    //por si hace falta para un futuro si se añaden nuevos niveles
    const tree = {};
    treeData.permissions.forEach(permission => {
        const parts = permission.split('.');
        let currentLevel = tree;

        parts.forEach((part, index) => {
            if (!currentLevel[part]) {
                currentLevel[part] = {
                    //children: {}
                };
            }
            currentLevel = currentLevel[part];
        });
    });
    */

    return (
        <div>
            {categories.map((category, index) => {
                const hasChildren = treeData.permissions.some(perm => perm.startsWith(category + '.'));
                const children = treeData.permissions.filter(perm => perm.startsWith(category + '.'));
                const childrenCategories = children.map(child => child.split('.')[0]);
                const thirdChildrenCategories = children.map(child => child.split('.').slice(0, 2).join('.'));
                // Agrupar por segundo nivel
                const groupedChildren = Array.from(
                    new Set(children.map(child => child.split('.').slice(0, 2).join('.')))
                );


                return (
                    <div key={category}>
                        {hasChildren ? (
                            <span onClick={() => toggleCategory(category)} style={{ cursor: 'pointer', marginRight: 8 }}>
                                {expandedCategories[category] ? <i className="bx bx-chevron-down" /> : <i className="bx bx-chevron-right" />}
                            </span>
                        ) : (
                            <span style={{ marginRight: 24 }}></span>
                        )}
                        <label>
                            <input
                                type="checkbox"
                                checked={dataCheck?.some(perm => perm === category)}
                                onChange={() => handleParentChange(category)}
                                className="me-2"
                            />
                            {category}
                        </label>
                        {expandedCategories[category] && hasChildren && (
                            <div style={{ marginLeft: 25 }}>
                                {groupedChildren.map((child, index) => {
                                    const subChildren = children.filter(perm => perm.startsWith(child + '.'));
                                    const permission2 = child.split(".")[0];
                                    return (
                                        <div key={index}>
                                            {
                                                subChildren.length > 0 ? (
                                                    <span onClick={() => toggleSecondChildren(child)} style={{ cursor: 'pointer', marginRight: 8 }}>
                                                        {expandedSecondChildren[child] ? <i className="bx bx-chevron-down" /> : <i className="bx bx-chevron-right" />}
                                                    </span>
                                                ) : (
                                                    <span style={{ marginRight: 24 }}></span>
                                                )
                                            }
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    checked={dataCheck?.some(perm2 => perm2 === child) || dataCheck?.includes(permission2) /*childrenCategories.some(childCategory => dataCheck?.includes(childCategory))*/}
                                                    onChange={() => {
                                                        handleChildChange(child);
                                                    }}
                                                    className="me-2"
                                                />
                                                {child.split('.')[1]}
                                            </label>

                                            {/* Renderizar tercer nivel si existen subChildren */}
                                            {expandedSecondChildren[child] && subChildren.length > 0 && (
                                                <div style={{ marginLeft: 45 }}>
                                                    {subChildren.map(subChild => {
                                                        const childPermission = subChild.split(".").slice(0, 2).join('.');
                                                        const permission = subChild.split(".")[0];
                                                        return <div key={subChild}>
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    checked={dataCheck?.includes(permission) || dataCheck?.includes(childPermission) || dataCheck?.some(perm => perm === subChild)}
                                                                    onChange={() => {
                                                                        handleThirdChildChange(subChild);
                                                                    }}
                                                                    className="me-2"
                                                                />
                                                                {subChild.split('.')[2]}
                                                            </label>
                                                        </div>;
                                                    })}
                                                </div>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                );
            })}
        </div>
    );
}

   // OLD

    /*const createCategoryTree = (categories) => {
        const tree = {};

        categories.forEach((category) => {
            const levels = category.split('.');
            let currentLevel = tree;

            levels.forEach((level, index) => {
                if (!currentLevel[level]) {
                    currentLevel[level] = {
                        children: {},
                        fullPath: levels.slice(0, index + 1).join('.'),
                    };
                }
                currentLevel = currentLevel[level].children;
            });
        });

        return tree;
    };

    const renderCategoryTree = (tree, parentPath = '') => {
        return (
            <div>
                {Object.keys(tree).map((key) => {
                    const currentCategory = tree[key];
                    const hasChildren = Object.keys(currentCategory.children).length > 0;
                    const fullPath = currentCategory.fullPath;

                    return (
                        <div key={fullPath} style={{ marginLeft: parentPath ? 20 : 0 }}>
                            <div>
                                {hasChildren ? (
                                    <span
                                        onClick={() => toggleCategory(fullPath)}
                                        style={{ cursor: 'pointer', marginRight: 8 }}
                                    >
                                    {expandedCategories[fullPath] ? (
                                        <i className="bx bx-chevron-down" />
                                    ) : (
                                        <i className="bx bx-chevron-right" />
                                    )}
                                </span>
                                ) : (
                                    <span style={{ marginRight: 24 }} />
                                )}
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={dataCheck?.some((perm) => perm === fullPath)}
                                        onChange={() => handleParentChange(fullPath)}
                                        className="me-2"
                                    />
                                    {key}
                                </label>
                            </div>

                            {expandedCategories[fullPath] && hasChildren && (
                                <div style={{ marginLeft: 20 }}>
                                    {renderCategoryTree(currentCategory.children, fullPath)}
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        );
    };

    const categoryTree = createCategoryTree(categories);

    return (
        <div>
            {renderCategoryTree(categoryTree)}
        </div>
    );
*/
//};