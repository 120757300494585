import {Col, Row} from "reactstrap";
import {ChangeDateFormat} from "../../Common/Helpers/ChangeDateFormat";
import {API_URL} from "../../config";
import React from "react";
import {useTranslation} from "react-i18next";
import {CheckUserRole} from "../../AppSystem/Helpers/CheckUserRole";
import {ToCustomDecimal} from "../../AppSystem/Helpers/ToCustomDecimal";

export function AttestPayViews({data, modelId = null, type = null}) {
    const { t } = useTranslation();
    const isSuperAdmin = CheckUserRole('SuperAdmin');
console.log('data', data)
    return (
       /* <Row className="px-4 justify-content-center">
            <hr className="mt-4" />*/
        <>
            <Col lg={8}>
                <h2 className="rokkitt-font">{t('payReceive.pay')}</h2>
            </Col>
            <Col lg={4} >
                <ul className="list-unstyled">
                    {isSuperAdmin === true && modelId ?
                        <>
                            <li className="pt-1 row">
                                <span className="fw-bold col-4">{t('modelLabel.modelId')}</span> <span className="col-8">{modelId}</span>
                            </li>
                        </>
                        : '' }
                </ul>
            </Col>
            <Col lg={10}>
                <h5 className='text-muted'>{data.type === "Bankkonto" ? t('payReceive.bankAccount') : t('payReceive.bankgiro')}</h5>
            </Col>
            <Col lg={10} className="border-top">
                <Row>
                    {isSuperAdmin === true ?
                        <Col lg={6}>
                            <ul className="list-unstyled">
                                <li className="pt-2 row">
                                    <span className="fw-bold col-12 col-lg-4">{t('modelLabel.id')} </span> <span className="col-12 col-lg-8">{data.id}</span>
                                </li>
                                <li className="pt-2 row">
                                    <span className="fw-bold col-12 col-lg-4">{t('reimbursement.costBearer')} </span> <span className="col-12 col-lg-8">{data.costBearerId}</span>
                                </li>
                                <li className="pt-2 row">
                                    <span className="fw-bold col-12 col-lg-4">{t('modelLabel.accountingId')} </span> <span className="col-12 col-lg-8">{data.accountingId}</span>
                                </li>
                            </ul>
                        </Col>
                        : ''}
                    <Col lg={type === null ? 6 : 12}>
                        <ul className="list-unstyled">
                            {data.visualId !== "" ?
                                <li className="pt-2 row">
                                    <span className="fw-bold col-6 col-lg-4">{t('modelLabel.id')}:</span>
                                    <span className="col-6 col-lg-8">{data.visualId}</span>
                                </li>
                                : ""}
                            <li className="pt-2 row">
                                <span className="fw-bold col-6 col-lg-4">{t('modelLabel.createdBy')} </span> <span className="col-6 col-lg-8">{data.createdByName}</span>
                            </li>
                            <li className="pt-2 row">
                                <span className="fw-bold col-6 col-lg-4">{t('modelLabel.createdAt')} </span> <span className="col-6 col-lg-8">{ChangeDateFormat('dateTime', data.createdAt)}</span>
                            </li>
                            <li className="pt-2 row">
                                <span className="fw-bold col-6 col-lg-4">{t('reimbursement.costBearer')} </span> <span className="col-6 col-lg-8">{data.costBearerName}</span>
                            </li>
                            <li className="pt-2 row">
                                <span className="fw-bold col-6 col-lg-4">{t('modelLabel.accounting')} </span> <span className="col-6 col-lg-8">{data.accountingName}</span>
                            </li>
                            <li className="pt-2 row">
                                <span className="fw-bold col-6 col-lg-4">{t('modelLabel.creditorName')} </span> <span className="col-6 col-lg-8">{data.creditorName}</span>
                            </li>
                            {data.clearingNumber ?
                                <li className="pt-2 row">
                                    <span className="fw-bold col-6 col-lg-4">{t('userSetting.modelClearingNumber')} </span> <span className="col-6 col-lg-8">{data.clearingNumber}</span>
                                </li>
                                : ''}
                            {data.accountNumber ?
                                <li className="pt-2 row">
                                    <span className="fw-bold col-6 col-lg-4">{t('userSetting.modelAccountNumber')} </span> <span className="col-6 col-lg-8">{data.accountNumber}</span>
                                </li>
                                : ''}
                            {data.bg ?
                                <li className="pt-2 row">
                                    <span className="fw-bold col-6 col-lg-4">BG </span> <span className="col-6 col-lg-8">{data.bg}</span>
                                </li>
                                : ''}
                            {data.pg ?
                                <li className="pt-2 row">
                                    <span className="fw-bold col-6 col-lg-4">PG </span> <span className="col-6 col-lg-8">{data.pg}</span>
                                </li>
                                : ''}
                            {data.ocr ?
                                <li className="pt-2 row">
                                    <span className="fw-bold col-6 col-lg-4">OCR </span> <span className="col-6 col-lg-8">{data.ocr}</span>
                                </li>
                                : ''}
                            {data.message ?
                                <li className="pt-2 row">
                                    <span className="fw-bold col-6 col-lg-4">{t('modelLabel.message')} </span> <span className="col-6 col-lg-8">{data.message}</span>
                                </li>
                                : ''}
                        </ul>
                    </Col>
                </Row>
            </Col>
            <Col lg={10} className="border-bottom">
                <Row>
                    <Col lg={type === null ? 6 : 12}>
                        <ul className="list-unstyled">
                            <li className="pt-2 row">
                                <span className="fw-bold col-6 col-lg-4">{t("modelLabel.whenToPay")} </span> <span className="col-6 col-lg-8">{data.date ? ChangeDateFormat('date', data.date) : t("modelLabel.soon")}</span>
                            </li>
                            <li className="pt-2 row">
                                <span className="fw-bold col-6 col-lg-4 ">{t('reimbursement.amount')} </span> <span className="col-6 col-lg-8 d-flex align-items-center">{data.amount ? ToCustomDecimal(data.amount) : 0} kr</span>
                            </li>
                            {data.vatAmount ?
                                <li className="pt-2 row">
                                    <span className="fw-bold col-6 col-lg-4">{t('modelLabel.vatAmount')} </span> <span className="col-6 col-lg-8">{data.vatAmount ? ToCustomDecimal(data.vatAmount) : 0} kr</span>
                                </li>
                                : ''}
                            <li className="pt-2 row">
                                <span className="fw-bold col-6 col-lg-4">{t('modelLabel.description')} </span> <span className="col-6 col-lg-8">{data.description}</span>
                            </li>
                            {isSuperAdmin === true ?
                                <li className="pt-2 row"><span className="fw-bold col-6 col-lg-4 ">{t('modelLabel.fileId')} </span> <span className="col-6 col-lg-8">{data.fileId}</span> </li>
                                : ''}
                            <li className="pt-2 d-flex align-items-center row">
                                <span className="fw-bold col-12 col-lg-4">{t('reimbursement.receipt')} </span>
                                <a href={`${API_URL}/file?id=${data.fileId}`} target="_blank"
                                   className="text-decoration-none text-green d-flex col-12 col-lg-8">
                                    {data?.fileName && data?.fileName?.includes('.png') || data?.fileName?.includes('.jpg') || data?.fileName?.includes('.gif') || data?.fileName?.includes('.jpeg') ?
                                        <i className="bx bx-image-alt fs-2"/> : ""}
                                    {data?.fileName && data?.fileName?.includes('.pdf') ? <i className="bx bxs-file-pdf fs-2 align-self-center"/> : ""}
                                    {data?.fileName && data?.fileName?.includes('.doc') || data?.fileName?.includes('.docx') ?
                                        <i className="bx bxs-file-doc fs-2"/> : ""}
                                    {data?.fileName && data?.fileName?.includes(".txt") ? <i className="bx bxs-file-txt fs-2 align-self-center"/> : ""}
                                    {data?.fileName && data?.fileName?.includes(".xlsx") ? <i className="bx bxs-file fs-2 align-self-center"/> : ""}
                                    {data?.fileName && data?.fileName?.includes(".xls") ? <i className="bx bxs-file fs-2 align-self-center"/> : ""}
                                    <span className='align-self-center ps-2 auto-wrap-td'>{data?.fileName ?? <i className="bx bx-file-blank fs-2 align-self-center" />} </span>
                                </a>
                            </li>
                            {data.additionalInfo && data.additionalInfo.participantsFileId ?
                                <>
                                    {isSuperAdmin === true ?
                                        <li className="pt-2 row">
                                            <span className="fw-bold col-12 col-lg-4">{t('modelLabel.additionalFileId')} </span>
                                            <span className="col-12 col-lg-8">{data.additionalInfo.participantsFileId}</span>
                                        </li>
                                        : ''}
                                    <li className="pt-2 d-flex align-items-center row">
                                        <span className="fw-bold col-12 col-lg-4">{t('reimbursement.listParticipants')} </span>
                                        <a className="col-12 col-lg-8" href={`${API_URL}/file?id=${data.additionalInfo.participantsFileId}`}
                                           target="_blank" className="text-decoration-none text-green d-flex">
                                            {data?.additionalFileName && data?.additionalFileName.includes('.png') || data?.additionalFileName.includes('.jpg') || data?.additionalFileName.includes('.gif') || data?.additionalFileName.includes('.jpeg') ?
                                                <i className="bx bx-image-alt fs-2 align-self-center"/> : ""}
                                            {data?.additionalFileName && data?.additionalFileName.includes('.pdf') ?
                                                <i className="bx bxs-file-pdf fs-2 align-self-center"/> : ""}
                                            {data?.additionalFileName && data?.additionalFileName.includes('.doc') || data?.additionalFileName.includes('.docx') ?
                                                <i className="bx bxs-file-doc fs-2 align-self-center"/> : ""}
                                            {data?.additionalFileName && data?.additionalFileName.includes(".txt") ?
                                                <i className="bx bxs-file-txt fs-2 align-self-center"/> : ""}
                                            {data?.additionalFileName && data?.additionalFileName.includes(".xlsx") ?
                                                <i className="bx bxs-file fs-2 align-self-center"/> : ""}
                                            {data?.additionalFileName && data?.additionalFileName.includes(".xls") ?
                                                <i className="bx bxs-file fs-2 align-self-center"/> : ""}
                                            <span className='align-self-center ps-2 auto-wrap-td'>{data?.additionalFileName}</span>
                                        </a>
                                    </li>
                                </>
                                : ''}
                            {data.additionalInfo && data.additionalInfo.program ?
                                <>
                                    <li className="pt-2 row">
                                        <span className="fw-bold col-6 col-lg-4">{t('reimbursement.programInvitation')} </span>
                                        <span className="col-6 col-lg-8">{data.additionalInfo.program}</span>
                                    </li>
                                </>
                                : ''}
                        </ul>
                    </Col>
                </Row>
            </Col>
        </>

       /* </Row>*/
    );

}