import {Button, Card, Col, Row} from "reactstrap";
import {ChangeDateFormat} from "../../Common/Helpers/ChangeDateFormat";
import React, {useState} from "react";
import {CustomButton} from "../../Common/Components/Button/CustomButton";
import {CustomModal} from "../../Common/Components/Modal/CustomModal";
import {useSelector} from "react-redux";
import {AttestHonorariumViews} from "./AttestHonorariumViews";
import {AttestReimbursementViews} from "./AttestReimbursementViews";
import {useTranslation} from "react-i18next";
import {AttestPayViews} from "./AttestPayViews";
import {ToCustomDecimal} from "../../AppSystem/Helpers/ToCustomDecimal";
import {CheckUserRole} from "../../AppSystem/Helpers/CheckUserRole";
import {CustomInput} from "../../Common/Components/Input/CustomInput";
import AdminRequestAttestDenyModel from "../Model/Request/AdminRequestAttestDenyModel";
import {CheckUserPermission} from "../../AppSystem/Helpers/CheckUserPermission";
import {CardCreditInvoiceInfo} from "../../Invoice/Components/CardCreditInvoiceInfo";
import $ from "jquery";

export function AttestViews({data, onChange, onValidate, onChangeDenyId, onchangeDenyReason, errorReason, setErrorReason, setShowConfirmModalApprove, showConfirmModalApprove, setShowConfirmModalDeny, showConfirmModalDeny}) {
    const { t } = useTranslation();
    const { userData } = useSelector(state => state.auth);

    const [moreInfo, setMoreInfo] = useState(false);
    const isSuperAdmin = CheckUserRole('SuperAdmin'); //SuperAdmin

    let permissionUpdate = false;
    if (CheckUserPermission("admin") || CheckUserPermission("admin.attest") || CheckUserPermission("admin.attest.approve")) {
        permissionUpdate = true;
    }

    let permissionDeny = false;
    if (CheckUserPermission("admin") || CheckUserPermission("admin.attest") || CheckUserPermission("admin.attest.deny")) {
        permissionDeny = true;
    }

    const contentBodyApproved = (
        <div>{t('validationMessage.modalAttest')}</div>
    );
    const contentFooterApproved = (
        <>
            <Button className="box-green border-0" onClick={async () => {
                setShowConfirmModalApprove(false); // Close Modal
                await onValidate("approve");
            }}>{t('button.accept')}</Button>
            {' '}
            <Button className="box-red border-0"
                    onClick={() => {
                        setShowConfirmModalApprove(false);
                        $("#btnApproveAttest").removeClass("disabled");
                        $(`#${data.id}`).removeClass("disabled");
                    }}>{t('button.cancel')}</Button>
        </>
    );

    const contentBodyDeny = (
        <>
            <div className="text-center pb-3">
                <h5>{t('validationMessage.modalAttestDenyHeader')}</h5>
            </div>
            <div className="d-flex justify-content-center">
                <div className="w-75 position-relative">
                    <CustomInput model={AdminRequestAttestDenyModel} attribute="reason" customChange={e => onchangeDenyReason(e)}/>
                    <div className={`error tooltip-custom-normal ${errorReason === true ? 'opacity-1' : 'opacity-0'}`}
                         style={{color: '#f56e6e'}}>
                        {t('validationMessage.fieldRequired')}
                    </div>
                </div>
            </div>
            <div>{t('validationMessage.modalAttestDeny')}</div>
        </>
    );

    const contentFooterDeny = (
        <>
            <Button className="box-green border-0" onClick={async () => {
                setShowConfirmModalApprove(false); // Close Modal
                await onValidate("deny");
            }}>{t('button.accept')}</Button>
            {' '}
            <Button className="box-red border-0" onClick={() => {
                setShowConfirmModalDeny(false);
                setErrorReason(false);
                $("#btnApproveAttest").removeClass("disabled");
                $(`#${data.id}`).removeClass("disabled");
            }}>{t('button.cancel')}</Button>
        </>
    );

    return(
        <>
            <Col lg={12} className="pb-5">
                <Card className="card-backend p-4">
                    <CustomModal isOpen={showConfirmModalApprove} toggle={() => setShowConfirmModalApprove(!showConfirmModalApprove)} body={contentBodyApproved} footer={contentFooterApproved}/>
                    <CustomModal isOpen={showConfirmModalDeny} toggle={() => setShowConfirmModalDeny(!showConfirmModalDeny)} body={contentBodyDeny} footer={contentFooterDeny} />
                    <Row>
                        <Col lg={6}>
                            <h2 className="rokkitt-font">{t('attest.labelInfo')}</h2>
                        </Col>
                        <Col lg={6} className="text-end pb-3">
                            {data.createdBy !== userData?.id && data.status === "Skickat" && permissionDeny ?
                                /*<CustomButton text={t("attest.deny")} iconSize={4} customClasses="rounded me-2 box-red" buttonBorder={false} id={data.id} onClick={() => {
                                    onChangeDenyId(data.id);
                                    setShowConfirmModalDeny(true);
                                }} />*/
                                <Button id={data.id} className="rounded me-2 box-red border-0"
                                        onClick={() => {
                                            onChangeDenyId(data.id);
                                            setShowConfirmModalDeny(true);
                                            $("#btnApproveAttest").addClass("disabled");
                                            $(`#${data.id}`).addClass("disabled");
                                        }}
                                >
                                    {t("attest.deny")}
                                </Button>

                            : ''}
                            {data.createdBy !== userData?.id && data.status === "Skickat" && permissionUpdate ?
                                <Button id="btnApproveAttest" className="rounded border-0 btn-submit-kassan"
                                        onClick ={() => {
                                            setShowConfirmModalApprove(true);
                                            onChange();
                                            $("#btnApproveAttest").addClass("disabled");
                                            $(`#${data.id}`).addClass("disabled");


                                        }}
                                >
                                    {t('attest.approve')}
                                </Button>
                            : ''}
                        </Col>
                        {data !== null ?
                            <Col lg={12}>
                                <Card className="px-1 py-3 p-lg-3">
                                    <Row className="px-2 px-lg-4">
                                        <Col lg={8}>
                                            <ul className="list-unstyled">
                                                {isSuperAdmin === true ?
                                                    <>
                                                        <li className="pt-2 row">
                                                            <span className="fw-bold col-6 col-lg-3">{t('modelLabel.id')}</span>
                                                            <span className="col-6 col-lg-9">{data.id}</span>
                                                        </li>
                                                        <li className="pt-2 row">
                                                            <span className="fw-bold col-6 col-lg-3">{t('modelLabel.orgId')}</span>
                                                            <span className="col-6 col-lg-9">{data.orgId}</span>

                                                        </li>
                                                    </>
                                                    : ''}
                                                {data.visualId !== "" ?
                                                    <li className="pt-2 row">
                                                        <span className="fw-bold col-6 col-lg-3">{t('modelLabel.id')}:</span>
                                                        <span className="col-6 col-lg-9">{data.visualId}</span>
                                                    </li>
                                                : ""}
                                                <li className="pt-2 row">
                                                    <span className="fw-bold col-6 col-lg-3">{t('modelLabel.orgName')}</span>
                                                    <span className="col-6 col-lg-9">{data.orgName}</span>
                                                </li>
                                                <li className="pt-2 row">
                                                    <span className="fw-bold col-6 col-lg-3">{t('modelLabel.createdAt')}</span>
                                                    <span className="col-6 col-lg-9">{ChangeDateFormat('dateTime', data.createdAt)}</span>

                                                </li>
                                                {isSuperAdmin === true ?
                                                    <li className="pt-2 row">
                                                        <span className="fw-bold col-6 col-lg-3">{t('modelLabel.createdBy')}</span>
                                                        <span className="col-6 col-lg-9">{data.createdBy}</span>

                                                    </li>
                                                    : ''}
                                                <li className="pt-2 row">
                                                    <span className="fw-bold col-6 col-lg-3">{t('modelLabel.creatorName')}</span>
                                                    <span className="col-6 col-lg-9">{data.createdByName}</span>

                                                </li>
                                                <li className="pt-2 row">
                                                    <span className="fw-bold col-6 col-lg-3">{t('modelLabel.attestAmount')}</span>
                                                    <span className="col-6 col-lg-9">{data.amount ? ToCustomDecimal(data.amount) : 0} kr</span>

                                                </li>
                                                <li className="pt-2 row">
                                                    <span className="fw-bold col-6 col-lg-3">{t('modelLabel.status')}</span>
                                                    <span className="col-6 col-lg-9">{data.status === "Nekad" ? t('attest.denied') : data.status === "Godkänd" ? t("attest.approved") : data.status === "Utbetald" ? t("modelLabel.paidOut") : t('reimbursement.sent')}</span>
                                                </li>
                                            </ul>
                                        </Col>
                                        {data.approvedDeniedByName && data.approvedDeniedByName !== "" && (data.status === "Nekad" || data.status === "Godkänd") ?
                                            <Col lg={4} className="bg-green-light rounded-2 p-4">
                                                <h5 className='rokkitt-font'>{t('modelLabel.approvedDeniedByName')}</h5>
                                                {data.approvedDeniedByName}
                                                {data.reason ?
                                                    <>
                                                        <h5 className='rokkitt-font mt-4'>{t('modelLabel.reason')}</h5>
                                                        {data.reason}
                                                    </>
                                                : ""}
                                                <ul className="list-unstyled">
                                                    <li className="pt-2 row">
                                                        <span className="fw-bold col-4">{t('attest.attested')}:</span>
                                                        <span className="col-8">{data.attested === true ? t('attest.approved') : t('attest.pending')}</span>

                                                    </li>
                                                    <li className="pt-2 row">
                                                        <span className="fw-bold col-4">{t('modelLabel.updatedAt')}:</span>
                                                        <span className="col-8">{ChangeDateFormat('dateTime',data.updatedAt)}</span>

                                                    </li>
                                                    {isSuperAdmin === true ?
                                                        <li className="pt-2 row">
                                                            <span className="fw-bold col-4">{t('modelLabel.updatedBy')}:</span>
                                                            <span className="col-8">{data.updatedBy}</span>

                                                        </li>
                                                        : ''}
                                                    <li className="pt-2 row">
                                                        <span className="fw-bold col-4">{t('modelLabel.updatedBy')}:</span>
                                                        <span className="col-8">{data.updatedByName}</span>

                                                    </li>

                                                </ul>
                                            </Col>
                                        : ''}
                                        {data.denied && data.reason ?
                                            <Col lg={4} className="bg-green-light rounded-2 p-4">
                                                <ul className="list-unstyled">
                                                    <li className="pt-2 row">
                                                        <span className="fw-bold col-3">{t('modelLabel.reason')}:</span>
                                                        <span className="col-9">{data.reason}</span>
                                                    </li>
                                                </ul>
                                            </Col>
                                        : ''}
                                        <Col lg={12} className="pt-3 pt-lg-0">
                                            <CustomButton text={moreInfo === true ? t('button.hideInfo') : t('button.moreInfo') } customClasses="rounded border-0" onClick={() => setMoreInfo(!moreInfo)}/>
                                        </Col>
                                    </Row>
                                    {moreInfo === true ?
                                        <>
                                            {data.model === "Arvode" ?
                                                <AttestHonorariumViews data={data.modelInfo} modelId={data.modelId}/>
                                            : ""}
                                            {data.model === "Förrättning" ?
                                                <AttestReimbursementViews data={data.modelInfo} modelId={data.modelId}/>
                                            : ""}
                                            {data.model === "Betala" ?
                                                <Row className="px-4 justify-content-center">
                                                    <hr className="mt-4" />
                                                    <AttestPayViews data={data.modelInfo} modelId={data.modelId}/>
                                                </Row>

                                            : ""}
                                            {data.model === "Faktura" ?
                                                <div className="mx-2 mx-lg-4">
                                                    <hr className="mt-4" />
                                                    <Row>
                                                        <Col lg={6}>
                                                            <h2 className="rokkitt-font">{t('invoice.invoice')}</h2>
                                                        </Col>
                                                        <Col lg={6} className="text-end">
                                                            <span className="fw-bold">Id:</span> {data?.modelInfo?.visualId}
                                                        </Col>

                                                    </Row>
                                                    <CardCreditInvoiceInfo dataInvoice={data.modelInfo} type="Attest"/>
                                                </div>


                                                : ""}
                                        </>
                                        : ''}
                                </Card>
                            </Col>
                            : ''}
                    </Row>
                </Card>
            </Col>
        </>
    )
}