import {Button, Card, Col, Row} from "reactstrap";
import {API_URL, APP_URL, ORGANIZATION_PATHS} from "../../config";
import {CustomButton} from "../../Common/Components/Button/CustomButton";
import React, {useState, useEffect, useRef} from "react";
import {useTranslation} from "react-i18next";
import {Link, useNavigate} from "react-router-dom";
import LogoLRF from "../../AppSystem/assets/img/Logo-LRF.png";
import useApiCallService from "../../Common/Helpers/ApiCall";
import ResponseQRCode from "../Models/Response/ResponseQRCode";
import {CustomReload} from "../../AppSystem/Helpers/CustomReload";
import {CheckUserPermission} from "../../AppSystem/Helpers/CheckUserPermission";
import {ToCustomDecimal} from "../../AppSystem/Helpers/ToCustomDecimal";
import {generateFileName} from "../../AppSystem/Helpers/GenerateFileName";
import SortableList from "../../Common/Components/Lists/SortableList";
import ResponseQRPayment from "../Models/Response/ResponseQRPayment";
import $ from "jquery";

export function QRCodeInfo() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { ApiCall } = useApiCallService();
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id') ?? "";
    const [infoQR, setInfoQR] = useState(new ResponseQRCode());
    const [inactiveQR, setInactiveQR] = useState({});
    const [isDownloading, setIsDownloading] = useState(false);

    let permissionUpdate = false;
    if (CheckUserPermission("org-qr.update") || CheckUserPermission("org-qr")) {
        permissionUpdate = true;
    }
    let permissionCreate = false;
    if (CheckUserPermission("org-qr.create") || CheckUserPermission("org-qr")) {
        permissionCreate = true;
    }


    // GET QR
    useEffect(() => {
        if (id !== "") {
            const handleApiResponseQR = (apiResponse) => {
                setInfoQR(new ResponseQRCode(apiResponse));
            };
            const fetchData = async () => {
                try {
                    const apiCallOptions = {
                        method: "get",
                        url: `/orgqr?id=${id}`
                    };
                    await ApiCall(apiCallOptions, handleApiResponseQR);
                } catch (error) {
                    console.log('error', error);
                }
            };
            fetchData();
            setInactiveQR(()=>({
                id: id,
            }));
        }
    }, []);


    const [activateCollection, setActivateCollection] = useState(null);

    useEffect(() => {
        if (activateCollection !== null) {
            CustomReload()
        }
    }, [activateCollection]);


    const handleInactiveQR = async () => {
        if (infoQR.status === 'Aktiv') {
            inactiveQR.status = 'Inaktiv'
        } else {
            inactiveQR.status = 'Aktiv'
        }
        const apiCallOptions = {
            method: "put",
            url: '/orgqr',
            data: inactiveQR
        };
        try {
            await ApiCall(apiCallOptions, setActivateCollection);
            $("#disableCollection").prop("disabled", true);
            $("#downloadQR").removeClass("btn-submit-kassan");
            $("#downloadQR").addClass("disabled-imitation");
            $("#editQR").prop("disabled", true);
            //$("#editQR").addClass("disabled");
        } catch (error) {
            console.log(error);
        }
    }
    const columns = [ 'provider', 'method', 'comment', 'createdAt', 'updatedAt', 'amount'];
    const listOptions = {
        "model": ResponseQRPayment,
        'columns': columns,
        /*"actions": {
            "actionColumn" : renderActionColumn,
        },*/
        "exportIsAvailable": true,
        "urlGetListItemsToExport": (id !== "" ? "/orgqr/payment/search?orgQrId=" + id : "/orgqr/payment/search"),
        "nameFile": t('QRCode.orgQRLabel'),
        "ignoreFields" : columns,
        "urlGetSearch" : (id !== "" ? "/orgqr/payment/search?orgQrId=" + id : "/orgqr/payment/search"),
    };

    const  handleDownload = (e) =>{
        if (isDownloading) {
            // Prevenir clics adicionales si está descargando
            e.preventDefault();
            return;
        }
        setIsDownloading(true);

        // Simulamos que la descarga tomará 2 segundos
        setTimeout(() => {
            setIsDownloading(false);
        }, 2000);
    }

    return (
        <>
            <Col lg={12} className="px-0">
                <CustomButton
                    text={t('button.back')}
                    icon={'bx bx-chevron-left'}
                    buttonSize={'small'}
                    customClasses={'mb-3 ms-3 backButton'}
                    onClick={() => {
                        navigate(ORGANIZATION_PATHS.QR_CODE_LIST)
                    }}
                />
            </Col>
            {infoQR.id !== '' ?
                <>
                    <Col lg={12} className="pb-3">
                        <Card className="card-backend p-4">
                            <Row>
                                <h2 className='rokkitt-font'>{t('QRCode.label')}</h2>
                                <Col xl={12} className="text-center">
                                    <img src={LogoLRF} alt={t('accessibility.logo')} className='img-fluid'/>
                                    <h2 className="rokkitt-font pt-4 pb-3">{infoQR.title}</h2>
                                    <p className="px-3">{infoQR.description}</p>
                                    <Row className="pb-3">
                                        <Col lg={3} className="mx-auto">
                                           <img src={API_URL + infoQR.fileUrl} className="img-fluid"/>
                                        </Col>
                                        <Link to={'/qr-code-payment?id='+infoQR.id } target="_blank" className="text-info" >{APP_URL+'/qr-code-payment?id='+infoQR.id }</Link>
                                    </Row>
                                    <Row>
                                        <Col lg={12} className="text-center">
                                            <a id="downloadQR" href={API_URL + infoQR.filePDFUrl} download={generateFileName('orgQR', infoQR.filePDFId)} onClick={handleDownload} className={`btn ${isDownloading ? "bg-grey-tab text-secondary" : "btn-submit-kassan text-white"} mt-3 me-2`}> {isDownloading ? t('modelLabel.downloading') : t('modelLabel.download')}</a>
                                            {permissionCreate === true ?
                                                <Button id="editQR" className=" mt-3 btn-submit-kassan border-0 rounded"
                                                        onClick={() => {
                                                            navigate(`${ORGANIZATION_PATHS.QR_CODE_CREATE}?id=${infoQR.id}`)
                                                        }}>{t('button.edit')}</Button>
                                            : ''}
                                        </Col>
                                        {permissionUpdate === true ?
                                            <Col lg={6} className="mx-auto">
                                                <Button id="disableCollection" className={`w-100 mt-3 ${infoQR.status === 'Aktiv' ? 'box-red' : 'btn-submit-kassan'}  border-0 rounded`} onClick={() =>{
                                                    handleInactiveQR()
                                                }}> {infoQR.status === 'Aktiv' ? t('QRCode.disable') : t('QRCode.enable')}</Button>
                                            </Col>
                                        : ''}
                                    </Row>
                                    <h5 className="rokkitt-font pt-4 pb-3">{t('QRCode.totalCollected')}: {ToCustomDecimal(infoQR.totalPaid)} kr </h5>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col lg={12} className="pb-3">
                        <div className="p-4 card-backend">
                            <h2 className="fw-bold rokkitt-font">{t('QRCode.paymentsReceived')}</h2>
                            <SortableList listOptions={listOptions} />
                        </div>
                    </Col>
                </>
            : ''}
        </>
    );
}