import {Card, Col, Row} from "reactstrap";
import {CustomButton} from "../../Common/Components/Button/CustomButton";
import React from "react";
import {useTranslation} from "react-i18next";
import {ToCustomDecimal} from "../../AppSystem/Helpers/ToCustomDecimal";

export function CustomReimbursementMileageView({data, editMode, onClickDelete, onClickEdit}) {
    const { t } = useTranslation();
    return(
        <>
            <Card className="bg-card-grey p-3">
                <Row>
                    <Col lg={12}>
                        <Row className="d-flex align-items-center">
                            <Col xxl={data?.status !== 'Förslag' ||  editMode === true ? 12 : 9}  className={`order-1 order-xxl-0 ${data?.status !== 'Förslag' || editMode === true ? 'pe-3 ' : 'pe-0 '}`}>
                                <Row className="pb-2">
                                    <Col lg={6}>
                                        <span className="pe-2">{data?.from}</span> <span className="pe-2">-</span>
                                        {data?.to}
                                    </Col>
                                    <Col lg={3} className="text-start text-lg-end ps-3 ps-lg-4">
                                        {data?.km } km
                                    </Col>
                                    <Col lg={3} className="text-start text-lg-end ps-lg-0">
                                        <span className="ps-1" >{data?.amount ? ToCustomDecimal(data?.amount) : 0} kr</span>
                                    </Col>
                                </Row>
                                {/*Previous design just in case, delete when the new one is approved*/}
                                {/*<Row className="pb-2">*/}
                                {/*    <Col lg={7}>*/}
                                {/*        <span className="fs-6 fw-medium pe-1">{t('modelLabel.from')}:</span>*/}
                                {/*        <span className="pe-2">{data.from}</span> <span className="pe-2">-</span>*/}
                                {/*        <span className="fs-6 fw-medium pe-1">{t('modelLabel.to')}:</span>*/}
                                {/*        {data.to}*/}
                                {/*    </Col>*/}
                                {/*    <Col lg={5} className="text-start text-lg-end ps-lg-0">*/}
                                {/*        <Row>*/}
                                {/*            <Col lg={7} className="col-auto fs-6 fw-medium pe-lg-0">{t('modelLabel.kilometers')}:</Col>*/}
                                {/*            <Col lg={5} className="col-auto ps-1" >{data.km } km</Col>*/}
                                {/*        </Row>*/}
                                {/*    </Col>*/}
                                {/*</Row>*/}

                                {data?.passengers && data?.passengers !== null && data?.passengers.length > 0 ?
                                    <Row>
                                        <Col lg={6}>
                                            <span className="fs-6 pe-1">{t('reimbursement.numberPassengers')}: </span>
                                            {data?.passengers[0]?.quantity}
                                        </Col>
                                        <Col lg={3} className="text-start text-lg-end ps-3 ps-lg-4">
                                            {data?.passengers[0]?.km} km
                                        </Col>
                                        <Col lg={3} className="text-start text-lg-end ps-lg-0">
                                            <span className="ps-1" >{data.passengers[0].amount !== 0 ? ToCustomDecimal(data.passengers[0].amount) : 0} kr</span>
                                        </Col>
                                    </Row>
                                    // <Row className="pb-2">
                                    //     <Col lg={7} className="pb-2">
                                    //         <span className="fs-6 fw-medium pe-1">{t('reimbursement.numberPassengers')}:</span>
                                    //         {data.passengers[0].quantity}
                                    //     </Col>
                                    //     <Col lg={5} className="text-start text-lg-end ps-lg-0">
                                    //         <Row>
                                    //             <Col lg={7} className="col-auto fs-6 fw-medium  pe-lg-0">{t('modelLabel.kilometers')}:</Col>
                                    //             <Col lg={5} className="col-auto ps-1">{data.passengers[0].km} km</Col>
                                    //         </Row>
                                    //
                                    //     </Col>
                                    // </Row>
                                    : ''}
                                {/*<Row>*/}
                                {/*    <Col lg={6}>*/}
                                {/*        <h6>{t('modelLabel.total')}:</h6>*/}
                                {/*    </Col>*/}
                                {/*    <Col lg={6} className="text-start text-lg-end">*/}
                                {/*        {data.totalAmount.toFixed(2)} {t('modelLabel.kr')}*/}
                                {/*    </Col>*/}

                                {/*</Row>*/}
                            </Col>
                            {data?.status !== 'Förslag' ?
                                ''
                                :
                                    editMode !== true ?
                                        <Col xxl={3} className="d-flex align-items-center justify-content-end order-0 order-xxl-1">

                                            <CustomButton text="" icon='bx bx-trash-alt py-1' iconColor='#EB5757'
                                                          iconSize={5} buttonColor='#e9cbcd'
                                                          buttonRadius={3} hoverButtonRadius={3}
                                                          hoverIconColor='white' hoverButtonColor='#EB5757'
                                                          customClasses="border-0"
                                                          tooltipText={t('button.delete')}
                                                          onClick={onClickDelete}
                                            />
                                            <CustomButton text="" icon='bx bx-edit py-1' iconColor='#157553'
                                                          iconSize={5} buttonColor='#bed1cd'
                                                          buttonRadius={3} hoverButtonRadius={3}
                                                          hoverIconColor='white' hoverButtonColor='#157553'
                                                          customClasses="ms-3 border-0"
                                                          tooltipText={t('button.edit')}
                                                          onClick={onClickEdit}/>

                                        </Col>
                                        : ''

                            }
                        </Row>
                    </Col>
                    {/*<Col lg={12}>*/}
                    {/*    <Row>*/}
                    {/*        <Col lg={5}>*/}
                    {/*            <h6>{t('reimbursement.origin')}:</h6>*/}
                    {/*            <div className="box-grey">*/}
                    {/*                {requestReimbursementMileage.from}*/}
                    {/*            </div>*/}
                    {/*        </Col>*/}
                    {/*        <Col lg={1} className='align-self-end text-center mb-1 pb-2'>*/}
                    {/*            -*/}
                    {/*        </Col>*/}
                    {/*        <Col lg={6}>*/}
                    {/*            <h6>{t('reimbursement.destination')}:</h6>*/}
                    {/*            <div className="box-grey">*/}
                    {/*                {requestReimbursementMileage.to}*/}
                    {/*            </div>*/}
                    {/*        </Col>*/}
                    {/*        <Col lg={5} xxl={8} className="pt-4">*/}
                    {/*            <h6>{t('modelLabel.kilometers')}:</h6>*/}
                    {/*            <div className="box-grey">*/}
                    {/*                {requestReimbursementMileage.km}*/}
                    {/*            </div>*/}
                    {/*        </Col>*/}
                    {/*        <Col lg={1} xxl={1} className='align-self-end text-center mb-1 pb-2'>*/}
                    {/*            =*/}
                    {/*        </Col>*/}
                    {/*        <Col lg={6} xxl={3} className="pt-4 align-self-end">*/}
                    {/*            <div*/}
                    {/*                className="box-beig">{requestReimbursementMileage.amount} {t('modelLabel.kr')}*/}
                    {/*            </div>*/}
                    {/*        </Col>*/}
                    {/*        {requestReimbursementMileage.passengers !== null && requestReimbursementMileage.passengers.length > 0 ?*/}
                    {/*            <>*/}
                    {/*                <Col lg={12}  xxl={5} className="pt-4">*/}
                    {/*                    <h6>{t('reimbursement.numberPassengers')}:</h6>*/}
                    {/*                    <div className="box-grey">*/}
                    {/*                        {requestReimbursementMileage.passengers[0].quantity}*/}
                    {/*                    </div>*/}
                    {/*                </Col>*/}
                    {/*                <Col lg={5} xxl={3} className="pt-4 align-self-end">*/}
                    {/*                    <h6>{t('modelLabel.kilometers')}:</h6>*/}
                    {/*                    <div className="box-grey">*/}
                    {/*                        {requestReimbursementMileage.passengers[0].km} {t('modelLabel.km')}*/}
                    {/*                    </div>*/}
                    {/*                </Col>*/}
                    {/*                <Col lg={1}*/}
                    {/*                     className='align-self-end text-center mb-1 pb-2'>*/}
                    {/*                    =*/}
                    {/*                </Col>*/}
                    {/*                <Col lg={6} xxl={3} className="pt-4 align-self-end">*/}
                    {/*                    <div className="box-beig">*/}
                    {/*                        {requestReimbursementMileage.passengers[0].amount} {t('modelLabel.kr')}*/}
                    {/*                    </div>*/}
                    {/*                </Col>*/}
                    {/*            </>*/}
                    {/*            : ''}*/}
                    {/*        <Col lg={6} xxl={3} className="pt-4">*/}
                    {/*            <h6>{t('modelLabel.total')}:</h6>*/}
                    {/*            <div*/}
                    {/*                className="box-beig">{requestReimbursementMileage.amount} {t('modelLabel.kr')}*/}
                    {/*            </div>*/}
                    {/*        </Col>*/}
                    {/*        {requestReimbursementMileage.passengers !== null && requestReimbursementMileage.passengers.length > 0 ?*/}
                    {/*            <>*/}
                    {/*                <Col lg={1} xxl={1}*/}
                    {/*                     className='align-self-end pb-2 text-center pt-4'>*/}
                    {/*                    +*/}
                    {/*                </Col>*/}
                    {/*                <Col lg={5}  xxl={3} className="pt-4 align-self-end">*/}
                    {/*                    <div className="box-beig">*/}
                    {/*                        {requestReimbursementMileage.passengers[0].amount} {t('modelLabel.kr')}*/}
                    {/*                    </div>*/}
                    {/*                </Col>*/}
                    {/*                <Col lg={1} xxl={1}*/}
                    {/*                     className='align-self-end pb-2 text-center pt-4'>*/}
                    {/*                    =*/}
                    {/*                </Col>*/}
                    {/*                <Col lg={11} xxl={4} className="pt-4 align-self-end">*/}
                    {/*                    <div className="box-beig">*/}
                    {/*                        {requestReimbursementMileage.totalAmount.toFixed(2)} {t('modelLabel.kr')}*/}
                    {/*                    </div>*/}
                    {/*                </Col>*/}
                    {/*            </>*/}
                    {/*            : ''}*/}
                    {/*    </Row>*/}
                    {/*</Col>*/}
                </Row>
            </Card>
        </>
    );

}