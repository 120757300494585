import React, {useEffect, useState} from "react";
import {Card, Col, Row, Spinner} from "reactstrap";
import {CustomInput} from "../../Common/Components/Input/CustomInput";
import {CustomSubmitButton} from "../../Common/Components/Input/CustomSubmitButton";
import ResponseOrgModel from "../../Org/Models/Response/ResponseOrgModel";
import useApiCallService from "../../Common/Helpers/ApiCall";
import {FlashMessage} from "../../Common/Helpers/FlashMessage";
import {ADMIN_PATHS} from "../../config";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {CheckDecimal} from "../../Common/Helpers/CheckDecimal";
import AdminRequestInternalTransaction from "../Models/Request/AdminRequestInternalTransaction";
import $ from "jquery";
import {CustomReload} from "../../AppSystem/Helpers/CustomReload";
import {InfoPageMessage} from "../../Common/Components/Button/InfoPageMessage";
import ResponseDigitalAccount from "../../DigitalAccount/Models/Response/ResponseDigitalAccount";
import {CardDigitalAccount} from "../../DigitalAccount/Components/CardDigitalAccount";
import {LoadingData} from "../../Common/Components/LoadingData";
import {SaveLocalStorage} from "../../Common/Helpers/SaveLocalStorage";
import {LoadLocalStorage} from "../../Common/Helpers/LoadLocalStorage";
import {FlashMessageInsufficientAccountAmount} from "../../Common/Helpers/FlashMessageInsufficientAccountAmount";

export function AdminInternalTransactionsCreate() {
    const { t } = useTranslation();
    const { ApiCall } = useApiCallService();
    const navigate = useNavigate();

    const [requestInternalTransaction, setRequestInternalTransaction] = useState(new AdminRequestInternalTransaction());
    const [responseInternalTransaction, setResponseInternalTransaction] = useState(null);
    const [responseDigitalAccount, setResponseDigitalAccount] = useState(new ResponseDigitalAccount());
    const [responseDigitalAccountDestination, setResponseDigitalAccountDestination] = useState(new ResponseDigitalAccount());

    const completeSchemaInternalTransaction = AdminRequestInternalTransaction.getAttributes( null, true);

    const [apiResponseOrg, setApiResponseOrg] = useState('');
    const [done, setDone] = useState(false);
    const [displaySpinner, setDisplaySpinner] = useState(false);
    const [selectedOrg, setSelectedOrg] = useState(null);
    const [selectedDestinationOrg, setSelectedDestinationOrg] = useState(null);

    const [errorMessage, setErrorMessage] = useState('');

    // GET ORGANIZATIONS FOR SELECT
    useEffect(() => {
        const handleApiResponse = (apiResponse) => {
                setApiResponseOrg(apiResponse);
        };
        const orgData = async () => {
            try {
                const apiCallOptions = {
                    method: "get",
                    url: "/org/list-dropdown",
                };
                await ApiCall(apiCallOptions, handleApiResponse);
            } catch (error) {
                console.log('error', error);
            }
        };
        orgData();
    }, []);

    let jsonOrg = {};
    if (apiResponseOrg !== null && apiResponseOrg !== '') {
        const orgArray = [];
        apiResponseOrg.items.forEach((item) => {
            const orgModel = new ResponseOrgModel(item);
            orgArray.push(orgModel);
        });
        jsonOrg = {
            orgId: orgArray,
        };
    }

    // CHECK IF THERE ARE LOCAL STORAGE VALUES
    useEffect(() => {
        const originOrgId = LoadLocalStorage('originOrgId');
        const message = LoadLocalStorage('message');
        if (originOrgId) {
            setSelectedOrg(originOrgId);
        }
        setRequestInternalTransaction((prevFormData) => ({
            ...prevFormData,
            originOrgId: originOrgId ?? '',
            message: message ?? ''
        }));
    },[apiResponseOrg]);

    // HANDLE CHANGE
    const handleChange = (e, name) => {
        let value;
        if (name === "originOrgId") {
            if (apiResponseOrg !== null && apiResponseOrg !== '') {
                apiResponseOrg.items.map(item => {
                    if (e.value !== undefined) {
                        if (item.id === e.value) {
                            setSelectedOrg(item.id);
                            if (item.name === "LEAB") {
                                SaveLocalStorage('originOrgId', item.id);
                            } else {
                                localStorage.removeItem('originOrgId');
                            }
                            value = e.value;
                        }
                    }
                })
            }
        }
        if (name === "destinationOrgId") {
            if (apiResponseOrg !== null && apiResponseOrg !== '') {
                apiResponseOrg.items.map(item => {
                    if (e.value !== undefined) {
                        if (item.id === e.value) {
                            setSelectedDestinationOrg(item.id);
                            value = e.value;
                        }
                    }
                })
            }
        }
        if (name === 'amount') {
            if (e && e.target && e.target.value) {
                value = CheckDecimal(e.target.value);
            } else {
                value = 0;
            }
        }
        if (name === 'message') {
            if (e && e.target && e.target.value) {
                console.log("si")
                value = e.target.value;
                if (value !== "") {
                    SaveLocalStorage('message', value);
                } else {
                    localStorage.removeItem('message');
                }
            } else {
                localStorage.removeItem('message');
            }
        }
        setRequestInternalTransaction((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    }

    // HANDLE VALIDATION
    const handleValidation = async () => {
        $('#submitInternalTransaction').prop('disabled', true);
        try {
            if ($("#destinationError").hasClass('opacity-0')) {
                await completeSchemaInternalTransaction.validate(requestInternalTransaction, {abortEarly: false});
                if (requestInternalTransaction && requestInternalTransaction.originOrgId !== "" && requestInternalTransaction.destinationOrgId !== "") {
                    if (requestInternalTransaction.message === "") {
                        requestInternalTransaction.message = null;
                    }
                    const apiCallOptions = {
                        method: "post",
                        url: "/internaltransaction/admin",
                        data: requestInternalTransaction,
                    };
                    try {
                        await ApiCall(apiCallOptions, setResponseInternalTransaction, setErrorMessage);
                        setDisplaySpinner(true);
                        setTimeout(() => {
                            setDone(true);
                        }, 1000);
                    } catch (error) {
                        console.log(error);
                    }
                }
            }
        } catch (err) {
            console.log(err);
        }
    }

    // REDIRECT TO INFO PAGE
    useEffect(() => {
        if (done) {
            if (responseInternalTransaction) {
                FlashMessage(t('validationMessage.internalTransactionSuccessfully'), 'flash-messageSuccess');
                setTimeout(() => {
                    CustomReload(ADMIN_PATHS.INTERNAL_TRANSACTION);
                }, 2000);
            } else {
                if (errorMessage.includes("{")) {
                    FlashMessage(<FlashMessageInsufficientAccountAmount jsonString={errorMessage} />, 'flash-messageError', 10000);
                    $('#submitInternalTransaction').prop('disabled', false);
                }
                else {
                    FlashMessage(t('validationMessage.organizationNotMoney'), 'flash-messageError');
                    $('#submitInternalTransaction').prop('disabled', false);
                }
            }
            setDone(false);
            setDisplaySpinner(false);
            setErrorMessage('');
        }
    }, [done]);

    //GET DIGITAL ACCOUNT BY ID ORG
    useEffect(() => {
        if(selectedOrg !== null){
        const handleDigitalAccountResponse = (responseApiDigitalAccount) => {
            if(responseApiDigitalAccount !== null) {
                setResponseDigitalAccount(responseApiDigitalAccount);
            }
        };
        const DigitalAccountOrgApiCall = async () => {
            try {
                const apiCallOptions = {
                    method: "get",
                    url: "/org/list-dropdown?orgId=" + selectedOrg + "&account=true",
                };
                await ApiCall(apiCallOptions, handleDigitalAccountResponse);
            } catch (error) {
                console.log('error', error);
            }
        };
        DigitalAccountOrgApiCall();
          }

    }, [selectedOrg]);

    useEffect(() => {
        if(selectedDestinationOrg !== null){
            const handleDigitalAccountResponse = (responseApiDigitalAccount) => {
                if(responseApiDigitalAccount !== null) {
                    setResponseDigitalAccountDestination(responseApiDigitalAccount);
                }
            };
            const DigitalAccountOrgDestinationApiCall = async () => {
                try {
                    const apiCallOptions = {
                        method: "get",
                        url: "/org/list-dropdown?orgId=" + selectedDestinationOrg + "&account=true",
                    };
                    await ApiCall(apiCallOptions, handleDigitalAccountResponse);
                } catch (error) {
                    console.log('error', error);
                }
            };
            DigitalAccountOrgDestinationApiCall();
        }

    }, [selectedDestinationOrg]);

    return(
        <>
            <Col lg={6} className="pb-4 ps-0">
                <Card className="card-backend p-4">
                    <Row>
                        <Col xs='auto'>
                            <h2 className='rokkitt-font'>{t('internalTransaction.create')}</h2>
                        </Col>
                        <InfoPageMessage message={t('infoBox.internalTransaction')}/>
                    </Row>

                    <Row>
                        {apiResponseOrg !== '' && apiResponseOrg !== null ?
                            <>
                                <Col lg={6}>
                                    <CustomInput model={AdminRequestInternalTransaction} attribute='originOrgId' data={requestInternalTransaction.originOrgId} customChange={(e) => handleChange(e, 'originOrgId')} customOptions={jsonOrg} />
                                    {responseDigitalAccount.id !== '' ?
                                        <CardDigitalAccount data={responseDigitalAccount}/>
                                        : ''}
                                </Col>
                                <Col lg={6} className="position-relative">
                                    <CustomInput model={AdminRequestInternalTransaction}
                                                 attribute='destinationOrgId' data={''}
                                                 customChange={(e) => handleChange(e, 'destinationOrgId')}
                                                 customOptions={jsonOrg}/>

                                    <div id="destinationError" className={`error tooltip-custom-normal ${requestInternalTransaction.originOrgId !== "" && requestInternalTransaction.destinationOrgId !== "" && requestInternalTransaction.originOrgId === requestInternalTransaction.destinationOrgId ? 'opacity-1' : 'opacity-0'}`}
                                        style={{color: '#f56e6e'}}>
                                        {t('validationMessage.sameOrg')}
                                    </div>
                                    {responseDigitalAccountDestination.id !== '' ?
                                        <CardDigitalAccount data={responseDigitalAccountDestination}/>
                                    : ''}
                                </Col>
                                <Col lg={6}>
                                    <CustomInput model={AdminRequestInternalTransaction} attribute='amount' data={''}
                                                 customChange={(e) => handleChange(e, 'amount')}/>
                                </Col>
                                <Col lg={6} className="position-relative">
                                    <i className="bx bx-x fs-4 position-absolute end-0 pt-4 mt-3 z-3 me-3" onClick={() => {
                                        setRequestInternalTransaction((prevFormData) => ({
                                            ...prevFormData,
                                            message: ""
                                        }));
                                        localStorage.removeItem('message');
                                    }}/>
                                    <CustomInput model={AdminRequestInternalTransaction} attribute='message' data={requestInternalTransaction.message}
                                                 customChange={(e) => handleChange(e, 'message')}/>
                                </Col>
                                <Col lg={12} className="text-end">
                                    {displaySpinner === true ?
                                        <>
                                            {t('validationMessage.sendingInternalTransaction')} <Spinner color='success' className="me-3" />
                                        </>
                                        : ""}
                                    <CustomSubmitButton text={t('button.send')} id='submitInternalTransaction' icon='bx bx-send pe-2' customClick={handleValidation} />
                                </Col>
                            </>
                            :
                            <LoadingData data={apiResponseOrg}/>
                        }
                    </Row>
                </Card>
            </Col>

        </>
    )
}