import * as yup from "yup";
import {string} from "yup";
import i18n from "i18next";
import {ChangeDateFormat} from "../../../Common/Helpers/ChangeDateFormat";

class RequestReimbursementCommon {
    constructor({
                    date = '',
                    toDate = '',
                    purpose = '',
                    costBearerId = '',
                    orgId = '',
                } = {}) {
        this.date = date;
        this.toDate = toDate;
        this.purpose = purpose;
        this.costBearerId = costBearerId;
        this.orgId = orgId;
    }

    static getAttributes(filter = null, validationSchema = null, optionsData = null) {

        let optionsCostBearer = [];
        if (optionsData && optionsData.costBearer) {
            optionsData.costBearer.map(item => {
                optionsCostBearer.push({
                    label: item.name,
                    value: item.id
                });
            })
        }

        let optionsOrg = [];
        if (optionsData && optionsData.org) {
            optionsData.org.map(item => {
                optionsOrg.push({
                    label: item.name,
                    value: item.id
                });
            })
        }

        const completeSchema = yup.object().shape({
            date: string().nullable(),
            toDate: string().nullable(),
            purpose: string().nullable(),
            costBearerId: string().nullable(),
            orgId: string().nullable(),
        });

        const attributes = {
            date :{
                "id": "date",
                //'label': i18n.t('modelLabel.dateReimbursement'),
                'form': {
                    'type': 'date',
                    'placeholder': i18n.t('formPlaceholder.enterDateReimbursement'),
                    'validation': completeSchema.fields.date,
                    "maxDate": new Date(),
                },
                'value': (item) => item.date ? ChangeDateFormat('date', item.date) : "",
            },
            toDate :{
                "id": "toDate",
                'label': i18n.t('modelLabel.toDateReimbursement'),
                'form': {
                    'type': 'date',
                    'placeholder': i18n.t('formPlaceholder.enterDateReimbursement'),
                    'validation': completeSchema.fields.date,
                    "maxDate": new Date(),
                },
                'value': (item) => item.toDate ? ChangeDateFormat('date', item.toDate) : "",
            },
            purpose :{
                'label': i18n.t('reimbursement.purpose'),
                'form': {
                    'type': 'textarea',
                    "rows": "1",
                    'placeholder': i18n.t('formPlaceholder.enterPurpose'),
                    'validation': completeSchema.fields.purpose,
                },
                'value': (item) => item.purpose || "",
            },
            costBearerId :{
                'label': i18n.t('reimbursement.costBearer'),
                'form': {
                    'type': 'select',
                    'options': optionsCostBearer,
                    'default':'',
                    'placeholder': 'Select one option',
                    'validation': completeSchema.fields.costBearerId,
                },
                'value': (item) => item.costBearerId || "",
            },
            orgId :{
                'label': i18n.t('modelLabel.org'),
                'form': {
                    'type': 'select',
                    'options': optionsOrg,
                    'default': '',
                    'placeholder': 'Select one option',
                    'validation': completeSchema.fields.orgId,
                },
                'value': (item) => item.orgId || "",
            }
        }

        if (validationSchema === true) {
            return completeSchema;
        }

        return attributes;
    }
}
export default RequestReimbursementCommon;