import {Col, Row} from "reactstrap";
import {CardDigitalAccount} from "../Components/CardDigitalAccount";
import React, {useEffect, useState} from "react";
import useApiCallService from "../../Common/Helpers/ApiCall";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import ResponseAccountRowModel from "../Models/Response/ResponseAccountRowModel";
import {ADMIN_PATHS} from "../../config";
import {CustomButton} from "../../Common/Components/Button/CustomButton";
import SortableList from "../../Common/Components/Lists/SortableList";
import ResponseDigitalAccountModel from "../Models/Response/ResponseDigitalAccount";
import AdminResponseAttestModel from "../../Attest/Model/Response/AdminResponseAttestModel";
import {CheckUserRole} from "../../AppSystem/Helpers/CheckUserRole";
import {SaveLocalStorage} from "../../Common/Helpers/SaveLocalStorage";
import {ToCustomDecimal} from "../../AppSystem/Helpers/ToCustomDecimal";

export function AdminDigitalAccountView() {
    const { ApiCall } = useApiCallService();
    const idValue = new URLSearchParams(window.location.search).get('id');
    const { t } = useTranslation();
    const navigate = useNavigate();
    const[apiCallDone, setApiCallDone] = useState('');

    //API RESPONSE
    const [apiResponseDigitalAccount, setApiResponseDigitalAccount] = useState(new ResponseDigitalAccountModel());

    useEffect(() => {
        const handleApiResponseDigitalAccount = (apiResponseDigitalAccount) => {
            setApiResponseDigitalAccount(apiResponseDigitalAccount);
        };
        const GetDigitalAccount = async () => {
            if (idValue !== null) {
                try {
                    const apiCallOptions = {
                        method: "get",
                        url: `/digital-account/admin?id=${idValue}`,
                    };
                    await ApiCall(apiCallOptions, handleApiResponseDigitalAccount );
                } catch (error) {
                    console.log('error', error);
                }
            }
        };
        GetDigitalAccount();
    }, [idValue]);


    const renderActionColumn = (item) => {
        return (
            <>
                <CustomButton icon = "bx bx-show py-1" iconColor="#157553" hoverIconColor='white' hoverButtonColor='#A3C2B8' iconSize={4}  buttonColor="#cde0da"  tooltipText={t('button.info')} customClasses="rounded" buttonBorder={false} id={item.id} onClick={() => {
                    navigate(`${ADMIN_PATHS.ACCOUNT_ROW_INFO}?id=${item.id}`);
                    SaveLocalStorage('account', idValue)
                }} />
            </>
        );
    }

    const isSuperAdmin = CheckUserRole('SuperAdmin');
    const columns = [
        "visualId", 'model', 'description','createdAt','amount',
    ];

    if (isSuperAdmin === true) {
        columns.unshift('id', 'modelId');
    }

    const listOptions = {
        "model": ResponseAccountRowModel,
        'columns':columns,
        "actions": {
            "actionColumn" : renderActionColumn,
        },
        "exportIsAvailable": true,
        "urlGetListItemsToExport": `/digital-account/admin/account-row/search?accountId=${idValue}`,
        "nameFile": t('digitalAccount.label'),
        "ignoreFields" : columns,
        "modelType" : "AdminDigitalAccount",
        "urlGetSearch" : `/digital-account/admin/account-row/search?accountId=${idValue}`,
    };
    return(
        <>
            <Col lg={12}>
                <Row className="pb-5">
                    <Col lg={12}>
                        <CustomButton
                            text={t('button.back')}
                            icon={'bx bx-chevron-left'}
                            buttonSize={'small'}
                            customClasses={'ms-3 mb-3'}
                            onClick={() => {
                                navigate(ADMIN_PATHS.DIGITAL_ACCOUNT_LIST)
                            }}
                        />
                    </Col>
                    <Col lg={12} className="p-4 card-backend position-relative">
                        {apiResponseDigitalAccount.visualId !== "" ?
                            <div className="position-absolute end-0 mt-4 me-4 top-0 fs-5">{apiResponseDigitalAccount.visualId}</div>
                            : ""}
                        <h2 className="rokkitt-font">{t('digitalAccount.label')}</h2>
                        <Row>
                            <Col lg={4}>
                                <CardDigitalAccount data={apiResponseDigitalAccount}/>
                            </Col>
                        </Row>

                    </Col>
                   {apiCallDone !== null ?
                        <Col lg={12} className="card-backend mt-3 p-4">
                            <h2 className='rokkitt-font'>{t('digitalAccount.accountRowList')}</h2>
                            <SortableList
                                listOptions={listOptions}
                                setApiCallDone={setApiCallDone}
                            />
                        </Col>
                    : ''}

                </Row>
            </Col>
        </>
    )
}