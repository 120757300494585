import React, { useEffect, useState } from 'react';
import {
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row
} from 'reactstrap';
import gitHub from '../../../AppSystem/assets/img/brands/github.png';
import bitbucket from '../../../AppSystem/assets/img/brands/bitbucket.png';
import dribbble from '../../../AppSystem/assets/img/brands/dribbble.png';
import dropbox from '../../../AppSystem/assets/img/brands/dropbox.png';
import mail_chimp from '../../../AppSystem/assets/img/brands/mail_chimp.png';
import slack from '../../../AppSystem/assets/img/brands/slack.png';
import avatarImage1 from '../../../AppSystem/assets/img/users/avatar-1.jpg';
import '../../../AppSystem/assets/css/simplebar.css';
import {Link, useLocation} from "react-router-dom";
import {CheckMobile} from "../../../AppSystem/Helpers/CheckMobile";
import {ButtonChangeLanguage} from "../../../Common/Components/Button/ButtonChangeLanguage";
import {logoutUser} from "../../../AppSystem/Redux/Auth/AuthSlice";
import {useDispatch, useSelector} from "react-redux";
import {PUBLIC_PATHS, USER_PATHS} from "../../../config";
import NotificationDropdown from '../../../Common/Components/Button/NotificationDropdown.js';
import {LoadLocalStorage} from "../../../Common/Helpers/LoadLocalStorage";
import {SaveLocalStorage} from "../../../Common/Helpers/SaveLocalStorage";
import {useTranslation} from "react-i18next";
import { redirectWhenCloseSession } from '../../../Common/Helpers/RedirectWhenCloseSession.js';

export function Header({title,/* onMenuClick*/}) {
  const { t } = useTranslation();
  const [navClass, setnavClass] = useState(false);
  const windowWidth = CheckMobile(819);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const actualPath = pathname.replace(/^.+\//, "");
  const {userData, tokenData, refreshTokenData} = useSelector(state => state.auth);
  useEffect(() => {
    window.addEventListener('scroll', scrollNavigation, true);
  });
  const headerTitle = LoadLocalStorage('headerTitle');
  const search = LoadLocalStorage("search");

  // const languageCheck =LoadLocalStorage('selectedLanguage');
  //
  // useEffect(() => {
  //   headerTitle = headerTitle
  // }, [languageCheck]);

  function scrollNavigation() {
    var scrollup = document.documentElement.scrollTop;
    if (scrollup > 50) {
      setnavClass('sticky');
    } else {
      setnavClass('');
    }
  }


  //Webapps Dropdown
  const [webapps, setWebapps] = useState(false);
  const toggleWebapps = () => {
    setWebapps(!webapps);
  };

  //Notification Dropdown
  const [notification, setNotification] = useState(false);
  const toggleNotification = () => {
    setNotification(!notification);
  };

  //Profile Dropdown

  const [profile, setProfile] = useState(false);
  const toggleProfile = () => {
    setProfile(!profile);
  };

  return (
    <>
      <header id="page-topbar" className='box-shadow-custom'>
        <div className="navbar-header border-bottom">
          <div className='w-25'></div>
          <div className="d-flex">
           <ButtonChangeLanguage type="backend" />
            {/*<Dropdown className="d-none d-lg-inline-block" isOpen={webapps} toggle={toggleWebapps}>
              <DropdownToggle tag="button" type="button" className="btn header-item noti-icon">
                <i className="bx bx-grid-alt font-size-20" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
                <div className="p-3">
                  <Row className="align-items-center">
                    <Col>
                      <h5 className="m-0 font-size-15"> Web Apps </h5>
                    </Col>
                    <div className="col-auto">
                      <Link to="#" className="small fw-semibold text-decoration-underline">
                        {' '}
                        View All
                      </Link>
                    </div>
                  </Row>
                </div>
                <div className="px-lg-2 pb-2">
                  <Row className="g-0">
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={gitHub} alt="Github" />
                        <span>GitHub</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={bitbucket} alt="bitbucket" />
                        <span>Bitbucket</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={dribbble} alt="dribbble" />
                        <span>Dribbble</span>
                      </Link>
                    </Col>
                  </Row>

                  <Row className="g-0">
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={dropbox} alt="dropbox" />
                        <span>Dropbox</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={mail_chimp} alt="mail_chimp" />
                        <span>Mail Chimp</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={slack} alt="slack" />
                        <span>Slack</span>
                      </Link>
                    </Col>
                  </Row>
                </div>
              </DropdownMenu>
            </Dropdown>*/}
           <NotificationDropdown notification={notification} toggleNotification={toggleNotification}/>
            {/*<div className="dropdown d-inline-block">
              <button
                type="button"
                className="btn header-item noti-icon right-bar-toggle"
                id="right-bar-toggle"
              >
                <i className="bx bx-cog fs-4" />
              </button>
            </div>*/}
            <Dropdown className={`d-inline-block ${windowWidth ? '' : 'bg-main'} border-bottom`} aria-label={t('accessibility.userOptions')} isOpen={profile} toggle={toggleProfile}>
              <DropdownToggle tag="a" type="button" className="btn header-item user text-start d-flex align-items-center" id="page-header-user-dropdown" to="#">
                <Row>
                  <Col lg={4} className="align-self-center">
                    <i className="bx bx-user-circle fs-4 align-middle"></i>
                  </Col>
                  {windowWidth ? '' :
                      <Col lg={8} className="ps-0 pe-2">
                        <p className={`fw-bold font-size-14 align-bottom ${(userData.firstName + " " + userData.lastName).length > 12 ? "lh-sm" : ""} mb-0`}>{userData.firstName} {userData.lastName}</p>
                        {/*<div>{userData.roles}</div>*/}
                      </Col>
                  }
                </Row>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end rounded">
                  <h6 className="dropdown-header">  {t('modelLabel.welcome')} {userData.firstName} {userData.lastName}!</h6>
                <DropdownItem tag={Link} to={USER_PATHS.PROFILE} onClick={() => {
                    SaveLocalStorage('headerTitle', t('modelLabel.profile'));
                  if (search) {
                    localStorage.removeItem('search');
                  }
                  localStorage.removeItem('currentPage');
                  localStorage.removeItem('itemsPerPage');

                  // REMOVE LOCAL STORAGE FROM INTERNAL TRANSACTION
                  if (window.location.href.includes("internal-transaction") === false) {
                    localStorage.removeItem('originOrgId');
                    localStorage.removeItem('message');
                  }
                }}>
                  <i className="bx bxs-user-circle text-muted font-size-16 align-middle me-1"></i> <span className="align-middle">{t('modelLabel.profile')}</span>
                </DropdownItem>
                <DropdownItem tag={Link} to={USER_PATHS.SETTINGS} onClick={() => {
                    SaveLocalStorage('headerTitle', t('userSetting.settings'));
                  if (search) {
                    localStorage.removeItem('search');
                  }
                  localStorage.removeItem('currentPage');
                  localStorage.removeItem('itemsPerPage');

                  // REMOVE LOCAL STORAGE FROM INTERNAL TRANSACTION
                  if (window.location.href.includes("internal-transaction") === false) {
                    localStorage.removeItem('originOrgId');
                    localStorage.removeItem('message');
                  }
                }}>
                  <i className="bx bx-cog text-muted font-size-16 align-middle me-1"></i> <span className="align-middle">{t('userSetting.settings')}</span>
                </DropdownItem>

                {/*<DropdownItem tag={Link} to="/apps-chat">*/}
                {/*  <i className="bx bx-message-dots text-muted font-size-16 align-middle me-1"></i> <span className="align-middle">Messages</span>*/}
                {/*</DropdownItem>*/}
                {/*<DropdownItem tag={Link} to="/pages-faqs">*/}
                {/*  <i className="bx bx-help-circle text-muted font-size-16 align-middle me-1"></i> <span className="align-middle">Help</span>*/}
                {/*</DropdownItem>*/}

                <div className="dropdown-divider"></div>
                {/*<DropdownItem tag={Link} to="#">*/}
                {/*  <i className="bx bxs-wallet text-muted font-size-16 align-middle me-1"></i>{' '}*/}
                {/*  <span className="align-middle">*/}
                {/*    Balance : <b>$6951.02</b>*/}
                {/*  </span>*/}
                {/*</DropdownItem>*/}
                {/*<DropdownItem tag={Link} to="/auth-lock-screen">*/}
                {/*  <i className="bx bxs-lock text-muted font-size-16 align-middle me-1"></i> <span className="align-middle">Lock screen</span>*/}
                {/*</DropdownItem>*/}
                <DropdownItem onClick={() => {
                  dispatch(logoutUser());
                  redirectWhenCloseSession(tokenData);
                  SaveLocalStorage('headerTitle', t('dashboard.dashboard'));
                  if (search) {
                    localStorage.removeItem('search');
                  }
                  localStorage.removeItem('currentPage');
                  localStorage.removeItem('itemsPerPage');

                  // REMOVE LOCAL STORAGE FROM INTERNAL TRANSACTION
                  if (window.location.href.includes("internal-transaction") === false) {
                    localStorage.removeItem('originOrgId');
                    localStorage.removeItem('message');
                  }
                }}>
                  <i className="bx bx-log-out text-muted font-size-16 align-middle me-1"></i> <span className="align-middle">{t('modelLabel.logOut')}</span>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
       {/* <Row className='bg-white'>
          <Col xs={12} id='title-breadcrumb' className="d-sm-flex align-items-center justify-content-between">
            <h4 className="text-start ps-4 pt-2">{headerTitle}</h4>
            Do verification for dropdown links
            <div className={`page-title-right pe-3 ${windowWidth ? 'ps-4 pb-2' : ''}`}>
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item"><Link to={USER_PATHS.APP}  className="text-muted">pageTitle kassan</Link></li>
                {actualPath !== "/" && actualPath !== "/dashboard" ? (<li className="breadcrumb-item active">{headerTitle}</li>) : ""}
              </ol>
            </div>
          </Col>
        </Row>*/}
      </header>
    </>
  );
}
