import {Input} from "reactstrap";

export const NumberInput = ({ name, value, id = null, placeholder = null, inputSize = null, validation, customOnChange = null, oneDecimal = null, possibleDecimalValue = null }) => {
    let visualValue = "";
    if (value) {
        visualValue = value.toString().replace(".", ",");
    }

    let inputSizeValue = "";
    if (inputSize !== null) {
        inputSizeValue = inputSize === 'long' ? 'lg' : 'sm';
    }

    // ON INPUT FUNCTION
    const onInput = (e) => {
        if (oneDecimal !== null && oneDecimal === true) {
            e.target.value = e.target.value.match(/^\d+\,?\d{0,1}/);
        } else if (oneDecimal !== null && oneDecimal === false) {
            e.target.value = e.target.value.match(/^\d+/);
        } else {
            e.target.value = e.target.value.match(/^\d+\,?\d{0,2}/);
        }
        if (possibleDecimalValue !== null) {
            if (e.target.value.includes(",")) {
                const lastLetter = e.target.value.charAt(e.target.value.length - 1);
                if (lastLetter !== "0" && lastLetter !== "," && lastLetter !== possibleDecimalValue.toString()) {
                    e.target.value = e.target.value.slice(0, -2);
                }
            }
        }
    }

    // ON CHANGE FUNCTION
    const onChange = (e) => {
        validation(e);
        if (customOnChange !== null && typeof customOnChange === 'function') {
            customOnChange(e);
        }
    }

    return (
        <Input className='flex-grow-1' id={id} name={name} placeholder={placeholder} type="text" bsSize={inputSizeValue} value={visualValue}
               onBlur={(e) => validation(e)}
               onInput={(e) => onInput(e)}
               onChange={(e) => onChange(e)}
        />
    );
}