import {Card, Col, Row} from "reactstrap";
import {CustomButton} from "../../Common/Components/Button/CustomButton";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {ToCustomDecimal} from "../../AppSystem/Helpers/ToCustomDecimal";
import {ChangeDateFormat} from "../../Common/Helpers/ChangeDateFormat";

export function CustomReimbursementFixedView({data, editMode, onClickDelete, onClickEdit, isFixed = false, dataFixed = null }) {
    const { t } = useTranslation();

    useEffect(() => {
        if (data && data.length > 0) {
            data.sort((a, b) => new Date(a.salaryDate) - new Date(b.salaryDate));
        }
    }, [data]);

    // IS FIXED
    if (isFixed === true && dataFixed !== null) {
        return (
            <Col lg={12} className="mt-1">
                <Card className="bg-card-grey py-2 px-3">
                    <Row className="d-flex align-items-center">
                        <Col xxl={dataFixed?.status !== 'Förslag' || editMode === true ? 12 : 9} className={`order-1 order-xxl-0 ${dataFixed?.status !== 'Förslag' || editMode === true ? 'pe-3 ' : 'pe-0 '}`}>
                            <Row>
                                <Col lg={3} className="">
                                    {t('reimbursement.fixed')}
                                </Col>
                                <Col lg={3} className=" d-flex align-items-center ps-3 ps-lg-4 justify-content-start justify-content-lg-end">
                                    {dataFixed.salaryDate ? ChangeDateFormat("date", dataFixed.salaryDate) : ""}
                                </Col>
                                <Col lg={6} className="text-end">
                                    {dataFixed?.amount ? ToCustomDecimal(dataFixed?.amount) : 0} kr
                                </Col>
                            </Row>
                        </Col>
                        {dataFixed?.status !== 'Förslag' ?
                            ''
                            :
                            editMode !== true ?
                                <Col xxl={3}
                                     className="d-flex align-items-center justify-content-end order-0 order-xxl-1">
                                    <CustomButton text="" icon='bx bx-trash-alt py-1' iconColor='#EB5757'
                                                  buttonColor='#e9cbcd'
                                                  buttonRadius={3} hoverButtonRadius={3}
                                                  hoverIconColor='white' hoverButtonColor='#EB5757'
                                                  customClasses="border-0"
                                                  tooltipText={t('button.delete')}
                                                  onClick={()=> {
                                                      onClickDelete()
                                                  }}
                                    />
                                    <CustomButton text="" icon='bx bx-edit  py-1' iconColor='#157553'
                                                  buttonColor='#bed1cd'
                                                  buttonRadius={3} hoverButtonRadius={3}
                                                  hoverIconColor='white' hoverButtonColor='#157553'
                                                  customClasses="ms-3 border-0"
                                                  tooltipText={t('button.edit')}
                                                  onClick={()=> {
                                                      onClickEdit(null)
                                                  }}
                                    />
                                </Col>
                                : ''
                        }
                    </Row>
                </Card>
            </Col>
        );
    } else {
        // IS MOVABLE
        return (
            <div className="bg-card-grey rounded">
                {data && data.length > 0 ? (
                    data.map((item, index) => {
                        return (
                            <Col lg={12} key={`fixed${item?.salaryDate}`}>
                                <Card className={`bg-card-grey px-3 ${index === 0 ? "py-1" : "py-2"}`}>
                                    <Row className="d-flex align-items-center">
                                        <Col xxl={(item?.status === 'Förslag' || item?.status === "") && editMode !== true ? 9 : 12}
                                             className={`order-1 order-xxl-0 ${(item?.status === 'Förslag' || item?.status === "") && editMode !== true ? 'pe-0 ' : 'pe-3 '}`}>
                                            {item?.type === 'Fixed' ?
                                                <>
                                                    <Row>
                                                        <Col lg={3} className="">
                                                            {t('reimbursement.fixed')}
                                                        </Col>
                                                        <Col lg={3}
                                                             className=" d-flex align-items-center ps-3 ps-lg-4 justify-content-start justify-content-lg-end">
                                                            {item.salaryDate ? ChangeDateFormat("date", item.salaryDate) : ""}
                                                        </Col>
                                                        <Col lg={6} className="text-end">
                                                            {item?.amount ? ToCustomDecimal(item?.amount) : 0} kr
                                                        </Col>
                                                    </Row>
                                                    {/* //Previous design just in case, delete when the new one is approved
                                                <Row className="pb-2">
                                                    <Col lg={5}>
                                                        <h6>{t('modelLabel.type')}:</h6>
                                                    </Col>
                                                    <Col lg={7} className="text-start text-lg-end">
                                                        {t('reimbursement.fixed')}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg={5}>
                                                        <h6>{t('modelLabel.amount')}:</h6>
                                                    </Col>
                                                    <Col lg={7} className="text-start text-lg-end">
                                                        {item.amount} {t('modelLabel.kr')}
                                                    </Col>
                                                </Row>*/}

                                                </>
                                                :
                                                <>
                                                    <Row>
                                                        <Col lg={3}>
                                                            {index === 0 ? t('reimbursement.movable') : ''}
                                                        </Col>
                                                        <Col lg={3}
                                                             className=" d-flex align-items-center ps-3 ps-lg-4 justify-content-start justify-content-lg-end">
                                                            {item.salaryDate ? ChangeDateFormat("date", item.salaryDate) : ""}
                                                        </Col>
                                                        <Col lg={3}
                                                             className=" d-flex align-items-center ps-3 ps-lg-4 justify-content-start justify-content-lg-end">
                                                            {item?.hours ? ToCustomDecimal(item?.hours) : 0} h
                                                        </Col>
                                                        <Col lg={3}
                                                             className=" d-flex align-items-center justify-content-start justify-content-lg-end">
                                                            {item?.amount ? ToCustomDecimal(item?.amount) : 0} kr
                                                        </Col>
                                                    </Row>
                                                    {/* //Previous design just in case, delete when the new one is approved
                                                <Row className="pb-2">
                                                    <Col lg={5}>
                                                        <h6>{t('modelLabel.type')}:</h6>
                                                    </Col>
                                                    <Col lg={7} className="text-start text-lg-end">
                                                        {t('reimbursement.hourly')}
                                                    </Col>
                                                </Row>
                                                <Row className="pb-2">
                                                    <Col lg={5}>
                                                        <h6>{t('modelLabel.hours')}:</h6>
                                                    </Col>
                                                    <Col lg={7} className="text-start text-lg-end">
                                                        {item.hours} {t('modelLabel.hours').toLowerCase()}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg={5}>
                                                        <h6>{t('modelLabel.amount')}:</h6>
                                                    </Col>
                                                    <Col lg={7} className="text-start text-lg-end">
                                                        {item.amount} {t('modelLabel.kr')}
                                                    </Col>
                                                </Row>*/}
                                                </>
                                            }
                                        </Col>
                                        {item?.status === 'Förslag' || item?.status === "" ?
                                            editMode !== true && index === 0 ?
                                                <Col xxl={3}
                                                     className="d-flex align-items-center justify-content-end order-0 order-xxl-1">
                                                    <CustomButton text="" icon='bx bx-trash-alt py-1'
                                                                  iconColor='#EB5757'
                                                                  buttonColor='#e9cbcd'
                                                                  buttonRadius={3} hoverButtonRadius={3}
                                                                  hoverIconColor='white' hoverButtonColor='#EB5757'
                                                                  customClasses="border-0"
                                                                  tooltipText={t('button.delete')}
                                                                  onClick={() => {
                                                                      onClickDelete()
                                                                  }}
                                                    />
                                                    <CustomButton text="" icon='bx bx-edit  py-1' iconColor='#157553'
                                                                  buttonColor='#bed1cd'
                                                                  buttonRadius={3} hoverButtonRadius={3}
                                                                  hoverIconColor='white' hoverButtonColor='#157553'
                                                                  customClasses="ms-3 border-0"
                                                                  tooltipText={t('button.edit')}
                                                                  onClick={() => {
                                                                      onClickEdit(item)
                                                                  }}
                                                    />
                                                </Col>
                                            : ''
                                        : ''}
                                    </Row>
                                </Card>
                            </Col>
                        )
                    })
                ) : ''}
            </div>
        );
    }
}