import React, {useEffect, useState} from 'react';
import { Header } from '../../Auth/Components/Nav/Header';
import { VerticalSidebar } from '../../Auth/Components/Nav/VerticalSidebarDesign';
import {CheckMobile} from "../Helpers/CheckMobile";
import {Row} from "reactstrap";
import Footer from "../../Auth/Components/Nav/Footer";

export function Main({ children }) {
  const [headerTitle, setHeaderTitle] = useState('Dashboard');
  const windowWidth = CheckMobile(998);
  const [elementHeight , setElementHeight] = useState(0);
  const [elementHeightTest , setElementHeightTest] = useState(0);

  const handleMenuClick = (newTitle) => {
    setHeaderTitle(newTitle);
  };

  useEffect(() => {
    const initialCheck = () => {
        const sidebarElement = document.getElementById('testId');
        if (sidebarElement) {
          setElementHeight(sidebarElement.offsetHeight)
        }
    }
    initialCheck();
  }, [children]);

  /*const pageHeight = Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight
  );*/

  /*useEffect(() => {
    const sidebarElementTest = document.getElementById('sideBar');
    if (sidebarElementTest) {
      setElementHeightTest(sidebarElementTest.offsetHeight)
    }
  },[])*/

 // console.log('elementHeightTest',elementHeightTest)
  /*useEffect(() => {
    const initialCheck = () => {
      const elementContainer = document.getElementById('layout-wrapper');
      if (elementContainer) {
        setElementHeightContainer(elementContainer.offsetHeight)
      }
    }
    initialCheck();
  }, [elementHeightContainer]);*/

  return (
    <div className="container-fluid p-0 bg-main vw-100">
      <div id="layout-wrapper" className="bg-darkBlue ">
        <Header title={headerTitle} /*onMenuClick={handleMenuClick}*//>
        <VerticalSidebar /*onMenuClick={handleMenuClick}*//>
        <div id="contentId" className="main-content pb-0 px-0 bg-main">
          <div className={`page-content ${windowWidth  ? 'min-height-contain-mobile': 'min-height-contain'}  px-3 px-lg-5`} >
            <Row id="testId" className={`${windowWidth ? 'pt-5 mt-0 mt-lg-5' : 'pt-4'} px-2 px-lg-0`}>
              {children}
            </Row>
          </div>
          <Footer/>
        </div>
      </div>
    </div>
  );
}
