import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter as Router} from 'react-router-dom';
import {Suspense, useMemo} from 'react';
import RouteIdentifier from './AppSystem/Routing/Components/RouteIdentifier';
import {DefineRoutes} from './AppSystem/Routing/DefineRoutes';
import {getRoutes} from './AppSystem/Routing/Helper';
import {Main} from "./AppSystem/Layout/Main";
import './AppSystem/assets/css/LightThemeCustom.css';
import {I18nextProvider, useTranslation} from 'react-i18next';
import i18n from "./AppSystem/i18n";
import {useDispatch, useSelector} from "react-redux";
import {logoutUser} from "./AppSystem/Redux/Auth/AuthSlice";
import {CheckExpired} from "./AppSystem/Helpers/CheckJwtExpired";
import {PUBLIC_PATHS} from "./config";
import {LoadLocalStorage} from "./Common/Helpers/LoadLocalStorage";
import {CustomReload} from "./AppSystem/Helpers/CustomReload";
import { redirectWhenCloseSession } from './Common/Helpers/RedirectWhenCloseSession';
import { Helmet } from 'react-helmet';

const response = await fetch('/branch-info.json');
const data = await response.json();
  const currentBranch = data.branch;

function App() {
    // LOGOUT USER IF REFRESH TOKEN IS EXPIRED
    const dispatch = useDispatch();  
    const {tokenData, refreshTokenData } = useSelector(state => state.auth);
    if (refreshTokenData && refreshTokenData?.expires != null&& CheckExpired('refreshToken', refreshTokenData?.expires) === true) {
        dispatch(logoutUser());
        redirectWhenCloseSession(tokenData)
    }

    let isCheckout = false;

    if (window.location.href.includes("checkout") || window.location.href.includes("qr-code-payment") || window.location.href.includes("qr-payment") || window.location.href.includes("redirect")) {
        isCheckout = true;
    }

    const title =
    currentBranch === 'develop'
      ? 'TEST Digitala Kassan'
      : currentBranch === 'master'
      ? 'LRF Digitala Kassan'
      : 'LRF Digitala Kassan';

    const { isAuthenticated } = useSelector(state => state.auth);
    const routesDataInfo = DefineRoutes();
    const routesData = useMemo(
        () =>
            getRoutes({
                data: routesDataInfo,
            }),
        [routesDataInfo],
    );

    return (
        <I18nextProvider i18n={i18n}>
             <Helmet>
                <title>{title}</title>
             </Helmet>
            <Router>
                <Suspense fallback="Loading">
                    { isAuthenticated && isCheckout === false ? (
                        <Main>
                            <div id="overlay"></div>
                            <div id="flash-message"></div>
                            <RouteIdentifier routes={routesData} />
                        </Main>
                    ) : (
                        <>
                            <div id="overlay"></div>
                            <div id="flash-message"></div>
                            <RouteIdentifier routes={routesData} />
                        </>
                    ) }
                </Suspense>
            </Router>
        </I18nextProvider>
    );

}
export default App;

