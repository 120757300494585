import React, {useEffect, useState} from "react";
import {Card, Col, Row} from "reactstrap";
import useApiCallService from "../../Common/Helpers/ApiCall";
import {ChangeDateFormat} from "../../Common/Helpers/ChangeDateFormat";
import {useNavigate} from "react-router-dom";
import {CustomButton} from "../../Common/Components/Button/CustomButton";
import {CustomInput} from "../../Common/Components/Input/CustomInput";
import {ADMIN_PATHS} from "../../config";
import ResponseUserModel from "../../User/Models/Response/ResponseUserModel";
import AdminResponseHonorarium from "../Models/Response/AdminResponseHonorarium";
import {FlashMessage} from "../../Common/Helpers/FlashMessage";
import ResponseOrgModel from "../../Org/Models/Response/ResponseOrgModel";
import {useTranslation} from "react-i18next";
import AdminRequestHonorariumUpdate from "../Models/Request/AdminRequestHonorariumUpdate";
import {CheckDecimal} from "../../Common/Helpers/CheckDecimal";
import {ToCustomDecimal} from "../../AppSystem/Helpers/ToCustomDecimal";
import {CheckUserPermission} from "../../AppSystem/Helpers/CheckUserPermission";
import CostBearerModel from "../../CostBearer/Models/Response/CostBearerModel";
import {CustomSubmitButton} from "../../Common/Components/Input/CustomSubmitButton";

const AdminHonorariumInfo = () => {
    const { t } = useTranslation();
    const { ApiCall } = useApiCallService();
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id') ?? "";
    const navigate = useNavigate();

    const [honorarium, setHonorarium] = useState(new AdminResponseHonorarium());

    const [editHonorarium, setEditHonorarium] = useState(new AdminRequestHonorariumUpdate());
    const [editMode, setEditMode] = useState(false);

    const [apiResponseUser, setApiResponseUser] = useState(null);
    const [apiResponseOrg, setApiResponseOrg] = useState(null);
    const [costResponse, setCostResponse] = useState(null);

    const [costJson, setCostJson] = useState({});
    const [jsonUsers, setJsonUsers] = useState({});
    const [jsonOrg, setJsonOrg] = useState({});

    const completeSchemaHonorarium = AdminRequestHonorariumUpdate.getAttributes( null, true);

    let permission = false;
    if (CheckUserPermission("admin") || CheckUserPermission("admin.honorarium") || CheckUserPermission("admin.honorarium.update")) {
        permission = true;
    }


    // GET HONORARIUM
    useEffect(() => {
        if (id !== "") {
            const handleApiResponse = (apiResponse) => {
                setHonorarium(new AdminResponseHonorarium(apiResponse));
            };
            const fetchData = async () => {
                try {
                    const apiCallOptions = {
                        method: "get",
                        url: `/honorarium/admin?id=${id}`
                    };
                    await ApiCall(apiCallOptions, handleApiResponse);
                } catch (error) {
                    console.log('error', error);
                }
            };
            fetchData();
        }
    }, []);

    // GET USERS FOR SELECT
    useEffect(() => {
        const userData = async () => {
            try {
                const apiCallOptions = {
                    method: "get",
                    url: '/user/admin/search?perPage=10000'
                };
                await ApiCall(apiCallOptions, setApiResponseUser);
            } catch (error) {
                console.log('error', error);
            }
        };
        userData()
    }, []);

    useEffect(() => {
        if (apiResponseUser !== null) {
            const userArray = [];
            apiResponseUser.items.forEach((item) => {
                const userModel = new ResponseUserModel(item);
                userArray.push(userModel);
            });
            setJsonUsers({
                userId: userArray,
            });
        }
    }, [apiResponseUser]);

    //GET ORGANIZATIONS FOR SELECT
    useEffect(() => {
        if (honorarium.userId !== "" || editHonorarium.userId !== "") {
            const handleApiResponse = (apiResponse) => {
                setApiResponseOrg(apiResponse);
            };
            const orgData = async () => {
                try {
                    const apiCallOptions = {
                        method: "get",
                        url: `/user/admin/org-user/search?userid=${editMode === true ? editHonorarium.userId : honorarium.userId}&perPage=10000`,
                    };
                    await ApiCall(apiCallOptions, handleApiResponse);
                } catch (error) {
                    console.log('error', error);
                }
            };
            orgData();
        }
    }, [honorarium.userId, editHonorarium.userId]);

    useEffect(() => {
        if (apiResponseOrg !== null) {
            const orgArray = [];
            apiResponseOrg.items.forEach((item) => {
                const orgModel = new ResponseOrgModel(item);
                orgArray.push(orgModel);
            });
            setJsonOrg({
                orgId: orgArray,
            });
        }
    }, [apiResponseOrg]);

    // API CALL TO GET COST BEARER
    useEffect(() => {
        if (editHonorarium.orgId !== "") {
            const handleCostResponse = (costResponse) => {
                setCostResponse(costResponse);
            };
            const CostApiCall = async () => {
                try {
                    const apiCallOptions = {
                        method: "get",
                        url: "/costbearer/list-org?id=" + editHonorarium.orgId,
                    };
                    await ApiCall(apiCallOptions, handleCostResponse);
                } catch (error) {
                    console.log('error', error);
                }
            };
            CostApiCall();
        }
    }, [editHonorarium.orgId]);

    useEffect(() => {
        if (costResponse !== null) {
            const costBearerArray = [];
            costResponse.forEach((item) => {
                const cosBearerModel = new CostBearerModel(item);
                costBearerArray.push(cosBearerModel);
            });
            setCostJson({
                costBearer: costBearerArray
            });
        }
    }, [costResponse]);

    const handleChangeHonorarium = (e, name) => {
        let value;
        if (e && e.target && e.target.value) {
            value = e.target.value;
        }
        if (name === 'annualHonorarium') {
            if (e.target.value !== "") {
                value = CheckDecimal(e.target.value);
            } else {
                value = 0;
            }
        }
        if (name === 'firstPayment') {
            value = ChangeDateFormat('date', e);
        }
        if (name === "userId") {
            if (apiResponseUser !== null) {
                apiResponseUser.items.map(item => {
                    if (e.value !== undefined) {
                        if (item.id === e.value) {
                            value = e.value;
                        }
                    }
                });
                setEditHonorarium((prevFormData) => ({
                    ...prevFormData,
                    orgId: '',
                }));
            }
        }
        if (name === "orgId") {
            if (apiResponseOrg !== null) {
                apiResponseOrg.items.map(item => {
                    if (e.value !== undefined) {
                        if (item.id === e.value) {
                            value = e.value;
                        }
                    }
                });
            }
        }

        if (name === "costBearerId" && costResponse !== null) {
            costResponse.forEach(item => {
                if (item.id === e.target.value) {
                    value = e.target.value;
                }
            });
        }
        setEditHonorarium((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    }

    const handleValidation = async () => {
        try {
            await completeSchemaHonorarium.validate(editHonorarium, { abortEarly: false });
            const apiCallOptions = {
                method: "put",
                url: "/honorarium/admin",
                data: editHonorarium,
            };
            try {
                await ApiCall(apiCallOptions, setHonorarium);
                setEditMode(false);
                FlashMessage(t('validationMessage.honorariumUpdate'), 'flash-messageSuccess');
            } catch (error) {
                console.log(error);
                FlashMessage(t('validationMessage.sendError'), 'flash-messageError');
            }
        } catch (err) {
            console.log(err)
        }
    }

    // TODO: MOVE TO CUSTOM INPUT
    const annualHonorariumInput = document.getElementById('annualHonorarium');
    if (annualHonorariumInput) {
        annualHonorariumInput.addEventListener('keypress', function(e) {
            const key = e.key;
            if (!((key >= '0' && key <= '9') || key === '.' || key === ',')) {
                e.preventDefault();
            }
        });
    }

    const paymentsDone = honorarium.queuedPayments.length > 0 ? honorarium.queuedPayments.some(item => item.done === true) : false;
    //const paid = t('modelLabel.paid');
    //const unpaid = t('modelLabel.unpaid');
    //const yearly = t('modelLabel.yearly');
    const monthly = t('modelLabel.monthly');

    return (
        <>
            <Col lg={12} className="px-0">
                <CustomButton
                    text={t('button.back')}
                    icon={'bx bx-chevron-left'}
                    buttonSize={'small'}
                    customClasses={'ms-3 mb-3'}
                    onClick={() => {
                        navigate(ADMIN_PATHS.HONORARIUM_LIST)
                    }}
                />
            </Col>
            {editMode && paymentsDone !== true ? (
                <>
                    <Col lg={6} className="pb-4 ps-0">
                        <Card className="card-backend p-4">
                            <Row>
                                <Col lg={12} className="text-center">
                                    <h2 className="rokkitt-font">{t('honorarium.update')}</h2>
                                </Col>
                                <Col lg={12}>
                                    <Card className="bg-card-grey p-3">
                                        <Col lg={12} className="d-flex justify-content-end">
                                            <CustomButton icon='bx bx-undo py-1' iconColor='#EB5757' iconSize={5} buttonColor='#e9cbcd' buttonRadius={3} hoverButtonRadius={3} hoverIconColor='white' hoverButtonColor='#EB5757' customClasses="border-0" onClick={() => { setEditMode(false) }} tooltipText={t('button.cancel')}/>
                                            <CustomSubmitButton icon="bx bx-check py-1" customClass="bg-green-light text-green ms-3" customClick={() => {handleValidation()}} tooltipText={t('button.save')}/>
                                        </Col>
                                        <Row>
                                            <Col lg={6}>
                                                <div className={'mb-3'}>
                                                    <CustomInput model={AdminRequestHonorariumUpdate} attribute='userId' data={editHonorarium.userId} customChange={(e) => {
                                                        handleChangeHonorarium(e, 'userId');
                                                        setEditHonorarium((prevFormData) => ({
                                                            ...prevFormData,
                                                            orgId: "",
                                                        }));
                                                    }} customOptions={jsonUsers}/>
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                {apiResponseOrg === null ? <span className="text-danger">¡¡ This User does not have any Organization !!</span> : ''}
                                                <CustomInput model={AdminRequestHonorariumUpdate} attribute='orgId' data={/*honorarium.orgId*/ editHonorarium.orgId} customChange={(e) => {
                                                    handleChangeHonorarium(e, 'orgId');
                                                    setEditHonorarium((prevFormData) => ({
                                                        ...prevFormData,
                                                        costBearerId: "",
                                                    }));
                                                }} customOptions={jsonOrg}/>
                                            </Col>
                                            <Col lg={6}>
                                                <CustomInput model={AdminRequestHonorariumUpdate} attribute='firstPayment' data={editHonorarium.firstPayment} customChange={(e) => handleChangeHonorarium(e, 'firstPayment')}/>
                                            </Col>
                                            <Col lg={6}>
                                                <CustomInput model={AdminRequestHonorariumUpdate} attribute='paymentInterval' data={editHonorarium.paymentInterval} customChange={(e) => handleChangeHonorarium(e, 'paymentInterval')}/>
                                            </Col>
                                            <Col lg={6}>
                                                <CustomInput model={AdminRequestHonorariumUpdate} attribute='annualHonorarium' data={editHonorarium.annualHonorarium} customChange={(e) => handleChangeHonorarium(e, 'annualHonorarium')}
                                                />
                                            </Col>
                                            {editHonorarium.orgId !== "" ?
                                                <Col lg={6}>
                                                    <CustomInput model={AdminRequestHonorariumUpdate} attribute='costBearerId' data={editHonorarium.costBearerId} customChange={(e) => handleChangeHonorarium(e, 'costBearerId')} customOptions={costJson}
                                                    />
                                                </Col>
                                            : ''}
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </>
            ) :
                (honorarium &&
                    <>
                        <Col lg={6} className="pb-4">
                            <Card className="card-backend px-2 px-lg-4 py-4 position-relative">
                                {honorarium.visualId !== "" ?
                                    <div className="position-absolute end-0 mt-4 me-4 top-0 fs-5">{honorarium.visualId}</div>
                                : ""}
                                <Row>
                                    <Col lg={12} className="text-center">
                                        <h2 className="rokkitt-font">{t('honorarium.honorarium')}</h2>
                                    </Col>
                                    <Col lg={12}>
                                        <Card className="bg-card-grey p-3">
                                            {!paymentsDone && permission && honorarium.status === "Skickat" ?
                                                <Col lg={12} className="d-flex justify-content-end">
                                                    <CustomButton text="" icon='bx bx-edit py-1' iconColor='#157553' iconSize={5} buttonColor='#bed1cd' buttonRadius={3} hoverButtonRadius={3} hoverIconColor='white' hoverButtonColor='#157553' customClasses="ms-3 border-0" onClick={() => {
                                                        setEditMode(true);
                                                        setEditHonorarium(new AdminRequestHonorariumUpdate(honorarium))
                                                    }} tooltipText={t('button.edit')} />
                                                </Col>
                                             : ('')}
                                            <Row>
                                                <Col lg={6}>
                                                    <h6>{t('modelLabel.recipient')}:</h6>
                                                    <div  className="box-grey"> {honorarium.userName}</div>
                                                </Col>
                                                <Col lg={6}>
                                                    <h6>{t('modelLabel.org')}:</h6>
                                                    <div className="box-grey">{honorarium.orgName}</div>
                                                </Col>
                                                <Col lg={6} className="pt-4">
                                                    <h6>{t('honorarium.first')}:</h6>
                                                    <div  className="box-grey"> {ChangeDateFormat('date', honorarium.firstPayment)}</div>
                                                </Col>
                                                <Col lg={6} className="pt-4">
                                                    <h6>{t('honorarium.payment')}:</h6>
                                                    <div  className="box-grey"> {honorarium.paymentInterval == "1" ? t('modelLabel.yearly'): honorarium.paymentInterval == "4" ? t('modelLabel.daily'): t('modelLabel.monthly')}</div>
                                                </Col>
                                                <Col lg={6}  className="pt-4">
                                                    <h6>{t('honorarium.annual')}:</h6>
                                                    <div  className="box-grey"> {honorarium.annualHonorarium ? ToCustomDecimal(honorarium.annualHonorarium) : 0} kr</div>
                                                </Col>
                                                <Col lg={6}  className="pt-4">
                                                    <h6>{t('reimbursement.costBearer')}:</h6>
                                                    <div  className="box-grey"> {honorarium.costBearerName}</div>
                                                </Col>
                                                <hr className="mt-4" />
                                                {honorarium.queuedPayments.length > 0 ?
                                                    <>
                                                        <h6>{t('honorarium.queued')}:</h6>
                                                        {honorarium.queuedPayments.map((honor, index) => (
                                                            <Col lg={12} className="pt-2">
                                                                <Row key={'honor' + index}>
                                                                    <Col xs={4} lg={4} className="px-1 px-lg-2">
                                                                        <div className="box-grey">{ChangeDateFormat('date', honor.date)}</div>
                                                                    </Col>
                                                                    <Col xs={4} lg={5} className="px-1 px-lg-2">
                                                                        <div className="box-beig">{honor.value ? ToCustomDecimal(honor.value) : 0} kr</div>
                                                                    </Col>
                                                                    <Col xs={4} lg={3} className="px-1 px-lg-2">
                                                                        <div className={honor.status === 'Utbetald' ? "box-grey bg-green-light text-green" : "box-grey"}>{honor.status}</div>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        ))}
                                                    </>
                                                : ''}
                                            </Row>
                                        </Card>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        {honorarium.approvedDeniedByName && honorarium.approvedDeniedByName !== "" && (honorarium.status === "Nekad" || honorarium.status === "Godkänd") ?
                            <Col xs={12} lg={6} clas>
                                <Card className="card-backend p-4 mb-4">
                                    <Row>
                                        <Col lg={12} className="text-center">
                                            <h2 className={`${honorarium.status === "Nekad" ? "text-red" : "text-green"} rokkitt-font`}>{honorarium.status === "Nekad" ? t('attest.denied') : t("attest.approved")}</h2>
                                        </Col>
                                        <Col lg={12}>
                                            <Card className="bg-card-grey p-3">
                                                <h5 className='rokkitt-font'>{t('modelLabel.approvedDeniedByName')}</h5>
                                                {honorarium.approvedDeniedByName}
                                                {honorarium.reason ?
                                                    <>
                                                        <h5 className='rokkitt-font mt-4'>{t('modelLabel.reason')}</h5>
                                                        {honorarium.reason}
                                                    </>
                                                    : ""}
                                            </Card>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        : ""}
                    </>
                )
            }
        </>
    );
};

export default AdminHonorariumInfo;