import {Card, Col, Row} from "reactstrap";
import useApiCallService from "../../Common/Helpers/ApiCall";
import React, {useEffect, useState} from "react";
import RequestUserSettingsModel from "../Model/Request/RequestUserSettingsModel";
import {CustomInput} from "../../Common/Components/Input/CustomInput";
import {CustomSubmitButton} from "../../Common/Components/Input/CustomSubmitButton";
import {CustomButton} from "../../Common/Components/Button/CustomButton";
import {FlashMessage} from "../../Common/Helpers/FlashMessage";
import ResponseUserSettingsModel from "../Model/Response/ResponseUserSettingsModel";
import $ from "jquery";
import {useTranslation} from "react-i18next";
import i18next from "i18next";
import {LoadLocalStorage} from "../../Common/Helpers/LoadLocalStorage";
import i18n from "i18next";


export function Settings() {
    const { t } = useTranslation();

    useEffect(() => {
        if (i18next.language !== LoadLocalStorage('selectedLanguage')){
            i18n.changeLanguage(LoadLocalStorage('selectedLanguage'));
        }
    }, []);



    const {ApiCall} = useApiCallService();
    // REQUESTS
    const [requestUserSettings, setRequestUserSettings] = useState(new RequestUserSettingsModel());

    // API RESPONSES
    const [responseUserSettings, setResponseUserSettings] = useState(new ResponseUserSettingsModel());

    //GET USER SETTING
    const [done, setDone] = useState(false);

    //EDIT MODE
    const [editModeSettings, setEditModeSettings] = useState(false);
    const [checkClickButton, setCheckClickButton] = useState(false);

    // VALIDATION SCHEMAS
    const completeSchemaUserSetting = RequestUserSettingsModel.getAttributes( null, true);

    //Checks so that they are only numbers
    const clearingNumberInput = document.getElementById('clearingNumber');
    if (clearingNumberInput) {
        clearingNumberInput.addEventListener('keypress', function (e) {
            const key = e.key;
            const currentValue = clearingNumberInput.value;

            const isValidChar = (key >= '0' && key <= '9')/* || key === '.' || key === ','*/;
            const hasReachedMaxLength = currentValue.replace(/[^0-9]/g, '').length >= 5;

            if (!isValidChar || hasReachedMaxLength) {
                e.preventDefault();
            }
        });
    }

    const accountNumberInput = document.getElementById('accountNumber');
    if (accountNumberInput) {
        accountNumberInput.addEventListener('keypress', function (e) {
            const key = e.key;
            const currentValue = accountNumberInput.value;

            const isValidChar = (key >= '0' && key <= '9')/* || key === '.' || key === ','*/;
            const hasReachedMaxLength = currentValue.replace(/[^0-9]/g, '').length >= 12;

            if (!isValidChar || hasReachedMaxLength) {
                e.preventDefault();
            }

        });
    }

    const SubmitSettings = async () => {
        try {
            await completeSchemaUserSetting.validate(requestUserSettings, { abortEarly: false });
            $('#submitUserSettings').prop('disabled', true);

            if (requestUserSettings.accountNumber !== "") {
                const apiCallOptions = {
                    method: "post",
                    url: "/usersetting/bank-account",
                    data: requestUserSettings,
                };
                try {
                    await ApiCall(apiCallOptions, setResponseUserSettings);
                    FlashMessage(  t('validationMessage.bankAccountSave'), 'flash-messageSuccess');
                    //setDone(true);
                } catch (error) {
                    console.log(error);
                     FlashMessage(t('validationMessage.sendErrorShort'), 'flash-messageError');
                }
            }
        } catch (err) {
            console.log(err)
        }
    };

    const HandleChange = (e, name) => {
        let value = e.target.value;
        setRequestUserSettings((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    }

    //Edit
    const EditSettings = async () => {
        try {
            await completeSchemaUserSetting.validate(requestUserSettings, { abortEarly: false });
            $('#editUserSettings').prop('disabled', true);

            if (requestUserSettings.accountNumber !== "") {
                const apiCallOptions = {
                    method: "put",
                    url: "/usersetting/bank-account",
                    data: requestUserSettings,
                };
                try {
                    await ApiCall(apiCallOptions, setResponseUserSettings);

                } catch (error) {
                    console.log(error);
                    FlashMessage(t('validationMessage.sendErrorShort'), 'flash-messageError');
                }
            }
        } catch (err) {
            console.log(err)
        }
    };

    //GET USER SETTING
    useEffect(() => {
        if(responseUserSettings !== null && responseUserSettings.clearingNumber === ''){
            const handleUserSettingsResponse = (responseUserSettings) => {
                if(responseUserSettings !== null) {
                    setResponseUserSettings(responseUserSettings);
                }
            };
            const CostApiCall = async () => {
                try {
                    const apiCallOptions = {
                        method: "get",
                        url: "/usersetting/bank-account",
                    };
                    await ApiCall(apiCallOptions, handleUserSettingsResponse);
                } catch (error) {
                    console.log('error', error);
                }
            };
            CostApiCall();
        }

    }, [responseUserSettings]);

    useEffect(() => {
        if(responseUserSettings === null) {
            FlashMessage(t('validationMessage.sendError'), 'flash-messageError');
            //$('#submitUserSettings').prop('disabled', false);
        }
        if(responseUserSettings !== null && responseUserSettings.clearingNumber !== '' && checkClickButton === true) {
            setDone(true);
        }
    }, [responseUserSettings]);

    useEffect(() => {
        if (done === true) {
            FlashMessage(  t('validationMessage.bankAccountUpdate'), 'flash-messageSuccess');
            setEditModeSettings(false);
            setDone(false);
            $('#editUserSettings').prop('disabled', false);
        }
    }, [done]);

    return(
        <Col lg={4} className='float-start ps-0'>
            <Card className='card-backend p-4'>
                <Row>
                    <Col lg='8'>
                        <h5>{t('userSetting.bankAccount')}</h5>
                    </Col>
                    <Col lg='4' className='text-end pl-0'>
                        {responseUserSettings !== null && responseUserSettings.clearingNumber !== '' && editModeSettings === false ?
                            <CustomButton text="" icon='bx bx-edit py-1' iconColor='#157553'
                                          iconSize={5} buttonColor='#bed1cd'
                                          buttonRadius={3} hoverButtonRadius={3}
                                          hoverIconColor='white' hoverButtonColor='#157553'
                                          customClasses="ms-3 border-0"
                                          tooltipText={t('button.edit')}
                                          onClick={() => {
                                              setEditModeSettings( true);
                                              setRequestUserSettings(responseUserSettings);
                                          }}/>
                            :
                            ''
                        }
                        {editModeSettings === true ?
                            <>
                                <CustomButton icon='bx bx-undo py-1' iconColor='#EB5757' iconSize={5} buttonColor='#e9cbcd' buttonRadius={3} hoverButtonRadius={3} hoverIconColor='white' hoverButtonColor='#EB5757' customClasses="border-0"  tooltipText={t('button.cancel')} onClick={() => { setEditModeSettings(false) }}
                                />
                                <CustomButton text="" icon='bx bx-check py-1' iconColor='#157553' id='editUserSettings'
                                              iconSize={5} buttonColor='#bed1cd'
                                              buttonRadius={3} hoverButtonRadius={3}
                                              hoverIconColor='white' hoverButtonColor='#157553'
                                              customClasses="ms-3 border-0"
                                              tooltipText={t('button.save')}
                                              onClick={async () => {
                                                  await EditSettings();
                                                  setCheckClickButton(true);
                                              }}/>
                            </>
                            :
                            ''
                        }
                    </Col>
                    { responseUserSettings !== null && responseUserSettings.clearingNumber !== '' && editModeSettings === false  ?
                        <>
                            <Col lg={12}>
                                <div className="fw-bold pb-1">{t('userSetting.modelClearingNumber')}</div>
                                <div className="box-grey">{responseUserSettings.clearingNumber}</div>
                            </Col>
                            <Col lg={12} className='pt-3'>
                                <div className="fw-bold pb-1">{t('userSetting.modelAccountNumber')}</div>
                                <div className="box-grey">{responseUserSettings.accountNumber}</div>

                            </Col>
                        </>
                        :
                        <>
                            <CustomInput model={RequestUserSettingsModel} attribute='clearingNumber' data={responseUserSettings !== null && responseUserSettings.clearingNumber !== '' ? responseUserSettings.clearingNumber :''} customChange={(e)=>{HandleChange(e,'clearingNumber')}}/>
                            <CustomInput model={RequestUserSettingsModel} attribute='accountNumber' data={responseUserSettings !== null && responseUserSettings.accountNumber !== '' ? responseUserSettings.accountNumber :''} customChange={(e)=>{HandleChange(e,'accountNumber')}}/>
                        </>
                    }
                    {(responseUserSettings !== null && responseUserSettings.clearingNumber === '') || responseUserSettings === null ?
                        <Col lg={12} className='text-end'>
                            <CustomSubmitButton text={t('button.save')} id='submitUserSettings'
                                                customClick={async () => {
                                                    await SubmitSettings();
                                                    setCheckClickButton(true);
                                                }}/>
                        </Col>
                        :
                        ''
                    }
                </Row>
            </Card>
        </Col>
    );

}