import { Button, Card, Col, Row, Spinner} from "reactstrap";
import {CustomButton} from "../../Common/Components/Button/CustomButton";
import {ADMIN_PATHS, ORGANIZATION_PATHS} from "../../config";
import React, {useEffect, useState} from "react";
import {CheckUserPermission} from "../../AppSystem/Helpers/CheckUserPermission";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {CustomSubmitButton} from "../../Common/Components/Input/CustomSubmitButton";
import useApiCallService from "../../Common/Helpers/ApiCall";
import {FlashMessage} from "../../Common/Helpers/FlashMessage";
import {CardCreditInvoiceInfo} from "../Components/CardCreditInvoiceInfo";
import { CustomModal } from "../../Common/Components/Modal/CustomModal";
import $ from "jquery";


export function InvoiceCreditCreate() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { ApiCall } = useApiCallService();

    const [isAdmin, setIsAdmin] = useState(false);

    const [displaySendModal, setDisplaySendModal] = useState(false);
    //RESPONSE
    const [responseCreateInvoice, setResponseCreateInvoice] = useState(null);
    /*const [getClient, setGetClient] = useState(null);*/

    // SPINNER
    const [displaySpinner, setDisplaySpinner] = useState(false);

    let permissionCreateInvoice = false;
    if (CheckUserPermission("invoice.create")) {
        permissionCreateInvoice = true;
    }

    // GET ID IF EXISTS
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id') ?? "";

    // GET INVOICE INFO
    useEffect(() => {
        if (id !== "") {
            setIsAdmin(window.location.href.includes('admin'));
            const handleInfoCreditResponse = (response) => {
                if (response !== null) {
                    setResponseCreateInvoice(response);
                }
            }
            const fetchData = async () => {
                try {
                    const apiCallOptions = {
                        method: "get",
                        url: window.location.href.includes('admin') === true ? `/invoice/admin?id=${id}` : `/invoice?id=${id}`
                    };
                    await ApiCall(apiCallOptions, handleInfoCreditResponse);
                } catch (error) {
                    console.log('error', error);
                }
            };
            fetchData();
        }
    }, []);
    // CLIENT ID
    /*useEffect(() => {
        if (responseCreateInvoice?.clientId) {
            const getDataClient = async () => {
                try {
                   // if (requestCreateNewClient && idClient !== null) {
                        const apiCallOptions = {
                            method: "get",
                            url: `/client?id=${responseCreateInvoice.clientId}`,
                        };
                        try {
                            await ApiCall(apiCallOptions, setGetClient);
                        } catch (error) {
                            console.log(error);
                            FlashMessage(t('validationMessage.sendError'), 'flash-messageError');
                        }
                  //  }
                } catch (err) {
                    console.log(err)
                }
            }
            getDataClient();
        }

    }, [responseCreateInvoice]);*/

    const[responseCredit, setResponseCredit] = useState(null)

    // SEND CREDIT
    const SendCredit = async () => {
        if (responseCreateInvoice?.id) {
            const apiCallOptions = {
                method: "post",
                url: isAdmin ? "/invoice/admin/credit" : "/invoice/credit",
                data: {
                    id:responseCreateInvoice?.id
                },
            };
            try {
                await ApiCall(apiCallOptions, setResponseCredit);
            } catch (error) {
                console.log(error);
                FlashMessage(t('validationMessage.sendError'), 'flash-messageError');
            }
        }

    }

    useEffect(() => {
        if (responseCredit !== null) {
            FlashMessage(t('validationMessage.creditInvoice'), 'flash-messageSuccess');
            setTimeout(()=>{
                if (isAdmin) {
                    navigate(ADMIN_PATHS.ADMIN_INVOICE_LIST);
                } else {
                    navigate(ORGANIZATION_PATHS.INVOICE_LIST);
                }
            },2000);
        }

    }, [responseCredit]);
    const modalContentSend = (
        <div>
            {t('validationMessage.sendCreditInvoice')}
        </div>
    );
    const modalFooterSend = (
        <>
            <Button className="box-green border-0"  onClick={async () => {
               SendCredit();
               setDisplaySpinner(true);
               setDisplaySendModal(false)
            }}>
                {t('button.send')}
            </Button>
            <Button className="box-red border-0" onClick={() => {
                setDisplaySendModal(false);
                $("#sendAttest").removeClass("disabled");
            }}>{t('button.cancel')}</Button>
        </>
    );

    return (
        <>
            {permissionCreateInvoice === true ? '' :
                <Col lg={12} className="px-0">
                    <CustomButton
                        text={t('button.back')}
                        icon={'bx bx-chevron-left'}
                        buttonSize={'small'}
                        customClasses={'mb-3 ms-3 backButton'}
                        onClick={() => {
                            if (isAdmin) {
                                navigate(ADMIN_PATHS.ADMIN_INVOICE_LIST)
                            } else {
                                navigate(ORGANIZATION_PATHS.INVOICE_LIST)
                            }
                        }}
                    />
                </Col>
            }
            {
                responseCreateInvoice !== null ?
                    <Col lg={12} className="pb-5">
                        <CustomModal isOpen={displaySendModal} toggle={() => setDisplaySendModal(!displaySendModal)}
                            body={modalContentSend} footer={modalFooterSend}/>
                        <Card className="card-backend p-4">
                            <Row>
                                <Col xs='auto'>
                                    <h2 className='rokkitt-font'>{t('invoice.createCreditInvoice')}</h2>
                                </Col>
                                {/*<InfoPageMessage message={'Test: message create Invoice'}/>*/}
                            </Row>

                            <Row>
                                <Col lg={12}>
                                    <CardCreditInvoiceInfo dataInvoice={responseCreateInvoice}/>
                                        <Row>
                                            <Col lg={7} className="text-center">
                                                {displaySpinner === true ?
                                                    <>
                                                        {t('validationMessage.sendingInvoice')} <Spinner color='success'/>
                                                    </>
                                                    : ''}
                                            </Col>
                                            <Col lg={5} className="pt-4 text-end">
                                                <CustomSubmitButton text={t('button.sendToAttest')} id="sendAttest" customClick={()=>{
                                                    setDisplaySendModal(true);
                                                    $("#sendAttest").addClass("disabled");
                                                }}/>
                                            </Col>
                                        </Row>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    : ''
            }

        </>

    )

}