import * as yup from "yup";
import {string} from "yup";
import i18n from "i18next";
import {ChangeDateFormat} from "../../../Common/Helpers/ChangeDateFormat";

class RequestReimbursementFixed {
    constructor({
                    id = "",
                    orgId = "",
                    date = '',
                    toDate = '',
                    purpose = '',
                    costBearerId = '',
                    salaryDate = '',
                    type = '',
                    hours = 0,
                    amount = 0,
                    status = "",
                } = {}) {
        this.id = id;
        this.orgId = orgId;
        this.date = ChangeDateFormat('date', date);
        this.toDate = ChangeDateFormat('date', toDate);
        this.purpose = purpose;
        this.costBearerId = costBearerId;
        this.salaryDate = ChangeDateFormat('date', salaryDate);
        this.type = type;
        this.hours = hours;
        this.amount = amount;
        this.status = status;
    }

    static getAttributes(filter = null, validationSchema = null, optionsData = null) {
        const date = i18n.t('modelLabel.date');
        const purpose = i18n.t('reimbursement.purpose');
       // const costBearerId = i18n.t('reimbursement.costBearer');
        const type = i18n.t('modelLabel.type');
       // const hoursLabel = i18n.t('modelLabel.hours');
       // const hoursPlaceholder = i18n.t('formPlaceholder.enterHours');
        //const amountLabel = i18n.t('modelLabel.amount');
        //const amountPlaceholder = i18n.t('formPlaceholder.enterAmount');
       // const validOption = i18n.t('validationMessage.validOption');
       // const greaterO = i18n.t('validationMessage.greater0');
        //const between05and24 = i18n.t('validationMessage.between05and24');
        //const status = i18n.t('modelLabel.status');
        //const orgId = i18n.t('modelLabel.org');

        let optionsCostBearer = [];
        if (optionsData && optionsData.costBearer) {
            optionsData.costBearer.map(item => {
                optionsCostBearer.push({
                    label: item.name,
                    value: item.id
                });
            })
        }

        const completeSchema = yup.object().shape({
            id: string().nullable(),
            orgId: string().nullable(),
            date: string().nullable(),
            toDate: string().nullable(),
            purpose: string().nullable(),
            costBearerId: string().nullable(),
            salaryDate: string().nullable(),
            type: string().nullable(),
            hours:string().nullable().test('is-positive', `${i18n.t('validationMessage.between05and24')}`, value => {
                if (value === null || value === undefined) return true;
                if (value.includes(',') || value.includes('.')) return false;
                const numericValue = parseFloat(value.replace(',', '.').trim());
                return !isNaN(numericValue) && numericValue >= 0 && numericValue <= 24;
            }),
            amount: string().nullable().test('is-positive', `${i18n.t('validationMessage.greater0')}`, value => {
                const numValue = parseFloat(value.replace(',', '.'));
                return !isNaN(numValue) && numValue >= 0;
            }),
            status: string().nullable(),
        });

        const attributes = {
            id :{
                'label': "Id",
                'form': {
                    'type': 'text',
                    'validation': completeSchema.fields.id,
                },
                'value': (item) => item.id || "",
            },
            orgId :{
                'label': i18n.t('modelLabel.org'),
                'form': {
                    'type': 'text',
                    'validation': completeSchema.fields.orgId,
                },
                'value': (item) => item.orgId || "",
            },
            date :{
                'label': date,
                'form': {
                    'type': 'date',
                    'placeholder': date,
                    'validation': completeSchema.fields.date,
                    "maxDate": new Date(),
                },
                'value': (item) => item.date ? ChangeDateFormat('date', item.date) : "",
            },
            toDate :{
                'label': date,
                'form': {
                    'type': 'date',
                    'placeholder': date,
                    'validation': completeSchema.fields.toDate,
                    "maxDate": new Date(),
                },
                'value': (item) => item.toDate ? ChangeDateFormat('date', item.toDate) : "",
            },
            purpose :{
                'label': purpose,
                'form': {
                    'type': 'text',
                    'placeholder': purpose,
                    'validation': completeSchema.fields.purpose,
                },
                'value': (item) => item.purpose || "",
            },
            costBearerId :{
                'label': i18n.t('reimbursement.costBearer'),
                'form': {
                    'type': 'select',
                    'options': optionsCostBearer,
                    'default':'',
                    'validation': completeSchema.fields.costBearerId,
                },
                'value': (item) => item.costBearerId || "",
            },
            salaryDate :{
                'label': date,
                'form': {
                    'type': 'date',
                    'placeholder': i18n.t('modelLabel.dateReimbursement'),
                    'validation': completeSchema.fields.salaryDate,
                },
                'value': (item) => item.salaryDate ? ChangeDateFormat('date', item.salaryDate) : "",
            },
            type :{
                'label': type,
                'form': {
                    'type': 'text',
                    'placeholder': type,
                    'validation': completeSchema.fields.type,
                },
                'value': (item) => item.type || "",
            },
            hours :{
                //'label': i18n.t('modelLabel.hours'),
                'form': {
                    'type': 'number',
                    'placeholder': i18n.t('formPlaceholder.enterHours'),
                    'validation': completeSchema.fields.hours,
                    "oneDecimal": true
                },
                'value': (item) => item.hours || "",
            },
            amount :{
                'label': i18n.t('modelLabel.amount'),
                "id": "fixedAmount",
                'form': {
                    'type': 'number',
                    'placeholder': i18n.t('formPlaceholder.enterAmount'),
                    'validation': completeSchema.fields.amount,
                },
                'value': (item) => item.amount || "",
            },
            status :{
                'label': i18n.t('modelLabel.status'),
                'form': {
                    'type': 'text',
                    'validation': completeSchema.fields.status,
                },
                'value': (item) => item.status || "",
            },
        }

        if (validationSchema === true) {
            return completeSchema;
        }

        return attributes;
    }
}

export default RequestReimbursementFixed;