import i18n from "i18next";
import { ChangeDateFormat } from "../../../Common/Helpers/ChangeDateFormat";

class ResponseUserModel {
    constructor({
                    id = '',
                    visualId = '',
                    firstName = '',
                    lastName = '',
                    email = '',
                    phone = '',
                    pnr = '',
                    country = '',
                    roles = [],
                    status = '',
                    loggedOnLastTime = '',
                    createdAt = '',
                } = {}) {
        this.id = id;
        this.visualId = visualId;
        this.firstName = firstName;
        this.lastName = lastName;
        this.email = email;
        this.phone = phone;
        this.pnr = pnr;
        this.country = country;
        this.roles = roles;
        this.status = status;
        this.loggedOnLastTime = loggedOnLastTime;
        this.createdAt = createdAt;
    }

    static getAttributes(filter = null, validationSchema = null) {

        const attributes = {
            id :{
                'label': 'ID',
                'sortable': true,
                'sortField': 'id',
                'value': (item) => item.id || "",
            },
            visualId :{
                'label': i18n.t('modelLabel.id'),
                'sortable': true,
                'sortField': 'visualId',
                'value': (item) => item.visualId || "",
            },
            firstName :{
                'label': i18n.t('modelLabel.firstName'),
                'sortable': true,
                'sortField': 'firstName',
                'value': (item) => item.firstName || "",
            },
            lastName :{
                'label': i18n.t('modelLabel.lastName'),
                'sortable': true,
                'sortField': 'lastName',
                'value': (item) => item.lastName || "",
            },
            email :{
                'label': i18n.t('modelLabel.email'),
                'sortable': true,
                'sortField': 'email',
                'value': (item) => item.email || "",
            },
            phone :{
                'label': i18n.t('modelLabel.phone'),
                'sortable': true,
                'sortField': 'phone',
                'value': (item) => item.phone || "",
            },
            pnr :{
                'label': i18n.t('modelLabel.pnr'),
                'sortable': true,
                'sortField': 'pnr',
                'value': (item) => item.pnr || "",
            },
            country :{
                'label': 'Country',
                'sortable': true,
                'sortField': 'country',
                'value': (item) => item.country || "",
            },
            roles :{
                'label': 'Roles',
                'sortable': true,
                'sortField': 'roles',
                'value': (item) => item.roles || "",
            },
            status :{
                'label': i18n.t('modelLabel.status'),
                'sortable': true,
                'sortField': 'status',
                'insideClass' : (item)=> item.status && item.status === 'Raderad' ? 'box-custom-grey me-4 fixed-width' : "box-green me-4 fixed-width",
                'value': (item) => item.status && item.status === 'Raderad' ? i18n.t('modelLabel.deleted') : i18n.t('modelLabel.active'),
            },
            loggedOnLastTime :{
                'label': i18n.t('adminLabel.userLoggedOnLastTime'),
                'sortable': true,
                'sortField': 'loggedOnLastTime',
                'value': (item) => item.loggedOnLastTime ? ChangeDateFormat('dateTime', item.loggedOnLastTime) : ''|| "",
            },
            createdAt :{
                'label': i18n.t('modelLabel.createdAt'),
                'sortable': true,
                'sortField': 'createdAt',
                'value': (item) => item.createdAt || "",
            },
        }

        return attributes;
    }
}

export default ResponseUserModel;