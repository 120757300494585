import {Button, Card, Col, Row, Spinner} from 'reactstrap';
import LogoLRF from "../../AppSystem/assets/img/LRF_Logga.png"
import Check from "../../AppSystem/assets/img/swedbank/check.png"
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import useApiCallService from '../../Common/Helpers/ApiCall';
import {ADMIN_PATHS, API_URL, APP_URL, PUBLIC_PATHS} from '../../config';
import { CustomButton } from '../../Common/Components/Button/CustomButton';
import { useNavigate } from 'react-router-dom';

const CheckoutOpenPaymentCompleted = () => {
    const { t } = useTranslation();
    const {ApiCall} = useApiCallService();
    const [errorMessageResult, setErrorMessageResult] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isPaySuccess, setIsPaySuccess] = useState(false);
    const [preview, setPreview] = useState([]);
    const navigate = useNavigate();

    const urlParams = new URLSearchParams(window.location.search);
    const consentCode = urlParams.get('code') ?? "";
    const consentScope = urlParams.get('scope') ?? "";

    const [responseActive, setResponseActive] = useState("");

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const code = searchParams.get('code');
        const state = searchParams.get('state');

        const handleApiSCAResponse = (apiResponse) => {
            if (apiResponse) {

                const handleUpdateStatusApiResponse = (apiResponse, basketId) => {
                    if (apiResponse) {

                        if (apiResponse.scaStatus !== '' && apiResponse.scaStatus === 'finalised'){              
                            handleApiResponse(apiResponse,basketId);
                        } else {
                            if (apiResponse.scaStatus !== '' && apiResponse.scaStatus === 'failed'){
                                setIsLoading(false);
                                setErrorMessageResult(`SCA-processen stötte på ett problem. Status:  ${apiResponse.scaStatus} - SCA-rutinen misslyckades`);
                            } else {
                                setIsLoading(false);
                                let description = '';

                                switch (apiResponse.scaStatus) {
                                    case 'received':
                                        description = 'En resurs för auktorisation eller annullering av auktorisation har skapats framgångsrikt.';
                                        break;
                                    case 'psuIdentified':
                                        description = 'PSU har identifierats i samband med auktorisationsresursen.';
                                        break;
                                    case 'psuAuthenticated':
                                        description = 'PSU har identifierats och autentiserats, till exempel med ett lösenord eller en access token.';
                                        break;
                                    case 'scaMethodSelected':
                                        description = 'PSU/TPP har valt den relaterade SCA-metoden.';
                                        break;
                                    case 'started':
                                        description = 'Den aktuella SCA-rutinen har startats.';
                                        break;
                                    case 'exempted':
                                        description = 'SCA har undantagits för den relaterade transaktionen och auktorisationen har lyckats.';
                                        break;
                                    case 'authenticationStarted':
                                        description = 'Autentiseringen av PSU har påbörjats vid ASPSP.';
                                        break;
                                    case 'authoriseCreditorAccountStarted':
                                        description = 'Godkännande av nytt borgenärskonto för betalning har påbörjats vid ASPSP.';
                                        break;
                                    default:
                                        description = 'Okänt statusvärde mottaget.';
                                        break;
                                }
                                
                                setErrorMessageResult(`SCA-processen stötte på ett problem. Status: ${apiResponse.scaStatus} - ${description}`);
                            }

                        }
                    }
                };
                const SeeStatus = async () => {
                    try {
                        const basketId = apiResponse.basketId;
                        const authorizationid = apiResponse.authorisationId
                        const apiCallOptions = {
                            "method": "Get",
                            "url" : `/open-payment/basket-authorisation-status?basketId=${basketId}&paymentAuthorisationId=${authorizationid}`,
                        };
                        await ApiCall(apiCallOptions, (response) => handleUpdateStatusApiResponse(response, apiResponse.basketId));

                    } catch (error) {
                        console.log('error', error);
                    }
                };
                SeeStatus();
            } else {
                setErrorMessageResult('Ett fel har uppstått, försök igen senare eller kontakta LRF');
            }
        };

        const handleApiResponse = (apiResponse, basketIda) => {
            if (apiResponse && basketIda) {

                const handleUpdateStatusApiResponse = (apiResponse) => {
                    if (apiResponse) {
                        if (apiResponse.transactionStatus !== '' && apiResponse.transactionStatus === 'ACSP' || apiResponse.transactionStatus === 'ACTC' || apiResponse.transactionStatus === 'ACWC' || apiResponse.transactionStatus === 'RCVD') {
                            setIsPaySuccess(true);
                            setPreview(apiResponse.previews);

                        } else {
                            if (apiResponse.transactionStatus !== '' && apiResponse.transactionStatus === 'RJCT') {
                                setIsLoading(false);
                                setErrorMessageResult('Det uppstod ett problem med din betalning och den har avvisats. Vänligen försök igen senare.');
                            } else {
                                setIsLoading(false);
                                setErrorMessageResult('Ett fel har uppstått, försök igen senare eller kontakta LRF.');
                            }

                        }
                    }
                };
                const updateStatus = async () => {
                    try {
                        const basketId = basketIda;
                        const apiCallOptions = {
                            "method": "Get",
                            "url": `/open-payment/basket-status?basketId=${basketId}`,
                        };
                        await ApiCall(apiCallOptions, handleUpdateStatusApiResponse);

                    } catch (error) {
                        console.log('error', error);
                    }
                };
                updateStatus();
            } else {
                setErrorMessageResult('Ett fel har uppstått, försök igen senare eller kontakta LRF');
            }
        };

        const fetchData = async (code, state) => {
            try {
                const newRequest = {
                    paymentInitiationCode: code,
                    redirectUri: (process.env.NODE_ENV === "development" ? 'https://localhost:3000' : APP_URL) + '/checkout-openpayment-completed/',
                    state: state,
                };
                const apiCallOptions = {
                    "method": "Post",
                    "url": "/open-payment/activate-oauth",
                    "data": newRequest
                };

                await ApiCall(apiCallOptions, handleApiSCAResponse);

            } catch (error) {
                console.log('error', error);
            }
        };

        fetchData(code, state);
    }, []);

    useEffect(() => {
        const ActiveConsent = async () => {
            if (consentCode !== "" && consentScope !== "" && consentScope !== "paymentinitiation private" && consentScope !== "paymentinitiation corporate") {
                const apiCallOptions = {
                    method: "post",
                    url: '/open-payment/admin/consent',
                    data: {
                        "code": consentCode
                    }
                };
                await ApiCall(apiCallOptions, setResponseActive);
            } else {
                //
            }
        };
        ActiveConsent();
    }, [consentCode]);

    if (consentCode !== "" && consentScope !== "" && consentScope !== "paymentinitiation private" && consentScope !== "paymentinitiation corporate") {
        return (
            <>
                <Row className='d-flex justify-content-center'>
                    <Col lg={9}>
                        <Card className="p-3">
                            <Row className='d-flex justify-content-center px-4'>
                                <Col lg={6} className='d-flex justify-content-center pt-5'>
                                    <img src={LogoLRF} alt="Logo LRF"/>
                                </Col>
                                <Col lg={12} className='text-center p-5'>
                                    {responseActive !== "" ? (
                                        <>
                                            <h4>{t('validationMessage.consentIsValidated')}</h4>
                                        </>
                                    ) : (
                                        <>
                                            <h4>{t('validationMessage.consentIsValidating')}</h4>
                                            <Spinner color='success' className="ms-2 me-3"/>
                                        </>
                                    )}
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </>
        );
    } else {
        return (
            <Row className='d-flex justify-content-center'>
                <Col lg={9}>
                    <Card className="p-3">
                        <Row className='d-flex justify-content-center px-4'>
                            <Col lg={6} className='d-flex justify-content-center pt-5'>
                                <img src={LogoLRF} alt="Logo LRF"/>
                            </Col>
                            <Col lg={12} className='d-flex justify-content-center pt-5'>
                                {isPaySuccess === true && errorMessageResult === null ? (
                                    <>
                                        {preview && (
                                            <Row className='mt-3'>
                                                <h2 className='text-center'>Resultat</h2>
                                                <div className="table-container">
                                                    <table className="my-5">
                                                        <thead>
                                                            <tr className="border-bottom">
                                                                <th>Id</th>
                                                                <th>Mottagare</th>
                                                                <th>Bankkonto</th>
                                                                <th>Summa</th>
                                                                <th>Status</th>
                                                                <th className="status-description">Statusbeskrivning</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {preview.map((data, index) => {
                                                                const rowClass = (() => {
                                                                    switch (data.status) {
                                                                        case 'ACCC':
                                                                        case 'ACCP':
                                                                        case 'ACSC':
                                                                            return 'payment-color-green';
                                                                        case 'RJCT':
                                                                        case 'CANC':
                                                                            return 'payment-color-red';
                                                                        default:
                                                                            return 'payment-color-orange';
                                                                    }
                                                                })();

                                                                return (
                                                                    <tr key={index} className={`border-bottom ${rowClass}`}>
                                                                        <td>{data.id}</td>
                                                                        <td>{data.model === 'Skatteverketdata' ?  data.model : data.creditorName}</td>
                                                                        <td>{data.bankAccount}</td>
                                                                        <td>{data.amount} kr</td>
                                                                        <td>{data.status}</td>
                                                                        <td>
                                                                            {data.status === 'ACCC' ? '(AcceptedSettlementCompleted) - Avräkningen på fordringsägarens konto har slutförts.' :
                                                                            data.status === 'ACCP' ? '(AcceptedCustomerProfile) - Föregående kontroll av teknisk validering var framgångsrik. Kontrollen av kundprofilen var också framgångsrik.' :
                                                                            data.status === 'ACSC' ? '(AcceptedSettlementCompleted) - Avräkning på gäldenärens konto har slutförts.' :
                                                                            data.status === 'ACSP' ? '(AcceptedSettlementInProcess) - Alla föregående kontroller såsom teknisk validering och kundprofil var framgångsrika och därför har betalningsinitieringen accepterats för genomförande.' :
                                                                            data.status === 'ACTC' ? '(AcceptedTechnicalValidation) - Autentisering samt syntaktisk och semantisk validering har lyckats.' :
                                                                            data.status === 'ACWC' ? '(AcceptedWithChange) - Instruktionen accepteras men en ändring kommer att göras, t.ex. datum eller remiss skickas inte.' :
                                                                            data.status === 'ACWP' ? '(AcceptedWithoutPosting) - Betalningsinstruktionen som ingår i kreditöverföringen accepteras utan att bokföras på kreditgivarkundens konto.' :
                                                                            data.status === 'RCVD' ? '(Received) - Betalningsinitiering har mottagits av det mottagande ombudet.' :
                                                                            data.status === 'PDNG' ? '(Pending) - Betalningsinitiering eller enskild transaktion som ingår i betalningsinitieringen är pågående. Ytterligare kontroller och statusuppdatering kommer att utföras.' :
                                                                            data.status === 'RJCT' ? '(Rejected) - Betalningsinitieringen eller en enskild transaktion som ingår i betalningsinitieringen har avvisats.' :
                                                                            data.status === 'CANC' ? '(Cancelled) - Betalningsinitieringen har annullerats före genomförandet Anmärkning: Denna kod accepteras som ny kod av ISO20022.' :
                                                                            data.status === 'ACFC' ? '(AcceptedFundsChecked) - Föregående kontroll av teknisk validering och kundprofil var framgångsrik och en automatisk kontroll av medel var positiv. Anmärkning: Denna kod accepteras som ny kod av ISO20022.' :
                                                                            data.status === 'PATC' ? '(PartiallyAcceptedTechnical) - Correct Betalningsinitieringen kräver flera autentiseringar, där vissa men ännu inte alla har utförts. Syntaktiska och semantiska valideringar är framgångsrika. Anmärkning: Denna kod accepteras som ny kod av ISO20022.' :
                                                                            data.status === 'PART' ? '(PartiallyAccepted) - Ett antal transaktioner har godkänts, medan ett annat antal transaktioner ännu inte har uppnått statusen ”godkänd”. Anmärkning: Denna kod får endast användas vid massbetalningar. Den används endast i en situation där alla föreskrivna behörigheter har tillämpats, men vissa betalningar har avvisats.' :
                                                                            'Texto por defecto'}
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>

                                                <Col lg={12} className="px-0 d-flex justify-content-center">
                                                    <CustomButton
                                                        text={t('button.close')}
                                                        buttonSize={'small'}
                                                        customClasses={'mb-3 ms-3 backButton'}
                                                        onClick={() => {
                                                            window.close();
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <Row>
                                            <Col className='col-12 d-flex justify-content-center'>
                                                <h2>{errorMessageResult}</h2>
                                            </Col>
                                            {errorMessageResult !== null && (
                                                <Col className='col-12 d-flex justify-content-center'>
                                                    <CustomButton
                                                        text={t('button.close')}
                                                        buttonSize={'small'}
                                                        customClasses={'mb-3 ms-3 backButton'}
                                                        onClick={() => {
                                                            window.close();
                                                        }}
                                                    />
                                                </Col>
                                            )}
                                        </Row>
                                    </>
                                )}
                            </Col>
                            {/* {isPaySuccess === true && errorMessageResult === null && (
                                <Col lg={6} className='d-flex justify-content-center pt-5'>
                                    <img src={Check} alt="Check" height="80" width="80"/>
                                </Col>
                            )} */}
                        </Row>
                    </Card>
                    {errorMessageResult === null && isLoading && !isPaySuccess && (
                        <Col lg={12} className='d-flex flex-column align-items-center pt-5'>
                            <Spinner animation="border" color="success" style={{width: '7rem', height: '7rem'}}/>
                            <p className='mt-3'>{'Vänligen vänta ett ögonblick ...'}</p>
                        </Col>
                    )}
                </Col>
            </Row>
        );
    }
};

export default CheckoutOpenPaymentCompleted;


