import * as yup from "yup";
import {string} from "yup";
import i18n from 'i18next';

class RequestPassenger{
    constructor({
                    quantity = 0,
                    km = 0,
                    amount = 0,
                } = {}) {
        this.quantity = quantity;
        this.km = km;
        this.amount = amount;
    }

    static getAttributes(filter = null, validationSchema = null) {
        const greaterO = i18n.t('validationMessage.greater0');

        const completeSchema = yup.object().shape({
            quantity: string().nullable().test('is-positive', `${greaterO}`, value => {
                const numValue = parseFloat(value.replace(',', '.'));
                return !isNaN(numValue) && numValue > 0;
            }),
            km: string().nullable().test('is-positive', `${greaterO}`, value => {
                const numValue = parseFloat(value.replace(',', '.'));
                return !isNaN(numValue) && numValue > 0;
            }),
            amount: string().nullable().test('is-positive', `${greaterO}`, value => {
                const numValue = parseFloat(value.replace(',', '.'));
                return !isNaN(numValue) && numValue > 0;
            }),
        });

        const attributes = {
            quantity :{
                'label': i18n.t('reimbursement.numberPassengers'),
                'id': 'number',
                'form': {
                    'type': 'number',
                    'placeholder': i18n.t('formPlaceholder.enterQuantity'),
                    'validation': completeSchema.fields.quantity,
                },
                'value': (item) => item.quantity || "",
            },
            km :{
                'label': i18n.t('modelLabel.totalDistance'),
                'id': 'distance',
                'form': {
                    'type': 'number',
                    'placeholder': i18n.t('formPlaceholder.enterKm'),
                    'validation': completeSchema.fields.km,
                },
                'value': (item) => item.km || "",
            },
            amount :{
                'label': i18n.t('modelLabel.amount'),
                'form': {
                    'type': 'number',
                    'placeholder': i18n.t('formPlaceholder.enterAmount'),
                    'validation': completeSchema.fields.amount,
                },
                'value': (item) => item.amount || "",
            },
        }

        if (validationSchema === true) {
            return completeSchema;
        }

        return attributes;
    }
}
export default RequestPassenger;