import {Button, Card, CardBody, Col, Input, Label, Row, Spinner} from "reactstrap";
import {CustomButton} from "../../Common/Components/Button/CustomButton";
import {ADMIN_PATHS} from "../../config";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import useApiCallService from "../../Common/Helpers/ApiCall";
import {useTranslation} from "react-i18next";
import {CheckUserRole} from "../../AppSystem/Helpers/CheckUserRole";
import {CheckUserPermission} from "../../AppSystem/Helpers/CheckUserPermission";
import $ from "jquery";
import {CustomSubmitButton} from "../../Common/Components/Input/CustomSubmitButton";
import {UserProfileHeader} from "../../Common/Components/User/UserProfileHeader";
import {UserSystemRoles} from "../../Common/Components/User/UserSystemRoles";
import {UserOrgRoles} from "../../Common/Components/User/UserOrgRoles";
import {CustomReload} from "../../AppSystem/Helpers/CustomReload";
import {CustomModal} from "../../Common/Components/Modal/CustomModal";

export function AdminUserInfo() {
    const isSuperAdmin = CheckUserRole("SuperAdmin");
    const { ApiCall } = useApiCallService();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const idValue = new URLSearchParams(window.location.search).get('id');

    const [apiResponseRoleList, setApiResponseRoleList] = useState(null);
    const [apiResponseUser, setApiResponseUser] = useState(null);
    const [apiResponseRoleOrgList, setApiResponseRoleOrgList] = useState(null);
    const [apiResponseUserCostPlaceList, setApiResponseUserCostPlaceList] = useState(null);

    // ASSIGN ROLE
    const [assignRoleList, setAssignRoleList] = useState([]);
    const [requestAssignRole, setRequestAssignRole] = useState(null);
    const [responseAssignRole, setResponseAssignRole] = useState(null);

    // ASSIGN ORG
    const [userOrgList, setUserOrgList] = useState([]);
    const [orgLeabList, setOrgLeabList] = useState(null);
    const [requestAssignOrg, setRequestAssignOrg] = useState(null);
    const [responseAssignOrg, setResponseAssignOrg] = useState(null);

    // ASSIGN COST PLACE
    const [assignCostPlaceList, setAssignCostPlaceList] = useState(null);
    const [responseCostPlaceList, setResponseCostPlaceList] = useState(null);
    const [responseCostPlace, setResponseCostPlace] = useState(null);

    // DELETE USER
    const [requestDelete, setRequestDelete] = useState(null);
    const [responseDelete, setResponseDelete] = useState(null);
    const [displayDeleteModal, setDisplayDeleteModal] = useState(false);

    // ACTIVE USER
    const [requestActive, setRequestActive] = useState(null);
    const [responseActive, setResponseActive] = useState(null);
    const [displayActiveModal, setDisplayActiveModal] = useState(false);

    let permission = false;
    if (CheckUserPermission("admin") || CheckUserPermission("admin.role") || CheckUserPermission("admin.role.assign")) {
        permission = true;
    }

    let permissionOrg = false;
    if (CheckUserPermission("admin") || CheckUserPermission("admin.user") || CheckUserPermission("admin.user.assign")) {
        permissionOrg = true;
    }

    let permissionDelete = false;
    if (CheckUserPermission("admin") || CheckUserPermission("admin.user") || CheckUserPermission("admin.user.delete")) {
        permissionDelete = true;
    }
    let permissionActive = false;
    if (CheckUserPermission("admin") || CheckUserPermission("admin.user") || CheckUserPermission("admin.user.active")) {
        permissionActive = true;
    }

    useEffect(() => {
        const handleApiResponseUserInfo  = (apiResponseUser) => {
            setApiResponseUser(apiResponseUser);
        };
        const GetInfoUser = async () => {
            if (idValue !== null) {
                try {
                    const apiCallOptions = {
                        method: "get",
                        url: `/user/admin?id=${idValue}`,
                    };
                    await ApiCall(apiCallOptions, handleApiResponseUserInfo );
                } catch (error) {
                    console.log('error', error);
                }
            }
        };
        GetInfoUser();
    }, [idValue]);

    useEffect(() => {
        const handleApiResponse  = (apiResponseRoleList) => {
            setApiResponseRoleList(apiResponseRoleList);
        };
        const GetUserRole = async () => {
            if (idValue !== null) {
                try {
                    const apiCallOptions = {
                        method: "get",
                        url: `/user/admin/role/list?id=${idValue}`,
                    };
                    await ApiCall(apiCallOptions, handleApiResponse );
                } catch (error) {
                    console.log('error', error);
                }
            }
        };
        GetUserRole();
    }, [idValue]);

    // list Organization
    useEffect(() => {
        const handleApiResponseListAll  = (apiResponseRoleOrgList) => {
            setApiResponseRoleOrgList(apiResponseRoleOrgList);
        };
        const GetOrgRole = async () => {
            if (idValue !== null) {
                try {
                    const apiCallOptions = {
                        method: "get",
                        url: `/user/role/org/list/all?id=${idValue}`, // change the call when in the api
                    };
                    await ApiCall(apiCallOptions, handleApiResponseListAll );
                } catch (error) {
                    console.log('error', error);
                }
            }
        };
        GetOrgRole();
    }, [idValue]);

    ////////// ASSIGN ROLE LIST
    useEffect(() => {
        const handleAssignRoleList  = (response) => {
            setAssignRoleList(response);
        };
        const GetAssignRoleList = async () => {
            if (idValue !== null) {
                try {
                    const apiCallOptions = {
                        method: "get",
                        url: "/role/admin/user/list",
                    };
                    await ApiCall(apiCallOptions, handleAssignRoleList);
                } catch (error) {
                    console.log('error', error);
                }
            }
        };
        GetAssignRoleList();
    }, [idValue]);

    // CHECK ROLES
    useEffect(() => {
        if (apiResponseRoleList && apiResponseRoleList.length > 0) {
            setRequestAssignRole((prevFormData) => ({
                ...prevFormData,
                userId: idValue,
                roles: apiResponseRoleList.map((item) => item.role)
            }));
        }
    }, [apiResponseRoleList]);

    // HANDLE CLICK ASSIGN ROLE
    const HandleClickAssignRole = (e) => {
        let roles = requestAssignRole.roles;
        if (e.target.checked) {
            roles.push(e.target.value);
        } else {
            roles = roles.filter((item) => item !== e.target.value);
        }
        setRequestAssignRole((prevFormData) => ({
            ...prevFormData,
            roles: roles
        }));
    }

    // HANDLE SUBMIT ASSIGN ROLE
    const HandleSubmitAssignRole = async () => {
        const handleSendAssignRole = (response) => {
            setResponseAssignRole(response);
        }
        const apiCallOptions = {
            method: "put",
            url: "/role/admin/assign/user",
            data: requestAssignRole
        };
        await ApiCall(apiCallOptions, handleSendAssignRole);
    }

    // RESPONSE ASSIGN ROLE
    useEffect(() => {
        if (responseAssignRole !== null) {
            CustomReload();
        }
    }, [responseAssignRole]);

    ////////// LEAB ORG LIST
    useEffect(() => {
        const handleUserOrgList  = (response) => {
            setUserOrgList(response);
        };
        const GetUserOrgList = async () => {
            if (idValue !== null) {
                try {
                    const apiCallOptions = {
                        method: "get",
                        url: "/user/admin/org-list?id=" + idValue,
                    };
                    await ApiCall(apiCallOptions, handleUserOrgList);
                } catch (error) {
                    console.log('error', error);
                }
            }
        };
        GetUserOrgList();
    }, [idValue]);

    useEffect(() => {
        const handleOrgList  = (response) => {
            setOrgLeabList(response);
        };
        const GetOrgList = async () => {
            if (idValue !== null) {
                try {
                    const apiCallOptions = {
                        method: "get",
                        url: "/org/admin/leab",
                    };
                    await ApiCall(apiCallOptions, handleOrgList);
                } catch (error) {
                    console.log('error', error);
                }
            }
        };
        GetOrgList();
    }, [idValue]);

    // CHECK ORGS
    useEffect(() => {
        const filteredUserOrgs = [];
        if (userOrgList && userOrgList.length > 0 && orgLeabList && orgLeabList.length > 0) {
            // CHECK IF USER HAS SOME LEAB ORG
            userOrgList.filter((userOrg) => {
                orgLeabList.some((org) => {
                    if (org.id === userOrg.id) {
                        filteredUserOrgs.push(org.id);
                    }
                })
            });
        }
        setRequestAssignOrg((prevFormData) => ({
            ...prevFormData,
            userId: idValue,
            orgIds: filteredUserOrgs
        }));
        setRequestDelete((prevFormData) => ({
            ...prevFormData,
            id: idValue
        }));
        setRequestActive((prevFormData) => ({
            ...prevFormData,
            id: idValue
        }));
    }, [userOrgList, orgLeabList]);

    // HANDLE CLICK ASSIGN ORG
    const HandleClickAssignOrg = (e) => {
        let orgIds = requestAssignOrg.orgIds;
        if (e.target.checked) {
            orgIds.push(e.target.value);
        } else {
            orgIds = orgIds.filter((item) => item !== e.target.value);
        }
        setRequestAssignOrg((prevFormData) => ({
            ...prevFormData,
            orgIds: orgIds
        }));
    }

    // HANDLE SUBMIT ASSIGN ORG
    const HandleSubmitAssignOrg = async () => {
        const handleSendAssignOrg = (response) => {
            setResponseAssignOrg(response);
        }
        const apiCallOptions = {
            method: "put",
            url: "/user/admin/assign/org",
            data: requestAssignOrg
        };
        await ApiCall(apiCallOptions, handleSendAssignOrg);
    }

    // RESPONSE ASSIGN ORG
    useEffect(() => {
        if (responseAssignOrg !== null) {
            CustomReload();
        }
    }, [responseAssignOrg]);

    ////////// COST PLACE LIST
    // GET ALL COST PLACES
    useEffect(() => {
        const handleCostPlaceList  = (response) => {
            const groupedCostPlaceList = response.reduce((acc, item) => {
                const [category, id, name] = item.split(" - ");
                if (!acc[category]) acc[category] = [];
                acc[category].push({ id, name });
                return acc;
            }, {});
            setResponseCostPlaceList(groupedCostPlaceList);
        };
        const GetCostPlaceList = async () => {
            try {
                const apiCallOptions = {
                    method: "get",
                    url: "/org/admin/cost-place?userId=" + idValue,
                };
                await ApiCall(apiCallOptions, handleCostPlaceList);
            } catch (error) {
                console.log('error', error);
            }
        };
        GetCostPlaceList();
    }, [idValue]);

    // GET USER COST PLACES
    useEffect(() => {
        const handleUserCostPlaceList  = (response) => {
            setApiResponseUserCostPlaceList(response);
        };
        const GetUserCostPlaceList = async () => {
            if (idValue !== null) {
                try {
                    const apiCallOptions = {
                        method: "get",
                        url: "/usersetting/primary-costplace?id=" + idValue,
                    };
                    await ApiCall(apiCallOptions, handleUserCostPlaceList);
                } catch (error) {
                    console.log('error', error);
                }
            }
        };
        GetUserCostPlaceList();
    }, [idValue]);

    // CHECK COST PLACES
    useEffect(() => {
        if (apiResponseUserCostPlaceList && apiResponseUserCostPlaceList.primaryCostPlaces && apiResponseUserCostPlaceList.primaryCostPlaces.length > 0) {
            setAssignCostPlaceList((prevFormData) => ({
                ...prevFormData,
                id: idValue,
                primaryCostPlace: apiResponseUserCostPlaceList.primaryCostPlaces
            }));
        }
    }, [apiResponseUserCostPlaceList]);

    // HANDLE CLICK ASSIGN COST PLACE
    const HandleClickAssignCostPlace = (e) => {
        let costPlaces = assignCostPlaceList?.primaryCostPlace ?? [];
        if (e.target.checked) {
            costPlaces.push(e.target.value);
        } else {
            costPlaces = costPlaces.filter((item) => item !== e.target.value);
        }
        setAssignCostPlaceList((prevFormData) => ({
            ...prevFormData,
            primaryCostPlace: costPlaces
        }));
    }

    // HANDLE SUBMIT ASSIGN COST PLACE
    const HandleSubmitAssignCostPlace = async () => {
        const handleSendAssignCostPlace = (response) => {
            setResponseCostPlace(response);
        }
        assignCostPlaceList.id = idValue;
        const apiCallOptions = {
            method: "post",
            url: "/usersetting/primary-costplace",
            data: assignCostPlaceList
        };
        await ApiCall(apiCallOptions, handleSendAssignCostPlace);
    }

    // RESPONSE ASSIGN COST PLACE
    useEffect(() => {
        if (responseCostPlace !== null) {
            CustomReload();
        }
    }, [responseCostPlace]);

    ////////// DELETE USER
    // HANDLE SUBMIT DELETE USER
    const HandleSubmitDeleteUser = async () => {
        const handleSendDelete = (response) => {
            setResponseDelete(response);
        }
        const apiCallOptions = {
            method: "delete",
            url: "/user/admin/",
            data: requestDelete
        };
        await ApiCall(apiCallOptions, handleSendDelete);
    }

    // RESPONSE DELETE USER
    useEffect(() => {
        if (responseDelete !== null) {
            CustomReload();
        }
    }, [responseDelete]);

    // MODAL DELETE USER
    const HandleClickTrash = () => {
        setDisplayDeleteModal(true);
    }
    const modalContentDelete = (
        <div>
            {`${t('validationMessage.deleteUser')}`}
        </div>
    );
    const modalFooterDelete = (
        <>
            <Button id="deleteButton" className="box-green border-0" onClick={() => {
                $("#deleteButton").prop('disabled', true);
                HandleSubmitDeleteUser();
            }}>
                {t('button.delete')}
            </Button>
            <Button className="box-red border-0" onClick={() => setDisplayDeleteModal(false)}>{t('button.cancel')}</Button>
        </>
    );

    ////////// ACTIVE USER
    // HANDLE SUBMIT ACTIVE USER
    const HandleSubmitActiveUser = async () => {
        const handleSendActive = (response) => {
            setResponseActive(response);
        }
        const apiCallOptions = {
            method: "post",
            url: "/user/admin/",
            data: requestActive
        };
        await ApiCall(apiCallOptions, handleSendActive);
    }

    // RESPONSE ACTIVE USER
    useEffect(() => {
        if (responseActive !== null) {
            CustomReload();
        }
    }, [responseActive]);

    // MODAL ACTIVE USER
    const HandleClickActive = () => {
        setDisplayActiveModal(true);
    }
    const modalContentActive = (
        <div>
            {`${t('validationMessage.activeUser')}`}
        </div>
    );
    const modalFooterActive = (
        <>
            <Button id="activeButton" className="box-green border-0" onClick={() => {
                $("#activeButton").prop('disabled', true);
                HandleSubmitActiveUser();
            }}>
                {t('button.accept')}
            </Button>
            <Button className="box-red border-0" onClick={() => setDisplayActiveModal(false)}>{t('button.cancel')}</Button>
        </>
    );

    return(
        <>
            <Col lg={1} className="px-0">
                <CustomButton
                    text={t('button.back')}
                    icon={'bx bx-chevron-left'}
                    buttonSize={'small'}
                    customClasses={'ms-2 mb-3 rounded'}
                    onClick={() => {
                        navigate(ADMIN_PATHS.ADMIN_USER)
                    }}
                />
            </Col>
            {apiResponseUser ?
                <>
                    <Col lg={12} className="px-0">
                        <UserProfileHeader data={apiResponseUser} isSuperAdmin={isSuperAdmin} type="Admin" handleClickTrash={HandleClickTrash} handleClickActive={HandleClickActive} />
                        {(apiResponseUser.status === undefined || apiResponseUser.status !== "Raderad") && permissionDelete === true ?
                            <CustomModal isOpen={displayDeleteModal} toggle={() => setDisplayDeleteModal(!displayDeleteModal)}
                                     body={modalContentDelete} footer={modalFooterDelete}/>
                        : ""}
                        {apiResponseUser.status && apiResponseUser.status === "Raderad" && permissionActive === true ?
                            <CustomModal isOpen={displayActiveModal} toggle={() => setDisplayActiveModal(!displayActiveModal)}
                                     body={modalContentActive} footer={modalFooterActive}/>
                        : ""}
                        <Row className='justify-content-center'>
                            <Col lg={12} className='px-4 p-lg-5 padding-top-profile'>
                                <Row>
                                    <Col lg={6}>
                                        {/* SYSTEM ROLES */}
                                        {apiResponseRoleList ?
                                            <UserSystemRoles data={apiResponseRoleList} />
                                        : ''}
                                        {/* ORGANIZATION ROLES */}
                                        {apiResponseRoleOrgList ?
                                            <UserOrgRoles data={apiResponseRoleOrgList} isSuperAdmin={isSuperAdmin} />
                                        : ''}
                                    </Col>
                                    <Col lg={6}>
                                        {/* ASSIGN ROLES */}
                                        {assignRoleList && assignRoleList.length > 0 && permission ?
                                            <Card className="mb-3">
                                                <CardBody>
                                                    <h5 className="mb-3 text-green fw-bold">{t('adminLabel.assignRoles')}</h5>
                                                    <Row>
                                                        {assignRoleList.map((item) => {
                                                            if (requestAssignRole !== null && requestAssignRole.roles && requestAssignRole.roles.length > 0 && requestAssignRole.roles.indexOf(item.role) !== -1) {
                                                                setTimeout(() => {
                                                                    $(`#role${item.id}`).prop('checked', true);
                                                                }, 250);
                                                            }
                                                            return (
                                                                <div className="col-4">
                                                                    <Label>
                                                                        <Input id={`role${item.id}`} type="checkbox" name="role" value={item.role} disabled={item.role === "Förtroendevald"} onChange={(e) => HandleClickAssignRole(e)} />
                                                                        <span className="ms-2">{item.role}</span>
                                                                    </Label>
                                                                    {item.permissions && item.permissions.length > 0 ?
                                                                        <>
                                                                            <i className="ms-2 bx bx-info-circle fs-5 text-green"
                                                                               onMouseOverCapture={() => {
                                                                                   $("#permissionDiv").removeClass("d-none");
                                                                                   $("#permissionSpan").text(item.permissions.join(', '));
                                                                               }}
                                                                               onMouseOutCapture={() => {
                                                                                   $("#permissionDiv").addClass("d-none");
                                                                                   $("#permissionSpan").text("");
                                                                               }}
                                                                            />
                                                                        </>
                                                                    : ''}
                                                                </div>
                                                            );
                                                        })}
                                                    </Row>
                                                    <div id="permissionDiv" className="mt-1 mb-3 bg-orange-light rounded border border-warning text-warning p-2 d-none text-center">
                                                        <h6>{t('role.permissions')}</h6>
                                                        <span id="permissionSpan"></span>
                                                    </div>
                                                    <div className="text-end">
                                                        <span id="spinnerAssign" className="me-3 d-none">
                                                            {t('validationMessage.assigningRoles')} <Spinner color='success'/>
                                                        </span>
                                                        <CustomSubmitButton id="submitAssign" text={t("button.assign")} icon="bx bx-send pe-2"
                                                                            customClick={async () => {
                                                                                $("#submitAssign").prop('disabled', true);
                                                                                $("#spinnerAssign").removeClass("d-none");
                                                                                await HandleSubmitAssignRole();
                                                                            }}/>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                            : ''}
                                        {/* ASSIGN ORG */}
                                        {orgLeabList && orgLeabList.length > 0 && permissionOrg ?
                                            <Card className="mb-3">
                                                <CardBody>
                                                    <h5 className="mb-3 text-green fw-bold">{t('adminLabel.assignOrg')} (LEAB)</h5>
                                                    <Row>
                                                        {orgLeabList.map((item) => {
                                                            if (requestAssignOrg !== null && requestAssignOrg.orgIds && requestAssignOrg.orgIds.length > 0 && requestAssignOrg.orgIds.indexOf(item.id) !== -1) {
                                                                setTimeout(() => {
                                                                    $(`#name${item.id}`).prop('checked', true);
                                                                }, 250);
                                                            }
                                                            return (
                                                                <div className="col-4">
                                                                    <Label>
                                                                        <Input id={`name${item.id}`} type="checkbox" name="name" value={item.id} onChange={(e) => HandleClickAssignOrg(e)} />
                                                                        <span className="ms-2">{item.name}</span>
                                                                    </Label>
                                                                </div>
                                                            );
                                                        })}
                                                    </Row>
                                                    <div className="text-end">
                                                        <span id="spinnerAssignOrg" className="me-3 d-none">
                                                            {t('validationMessage.assigningOrgs')} <Spinner color='success'/>
                                                        </span>
                                                        <CustomSubmitButton id="submitAssignOrg" text={t("button.assign")} icon="bx bx-send pe-2"
                                                                            customClick={async () => {
                                                                                $("#submitAssignOrg").prop('disabled', true);
                                                                                $("#spinnerAssignOrg").removeClass("d-none");
                                                                                await HandleSubmitAssignOrg();
                                                                            }}/>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        : ''}
                                        {/* ASSING PRIMARY COSTPLACE */}
                                        {responseCostPlaceList && permission ?
                                            <Card className="mb-3">
                                                <CardBody>
                                                    <h5 className="mb-3 text-green fw-bold">{t('adminLabel.assignCostPlaces')}</h5>
                                                    {Object.keys(responseCostPlaceList).map((category) => (
                                                        <div key={category} className="mb-3">
                                                            <h6>{category}</h6>
                                                            <div className="row">
                                                                {responseCostPlaceList[category].map(({id, name}) => {
                                                                    const itemId = id.replace(" ", "");
                                                                    if (assignCostPlaceList && assignCostPlaceList.primaryCostPlace && assignCostPlaceList.primaryCostPlace.includes(itemId)) {
                                                                        setTimeout(() => {
                                                                            $(`#costPlace${itemId}`).prop('checked', true);
                                                                        }, 250);
                                                                    }
                                                                    return (
                                                                        <div className="col-6" key={id}>
                                                                            <Label>
                                                                                <Input id={`costPlace${itemId}`} type="checkbox" name="costPlace" value={itemId} onChange={(e) => HandleClickAssignCostPlace(e)} />
                                                                                <span className="ms-2">{`${id} - ${name}`}</span>
                                                                            </Label>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        </div>
                                                    ))}
                                                    <div className="text-end">
                                                        <span id="spinnerAssignCostPlace" className="me-3 d-none">
                                                            {t('validationMessage.assigningCostPlaces')} <Spinner
                                                            color='success'/>
                                                        </span>
                                                        <CustomSubmitButton id="submitAssignCostPlace"
                                                                            text={t("button.assign")}
                                                                            icon="bx bx-send pe-2"
                                                                            customClick={async () => {
                                                                                $("#submitAssignCostPlace").prop('disabled', true);
                                                                                $("#spinnerAssignCostPlace").removeClass("d-none");
                                                                                await HandleSubmitAssignCostPlace();
                                                                            }}/>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                            : ''}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </>
                : ''}
        </>
    );
}