import {useSelector} from "react-redux";

/*
Usage:
CheckUserOrganization()
*/

// CHECK USER ORGANIZATION
export function CheckUserOrganization() {
    const { orgData } = useSelector(state => state.auth);
    let hasOrg = false;
    if (orgData !== "" && orgData !== null && orgData.orgId && orgData.orgId !== "") {
        hasOrg = true;
    }
    return hasOrg;
}