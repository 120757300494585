import ReactDOM from 'react-dom';

/*
You can use this component in the try-catch API responses
Example: try {
  FlashMessage('Login Successfully', 'flash-messageSuccess');
} catch (ex) {
  FlashMessage(ex.response.data.title + '. ' + 'Please try again', 'flash-messageError');
}

FlashMessage(<Component jsonString={errorMessage} />, 'flash-messageError', 10000);
*/

export function FlashMessage(APImessage, type, time = null) {
  let flashMessage = APImessage;
  const messageDiv = document.getElementById('flash-message');
  const overlay = document.getElementById('overlay');

  if (messageDiv && overlay) {
    // NEXT LINE DO THE SAME AS "ReactDOM.render(flashMessage, messageDiv);", DONE THIS FOR CAN PASS A COMPONENT TOO
    // messageDiv.innerHTML = flashMessage;
    ReactDOM.render(flashMessage, messageDiv);
    document.getElementById('flash-message').classList.add(type);

    messageDiv.style.display = 'block';
    /*
    messageDiv.style.position = 'absolute';
    messageDiv.style.left = '50%';
    messageDiv.style.top = '50%';
    messageDiv.style.transform = 'translate(-50%, -50%)'
    */
    messageDiv.style.textAlign = 'center';
    messageDiv.style.margin = '0 auto';

    overlay.style.display = 'block';
    overlay.style.alignContent = 'center';

    setTimeout(() => {
      // NEXT LINE IS IF USE "ReactDOM.render(flashMessage, messageDiv);"
      ReactDOM.unmountComponentAtNode(messageDiv);

      messageDiv.style.display = 'none';
      overlay.style.display = 'none';
      document.getElementById('flash-message').classList.remove(type);
      flashMessage = '';
    }, time ?? 2300);
  }
}
