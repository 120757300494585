import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useApiCallService from "../Common/Helpers/ApiCall";
import { useNavigate } from "react-router-dom";
import { Col } from "reactstrap";
import { CustomButton } from "../Common/Components/Button/CustomButton";
import { ADMIN_PATHS } from "../config";
import ReactMarkdown from 'react-markdown';

export function AdminReportTaxAuthorityInfo() {
    const { t } = useTranslation()
    const markdownValue = decodeURIComponent(new URLSearchParams(window.location.search).get('id'));
    const navigate = useNavigate();
    return(
        <>
            <Col lg={1} className="px-0 mb-4">
                <CustomButton
                    text={t('button.back')}
                    icon={'bx bx-chevron-left'}
                    buttonSize={'small'}
                    customClasses={'m-3 rounded'}
                    onClick={() => {
                        navigate(ADMIN_PATHS.ADMIN_REPORT_TAX)
                    }}
                />
            </Col>
            <Col lg={12} className="px-0 ml-5">
            <div  className='card-backend p-5' >
                <ReactMarkdown>{markdownValue}</ReactMarkdown>
                </div>
            </Col>
        </>);

}