class ResponseReimbursementFixed {
    constructor({
                    id = '',
                    orgId = '',
                    userId = '',
                    date = '',
                    toDate = '',
                    purpose = '',
                    costBearerId = '',
                    costBearerName = '',
                    type = '',
                    hours = 0,
                    amount = 0,
                    status = '',
                } = {}) {
        this.id = id;
        this.orgId = orgId;
        this.userId = userId;
        this.date = date;
        this.toDate = toDate;
        this.purpose = purpose;
        this.costBearerId = costBearerId;
        this.costBearerName = costBearerName;
        this.type = type;
        this.hours = hours;
        this.amount = amount;
        this.status = status;
    }

    static getAttributes(filter = null, validationSchema = null) {
        const attributes = {
            id :{
                'label': 'Id',
                'sortable': true,
                'sortField': "id",
                'value': (item) => item.id || "",
            },
            orgId :{
                'label': 'Org Id',
                'sortable': true,
                'sortField': "orgId",
                'value': (item) => item.orgId || "",
            },
            userId :{
                'label': 'User Id',
                'sortable': true,
                'sortField': "userId",
                'value': (item) => item.userId || "",
            },
            date :{
                'label': 'Date',
                'sortable': true,
                'sortField': "date",
                'value': (item) => item.date || "",
            },
            toDate :{
                'label': 'Date',
                'sortable': true,
                'sortField': "toDate",
                'value': (item) => item.toDate || "",
            },
            purpose :{
                'label': 'Purpose',
                'sortable': true,
                'sortField': "purpose",
                'value': (item) => item.purpose || "",
            },
            costBearerId :{
                'label': 'Cost Bearer',
                'sortable': true,
                'sortField': "costBearerId",
                'value': (item) => item.costBearerId || "",
            },
            costBearerName :{
                'label': 'Cost Bearer Name',
                'sortable': true,
                'sortField': "costBearerName",
                'value': (item) => item.costBearerName || "",
            },
            type :{
                'label': 'type',
                'sortable': true,
                'sortField': "type",
                'value': (item) => item.type || "",
            },
            hours :{
                'label': 'Hours',
                'sortable': true,
                'sortField': "hours",
                'value': (item) => item.hours || "",
            },
            amount :{
                'label': 'Amount',
                'sortable': true,
                'sortField': "amount",
                'value': (item) => item.amount || "",
            },
            status :{
                'label': "Status",
                'sortable': true,
                'sortField': "status",
                'value': (item) => item.status || "",
            },
        }

        return attributes;
    }
}

export default ResponseReimbursementFixed;