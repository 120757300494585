import React from "react";

export function InputMessage(array, selectedValue) {
    if (array) {
        let filterValue = '';
        if (selectedValue !== '' && selectedValue) {
            filterValue = array.find(item => item.id === selectedValue)
        }

        if (filterValue !== '' && filterValue) {
            return <div className="text-green d-flex align-items-center mb-3">
                <i className='bx bx-info-circle fs-5 pe-2'/>{filterValue.message ?? ""}
            </div>
        }
    }
    return "";
}