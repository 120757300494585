import {Button, Card, Col, Input, Label, Row, Spinner} from "reactstrap";
import {API_URL, ORGANIZATION_PATHS} from "../../config";
import {CustomButton} from "../../Common/Components/Button/CustomButton";
import React, {useState,useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import LogoLRF from "../../AppSystem/assets/img/Logo-LRF.png";
import useApiCallService from "../../Common/Helpers/ApiCall";
import ResponseQRCode from "../Models/Response/ResponseQRCode";
import {CustomReload} from "../../AppSystem/Helpers/CustomReload";
import {CustomInput} from "../../Common/Components/Input/CustomInput";
import RequestQRPayment from "../Models/Request/RequestQRPayment";
import {CheckDecimal} from "../../Common/Helpers/CheckDecimal";
import {FlashMessage} from "../../Common/Helpers/FlashMessage";
import {CustomSubmitButton} from "../../Common/Components/Input/CustomSubmitButton";
import {ToCustomDecimal} from "../../AppSystem/Helpers/ToCustomDecimal";
import $ from "jquery"
import {NumberInput} from "../../Common/Components/Input/Fields/NumberInput";

export function QRCodePublicInfo() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { ApiCall } = useApiCallService();
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id') ?? "";
    const [infoQR, setInfoQR] = useState(new ResponseQRCode());

    //REQUEST
    const [requestQRPayment, setRequestQRPayment] = useState(new RequestQRPayment());

    //REQUEST
    const [responseQRPayment, setResponseQRPayment] = useState('');

    //VALIDATION SCHEMAS
    const completeSchemaQRPayment = RequestQRPayment.getAttributes( null, true);

    // SPINNER
    const [displaySpinner, setDisplaySpinner] = useState(false);

    const [selectedButton, setSelectedButton] = useState(null);
    const [error, setError] = useState('');

    // GET QR
   useEffect(() => {
        if (id !== "") {
            const handleApiResponseQR = (apiResponse) => {
                setInfoQR(new ResponseQRCode(apiResponse));
            };
            const fetchData = async () => {
                try {
                    const apiCallOptions = {
                        method: "get",
                        url: `/orgqr/allow?id=${id}`
                    };
                    await ApiCall(apiCallOptions, handleApiResponseQR);
                } catch (error) {
                    console.log('error', error);
                }
            };
            fetchData();

            setRequestQRPayment((prevData) => ({
                ...prevData,
                id: id,

            }));
        }
    }, []);

    //PAYMENT HANDLECHANGE
     const HandleQRPayment = (e, name) => {
         let amount = requestQRPayment.amount;
         let comment = requestQRPayment.comment;

         if (name === "defaultAmounts") {
             //amount = CheckDecimal(e.target.value);
             amount = e.target.value;

         }
         if (name === "amount") {
             if (e && e.target && e.target.value !== "") {
                 setSelectedButton(null);
                 //amount = CheckDecimal(e.target.value);
                 amount = e.target.value;
             } else {
                 amount = 0;
             }
         }
         if (name === "comment") {
             comment = e.target.value;
         }
         setRequestQRPayment((prevData) => ({
             ...prevData,
             amount: amount,
             comment: comment,

         }));
     }

    //test
    useEffect(() => {
        if (infoQR?.defaultAmounts && infoQR?.defaultAmounts.length === 1 && infoQR?.thereIsCustomAmount === false) {
            setSelectedButton(0);
            setRequestQRPayment((prevData) => ({
                ...prevData,
                amount: infoQR?.defaultAmounts[0].toString(),
            }));
        }

    }, [infoQR?.defaultAmounts]);


    //DISABLE BUTTON
    useEffect(() => {
        if (infoQR?.defaultAmounts?.length > 1) {
            setTimeout(()=>{
                $("#chooseMethod").prop("disabled", true);
            },80);
        }else {
            if (infoQR?.thereIsCustomAmount === true) {
                $("#chooseMethod").prop("disabled", true);
            } else {
                $("#chooseMethod").prop("disabled", false);
            }

        }


    }, [infoQR?.defaultAmounts]);



    useEffect(() => {
        if (requestQRPayment.id !== '' && requestQRPayment.amount !== '0' && requestQRPayment.amount !== '' && infoQR.thereIsComment === false ||
            (requestQRPayment.id !== '' && requestQRPayment.amount !== '0' && requestQRPayment.amount !== '' && infoQR.thereIsComment === true && requestQRPayment.comment !== '')){
            $("#chooseMethod").prop("disabled", false);
        } else {
            $("#chooseMethod").prop("disabled", true);
        }
        if (requestQRPayment.amount === '0' || requestQRPayment.amount === '') {
            setError(t('validationMessage.greater0'))
        } else {
            setError('')
        }
        if (requestQRPayment.amount === 0) {
            $("#chooseMethod").prop("disabled", true);
        }
    }, [requestQRPayment, requestQRPayment.amount]);
//GO TO PAYMENT METHOD
    const GoToPaymentMethod = async () => {
        if (requestQRPayment.comment === ''){
            requestQRPayment.comment = null;
        }
        /*if (typeof requestQRPayment.amount === 'string'){
            requestQRPayment.amount = CheckDecimal(requestQRPayment.amount);
        }*/
        if (requestQRPayment.amount !== '0' && requestQRPayment.amount !== '') {
            requestQRPayment.amount = CheckDecimal(requestQRPayment.amount);
            try {
                await completeSchemaQRPayment.validate(requestQRPayment, {abortEarly: false});
                setDisplaySpinner(true);
                const apiCallOptions = {
                    method: "post",
                    url: "/orgqr/payment",
                    data: requestQRPayment,
                };
                try {
                    await ApiCall(apiCallOptions, setResponseQRPayment);
                } catch (error) {
                    console.log(error);
                    FlashMessage(t('validationMessage.sendError'), 'flash-messageError');
                }
            } catch (err) {
                console.log(err)
                setDisplaySpinner(false);
            }
        } else {
            $("#chooseMethod").prop("disabled", false);
        }
    }

    useEffect(() => {
        if (responseQRPayment === null) {
            setDisplaySpinner(false);
            FlashMessage(t('validationMessage.sendError'), 'flash-messageError');
        }
    }, [responseQRPayment]);

    //REDIRECT TO PAYMENT
    useEffect(() => {
        if (responseQRPayment !== '' && responseQRPayment !== null) {
            CustomReload(responseQRPayment);
        }
    }, [responseQRPayment]);



    return (
        <Row className='d-flex justify-content-center pt-5'>
            {infoQR.id !== '' ?
                <Col lg={9} className="pb-5">
                    <Card className="card-backend p-4">
                        <Row>
                            <Col xl={12} className="text-center">
                                <img src={LogoLRF} alt={t('accessibility.logo')} className='img-fluid'/>
                                <h2 className="rokkitt-font pt-4 pb-3">{infoQR.title} </h2>
                                <p className="px-3">{infoQR.description}</p>
                                <Row>
                                    {infoQR?.defaultAmounts.length > 0 ?
                                        <Col lg={12} className="text-start mb-3">
                                            <Label>{t('QRCode.defaultAmountsToContribute')}:</Label>
                                            <div>
                                                {infoQR?.defaultAmounts.map((value, index)=>(
                                                    <Button key={'btnDAm'+index} className={`me-2 bg-green-light border-0 ${selectedButton === index ? 'bg-green text-white': 'text-green'}`} value={value} onClick={(e)=> {
                                                        HandleQRPayment(e, 'defaultAmounts');
                                                        setSelectedButton(index);
                                                    }}>
                                                        {ToCustomDecimal(value)} kr</Button>
                                                ))}
                                            </div>
                                        </Col>
                                        : ''
                                    }
                                    { infoQR?.thereIsCustomAmount === false && !infoQR?.defaultAmounts.length > 0 || infoQR?.thereIsCustomAmount === true  || infoQR?.thereIsCustomAmount === true && infoQR?.defaultAmounts.length > 0 ?
                                        <div id="testInputDisabled">
                                            {/*<CustomInput model={RequestQRPayment} attribute="amount" data={ selectedButton !== null ? '' : requestQRPayment.amount} customChange={(e) => HandleQRPayment(e, 'amount')}/>*/}
                                            <div className="w-100 text-start position-relative">
                                                <Label className="mb-2"> {t('QRCode.amount')}: </Label>
                                                <NumberInput value={selectedButton !== null ? '' : requestQRPayment.amount} placeholder={t('formPlaceholder.enterAmount')} validation={(e) => HandleQRPayment(e, 'amount')} name="amount" />
                                                <div className={`tooltip-custom-normal ${error !== '' ? 'opacity-1' : ''}`}>{error}</div>
                                            </div>


                                        </div>
                                        : ''}


                                    {infoQR.thereIsComment && infoQR.thereIsComment === true ?
                                    <CustomInput model={RequestQRPayment} attribute="comment" data={requestQRPayment.comment} customChange={(e) => HandleQRPayment(e, 'comment')}/>
                                    : ''}
                                </Row>
                                <Row className="mt-2">
                                    <Col lg={12}>
                                        {displaySpinner === true ?
                                            <>
                                                {t('validationMessage.loadingPayment')} <Spinner color='success'/>
                                            </>
                                            : ''}
                                    </Col>
                                    <Col lg={12} className="mt-2">
                                        <CustomSubmitButton id="chooseMethod" text={t('QRCode.choosePaymentMethod')} className="w-100" customClick={()=>{
                                            GoToPaymentMethod();
                                        }}/> {/*Fortsätt till betalning*/}
                                    </Col>
                                </Row>

                            </Col>
                        </Row>
                    </Card>
                </Col>
                : ''}
        </Row>

    )

}