import {Col, Row, Table} from "reactstrap";
import {ChangeDateFormat} from "../../Common/Helpers/ChangeDateFormat";
import React from "react";
import {useTranslation} from "react-i18next";
import {CheckUserRole} from "../../AppSystem/Helpers/CheckUserRole";
import {ToCustomDecimal} from "../../AppSystem/Helpers/ToCustomDecimal";

export function AttestHonorariumViews({data, modelId = null}) {
    const { t } = useTranslation();
    const isSuperAdmin = CheckUserRole('Admin'); //SuperAdmin

    return (
        <Row className="px-2 px-lg-4 justify-content-center">
            <hr className="mt-4" />
            <Col lg={8}>
                <h2 className="rokkitt-font">{t('honorarium.honorarium')}</h2>
            </Col>
            <Col lg={4} >
                <ul className="list-unstyled">
                    {isSuperAdmin === true && modelId ?
                        <>
                            <li className="pt-1 row">
                                <span className="fw-bold col-4">{t('modelLabel.modelId')}</span>
                                <span className="col-8">{modelId}</span>

                            </li>
                            <li className="pt-1 row">
                                <span className="fw-bold col-4">{t('modelLabel.userId')}</span>
                                <span className="col-8">{data.userId}</span>

                            </li>
                        </>
                    : '' }
                    {data.visualId !== "" ?
                        <li className="pt-2 row">
                            <span className="fw-bold col-4">{t('modelLabel.id')}:</span>
                            <span className="col-8">{data.visualId}</span>
                        </li>
                    : ""}
                    <li className="pt-1 pb-4 row">
                        <span className="fw-bold col-4">{t('modelLabel.userName')}</span>
                        <span className="col-8">{data.userName}</span>

                    </li>
                </ul>
            </Col>
            {data?.queuedPayments?.length > 0 ? /*apiResponseAttest.queuedPayments?.length > 0*/
                <>
                    <h6>{t('honorarium.queued')}</h6>
                    <div className="table-responsive">
                        <Table>
                            <thead>
                            <tr>
                                <th>{t('modelLabel.date')}</th>
                                <th className="text-center">{t('modelLabel.status')}</th>
                                <th className="text-end">{t('modelLabel.amount')}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {data?.queuedPayments.map((honor, index) => (
                                <React.Fragment key={'queuedPayments'+index}>
                                    <tr className="pt-2 border-bottom">
                                        <td>
                                            {ChangeDateFormat('date', honor.date)}
                                        </td>
                                        <td className="text-center">
                                            <div className={`fw-bold ${honor.status === 'Utbetald' ? "text-green" : "text-grey-custom"}`}>{honor.status}</div>
                                        </td>
                                        <td className="text-end">
                                            {honor.value ? ToCustomDecimal(honor.value) : 0} kr
                                        </td>
                                    </tr>
                                </React.Fragment>
                            ))}
                            </tbody>
                        </Table>
                    </div>
                </>
                : ''}
            <Col lg ={12}>
                <Row className="justify-content-end">
                    <Col lg={4} className="text-end pt-4">
                        <ul className="list-unstyled">
                            <li className="pt-2 row">
                                <span className="fw-bold col-8">{t('honorarium.payment')} </span>
                                <span className="col-4">
                                    {data.paymentInterval && data.paymentInterval === 12 ? t('modelLabel.monthly') : t('modelLabel.yearly') }
                                </span>
                            </li>
                            <li className="pt-2 row">
                                <span className="fw-bold col-8">{t('honorarium.annual')}</span>
                                <span className="col-4">{data.annualHonorarium ? ToCustomDecimal(data.annualHonorarium) : 0} kr</span>

                            </li>
                            <li className="pt-2 row">
                                <span className="fw-bold col-8">{t('modelLabel.net')}</span>
                                <span className="col-4">{data.net ? ToCustomDecimal(data.net) : 0} kr</span>

                            </li>
                            <li className="pt-2 row">
                                <span className="fw-bold col-8">{t('modelLabel.incomeTax')}</span>
                                <span className="col-4">{data.incomeTax ? ToCustomDecimal(data.incomeTax) : 0} kr</span>

                            </li>
                            <li className="pt-2 row">
                                <span className="fw-bold col-8">{t('modelLabel.employmentTax')}</span>
                                <span className="col-4">{data.employmentTax ? ToCustomDecimal(data.employmentTax) : 0} kr</span>

                            </li>
                        </ul>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}