class ResponseReimbursementExpense {
    constructor({
                    id = '',
                    orgId = '',
                    userId = '',
                    date = '',
                    toDate = '',
                    purpose = '',
                    costBearerId = '',
                    costBearerName = '',
                    fileId = '',
                    fileExtension = '',
                    dateReceive = '',
                    amount = 0,
                    vatAmount = 0,
                    accountingId = '',
                    accountingName = '',
                    status = '',
                    additionalInfo = '',
                    additionalFileExtension = '',
                } = {}) {
        this.id = id;
        this.orgId = orgId;
        this.userId = userId;
        this.date = date;
        this.toDate = toDate;
        this.purpose = purpose;
        this.costBearerId = costBearerId;
        this.costBearerName = costBearerName;
        this.fileId = fileId;
        this.fileExtension = fileExtension;
        this.dateReceive = dateReceive;
        this.amount = amount;
        this.vatAmount = vatAmount;
        this.accountingId = accountingId;
        this.accountingName = accountingName;
        this.status = status;
        this.additionalInfo = additionalInfo;
        this.additionalFileExtension = additionalFileExtension;
    }

    static getAttributes(filter = null, validationSchema = null) {
        const attributes = {
            id :{
                'label': 'Id',
                'sortable': true,
                'sortField': "id",
                'value': (item) => item.id || "",
            },
            orgId :{
                'label': 'Org Id',
                'sortable': true,
                'sortField': "orgId",
                'value': (item) => item.orgId || "",
            },
            userId :{
                'label': 'User Id',
                'sortable': true,
                'sortField': "userId",
                'value': (item) => item.userId || "",
            },
            date :{
                'label': 'Date',
                'sortable': true,
                'sortField': "date",
                'value': (item) => item.date || "",
            },
            toDate :{
                'label': 'Date',
                'sortable': true,
                'sortField': "toDate",
                'value': (item) => item.toDate || '',
            },
            purpose :{
                'label': 'Purpose',
                'sortable': true,
                'sortField': "purpose",
                'value': (item) => item.purpose || "",
            },
            costBearerId :{
                'label': 'Cost Bearer',
                'sortable': true,
                'sortField': "costBearerId",
                'value': (item) => item.purpose || "",
            },
            costBearerName :{
                'label': 'Cost Bearer Name',
                'sortable': true,
                'sortField': "costBearerName",
                'value': (item) => item.costBearerName || "",
            },
            fileId :{
                'label': 'File',
                'sortable': true,
                'sortField': "fileId",
                'value': (item) => item.fileId || "",
            },
            dateReceive :{
                'label': 'Date Receive',
                'sortable': true,
                'sortField': "dateReceive",
                'value': (item) => item.dateReceive || "",
            },
            amount :{
                'label': 'Amount',
                'sortable': true,
                'sortField': "amount",
                'value': (item) => item.amount || "",
            },
            vatAmount :{
                'label': 'VAT Amount',
                'sortable': true,
                'sortField': "vatAmount",
                'value': (item) => item.vatAmount || "",
            },
            accountingId :{
                'label': 'Accounting Id',
                'sortable': true,
                'sortField': "accountingId",
                'value': (item) => item.accountingId || "",
            },
            accountingName :{
                'label': 'Accounting Name',
                'sortable': true,
                'sortField': "accountingName",
                'value': (item) => item.accountingName || "",
            },
            status :{
                'label': "Status",
                'sortable': true,
                'sortField': "status",
                'value': (item) => item.status || "",
            },
            // additionalInfo :{
            //     'label': 'additional Info',
            //     'sortable': true,
            //     'sortField': "additionalInfo",
            //     'value': (item) => item.additionalInfo || "",
            // },
        }

        return attributes;
    }
}

export default ResponseReimbursementExpense;