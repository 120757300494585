import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { SaveRedux } from '../../../Common/Helpers/SaveRedux';
import useApiCallService from "../../../Common/Helpers/ApiCall";
import {API_URL, USER_PATHS} from "../../../config";
import { Button, Spinner } from 'reactstrap';
import { CustomReload } from '../../../AppSystem/Helpers/CustomReload';
import { logoutUser } from '../../../AppSystem/Redux/Auth/AuthSlice';
import {useTranslation} from "react-i18next";

export  function LoginWithRotenFromLRF() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [errorMessageResult, setErrorMessageResult] = useState(null);
    const { ApiCall } = useApiCallService();
    const {isAuthenticated} = useSelector(state => state.auth);

    useEffect(() => {
       
        const hubConnection = new HubConnectionBuilder()
                                 .withUrl(`${API_URL}/realTimeUpdateInfo`)
                                 .configureLogging(LogLevel.Information)
                                 .withAutomaticReconnect(0, 0, 10000)
                                 .build();

        async function ConnectSignalR() {
            try {
              if(isAuthenticated === true){
                dispatch(logoutUser());
                CustomReload();
                
              }
                
                const searchParams = new URLSearchParams(window.location.search);
                const sessionId = searchParams.get('session');

                await hubConnection
                    .start()
                    .then(() => {
                    })
                    .catch(error => {
                        console.log(`Error: ${error}`);
                    });

                fetchData(hubConnection.connectionId, sessionId);
                hubConnection.on('ProcessUpdates', (message) => {
                    if (message.isSuccess === true) {
                        SaveRedux(dispatch,'tokenData', message.token);
                        SaveRedux(dispatch,'refreshTokenData', message.refreshToken);
                        SaveRedux(dispatch,'userData', message.user);
                        SaveRedux(dispatch,'rolesData', message.roles ?? []);
                        SaveRedux(dispatch,'orgData', message.orgData ?? []);
                        hubConnection.stop();
                        setErrorMessageResult('');
                        const link = document.createElement('a');
                        link.href = USER_PATHS.DASHBOARD;
                        link.rel = 'noopener noreferrer';                       
                        link.click();   
                                           
                    } else {
                        setErrorMessageResult(message.errorMessage);
                    }
                });
            } catch (err) {
                setTimeout(ConnectSignalR, 5000);
            }
        }

        const fetchData = async (connectionIds, session) => {
            try {
               const loginRotenCreateRequest = {
                   connectionId: connectionIds,
                   sessionId: session                 
                };

               const jsonRequest = JSON.stringify(loginRotenCreateRequest);    

               const apiCallOptions = {
                    "method" : "Post",
                    "url" : "/loginroten/sso",
                    "data" : jsonRequest
               };
               await ApiCall(apiCallOptions, setErrorMessageResult);
               
            } catch (error) {
               console.log('error', error);
            }
        };

      ConnectSignalR();
        return () => {
           hubConnection.stop();
        };
    }, []);    
    
    const handleBackToTestAccount = () => {
        dispatch(logoutUser());
        CustomReload('https://lrfssoback.demondog.es/');
      };
    return(          
        <>
    {errorMessageResult && errorMessageResult.isSuccess === false ? (
      <div className="spinner-container">
      <div className="spinner-wrapper">
        <h1 style={{ marginBottom: '20px' }}>
        {errorMessageResult.errorMessage.includes('Contact') ? (
            <>
              Du saknar tillräcklig behörighet att öppna Digitala Kassan.
              <br />
              Kontakta supporten på{' '}
              <a href="mailto:digitalakassan@lrf.se" style={{ color: '#006400', textDecoration: 'underline' }}>
                digitalakassan@lrf.se
              </a>.
            </>
          ) : errorMessageResult.errorMessage.includes('Roten') ? (
            <>
              Ett fel uppstod i kommunikationen med lrf.se, vänligen försök igen.
              <br />
              Om felet kvarstår vänligen kontakta support:{' '}
              <a href="mailto:digitalakassan@lrf.se" style={{ color: '#006400', textDecoration: 'underline' }}>
                digitalakassan@lrf.se
              </a>.
            </>
          ) : (
            errorMessageResult.errorMessage
          )
        }
      </h1>
        {/* <Button onClick={handleBackToTestAccount}>{t('roterLogin.buttonBack')}</Button> */}
      </div>
      </div>
    ) : (
        <div className="spinner-container">
        <div className="spinner-wrapper">
          <Spinner color='success' style={{ width: '5rem', height: '5rem' }} />
          <p>{t('roterLogin.spinner')}</p>
        </div>
      </div>
    )}
  </>
     );
}