import SortableList from "../../Common/Components/Lists/SortableList";
import {Button, Col} from "reactstrap";
import React, {useEffect, useState} from "react";
import RequestInvoice from "../../Invoice/Models/Response/ResponseInvoice";
import {useTranslation} from "react-i18next";
import {CustomButton} from "../../Common/Components/Button/CustomButton";
import {ORGANIZATION_PATHS} from "../../config";
import {useNavigate} from "react-router-dom";
import ResponseQRCode from "../Models/Response/ResponseQRCode";
import {CheckUserPermission} from "../../AppSystem/Helpers/CheckUserPermission";
import {CustomModal} from "../../Common/Components/Modal/CustomModal";
import useApiCallService from "../../Common/Helpers/ApiCall";
import {CustomReload} from "../../AppSystem/Helpers/CustomReload";

export function QRCodeList() {
    const { t } = useTranslation();
    const { ApiCall } = useApiCallService();
    const navigate = useNavigate();
    const [displayDeleteModal, setDisplayDeleteModal] = useState(false);
    const [qrCodeId, setQrCodeId] = useState(null);

    const [deleteApiResponse, setDeleteApiResponse] = useState(null);


    let permissionCreate = false;
    if (CheckUserPermission("org-qr.create") || CheckUserPermission("org-qr")) {
        permissionCreate = true;
    }
    let permissionUpdate = false;
    if (CheckUserPermission("org-qr.update") || CheckUserPermission("org-qr")) {
        permissionUpdate = true;
    }

    let permissionDelete = false;
    if (CheckUserPermission("org-qr.delete") || CheckUserPermission("org-qr")) {
        permissionDelete = true;
    }

    // HANDLE MODAL DELETE
    const handleModalDelete = async () => {
        const apiCallOptions = {
            method: "delete",
            url: '/orgqr',
            data: {
                id: qrCodeId
            }
        };
        try {
            await ApiCall(apiCallOptions, setDeleteApiResponse);
            setDisplayDeleteModal(false);
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        if (deleteApiResponse !== null) {
            CustomReload(ORGANIZATION_PATHS.QR_CODE_LIST);
        }
    }, [deleteApiResponse]);

    const renderActionColumn = (item) => {
        return (
            <>
                {permissionDelete === true ?
                    ( item.totalPaid > 0 || item.totalPayments > 0 ? '' :
                        <Button className="box-red border-0 position-relative me-2 rounded class-delete" onClick={() => {
                            setDisplayDeleteModal(true);
                            setQrCodeId(item.id);
                            const allButtons = document.querySelectorAll('button');
                            allButtons.forEach(button => {
                                if (button.classList.contains('class-link')) {
                                    button.disabled = true;
                                    button.classList.remove('bg-green-light', 'text-green');
                                    button.classList.add('bg-grey-tab','text-secondary');
                                } else if (button.classList.contains('class-delete')) {
                                    button.disabled = true;
                                    button.classList.remove('box-red', 'text-red');
                                    button.classList.add('bg-grey-tab','text-secondary');
                                } else if (button.classList.contains('class-add')){
                                    button.disabled = true;
                                    button.classList.remove('btn-submit-kassan');
                                    button.classList.add('bg-grey-tab','text-secondary','border-0');
                                }
                            });
                        }}>
                            <i className="bx bx-x-circle fs-5 align-middle pb-1"/>
                            <div className="tooltip-custom-button">
                                {t("button.delete")}
                            </div>
                        </Button>)
                    : ''}

                {
                    permissionUpdate === true ?
                        <Button className="bg-green-light text-green border-0 position-relative me-2 class-link" onClick={() => {
                            navigate(`${ORGANIZATION_PATHS.QR_CODE_CREATE}?id=${item.id}`)
                        }}>
                            <i className="bx bx-edit fs-5 align-middle pb-1"/>
                            <div className="tooltip-custom-button">
                                {t("button.edit")}
                            </div>
                        </Button>
                        : ''
                }

                <Button className="bg-green-light text-green border-0 position-relative class-add" onClick={() => {
                     navigate(`${ORGANIZATION_PATHS.QR_CODE_INFO}?id=${item.id}`)
                }}>
                    <i className="bx bx-show fs-5 align-middle pb-1" />
                    <div className="tooltip-custom-button">
                        {t("button.info")}
                    </div>
                </Button>

            </>
        );
    }
    const renderButtonColumn = (item) => {
        return (
            <>
                {permissionCreate ?
                <Col lg={12} className="mt-4 px-3">
                   {/* <CustomButton customClasses="rounded" text={t('QRCode.addCollection')} icon="bx bx-plus pe-2" onClick={() => {
                        navigate(ORGANIZATION_PATHS.QR_CODE_CREATE)
                    }}
                    />*/}
                    <Button className="rounded p-custom-button btn-submit-kassan class-add" onClick={() => {
                        navigate(ORGANIZATION_PATHS.QR_CODE_CREATE)
                    }}>
                        <i className={`bx bx-plus pe-2 align-middle size-icon-custom-button`} />
                        {t('QRCode.addCollection')}
                    </Button>
                </Col>

                 : ""}
            </>
        );
    }

    const modalContent = (
        <div>
            {`${t('validationMessage.modalVar')}?`}
        </div>
    );
    const modalFooter = (
        <>
            <Button className="box-green border-0" onClick={handleModalDelete}>
                {t('button.delete')}
            </Button>
            <Button className="box-red border-0" onClick={() => {
                setDisplayDeleteModal(false);
                const allButtons = document.querySelectorAll('button');
                allButtons.forEach(button => {
                    if (button.classList.contains('class-link')) {
                        button.disabled = false;
                        button.classList.add('bg-green-light', 'text-green');
                        button.classList.remove('bg-grey-tab','text-secondary');
                    } else if (button.classList.contains('class-delete')) {
                        button.disabled = false;
                        button.classList.add('box-red', 'text-red');
                        button.classList.remove('bg-grey-tab','text-secondary');
                    }else if (button.classList.contains('class-add')){
                        button.disabled = false;
                        button.classList.add('btn-submit-kassan',);
                        button.classList.remove('bg-grey-tab','text-secondary','border-0');
                    }
                });
            }}>{t('button.cancel')}</Button>
        </>
    );

    const columns = [ "visualId", 'name','createdByName' , 'createdAt','totalPaid','totalPayments',];
    const listOptions = {
        "model": ResponseQRCode,
        'columns':columns,
        "actions": {
            "actionColumn" : renderActionColumn,
            "addButton": renderButtonColumn,
        },
       // "exportIsAvailable": true,
       // "urlGetListItemsToExport": "/orgqr/active/search",
        //"nameFile": t('digitalAccount.label'),
        "ignoreFields" : columns,
       "urlGetSearch" : "/orgqr/active/search",
    };

    const listOptionsInactive = {
        "model": ResponseQRCode,
        'columns': columns,
        "actions": {
            "actionColumn" : renderActionColumn,
           // "addButton": renderButtonColumn,
        },
       // "exportIsAvailable": true,
        // "urlGetListItemsToExport": "/orgqr/active/search",
        //"nameFile": t('digitalAccount.label'),
        "ignoreFields" : columns,
        "urlGetSearch" : "/orgqr/inactive/search",
    };

    //Lögg till insamling

    return(
        <>
            <CustomModal isOpen={displayDeleteModal} toggle={() => setDisplayDeleteModal(!displayDeleteModal)}
                         body={modalContent} footer={modalFooter}/>
            <Col lg={12} className="p-4 pb-3 mt-4 card-backend">
            <h2 className="fw-bold rokkitt-font">{t('QRCode.listActive')}</h2>
            <SortableList
                listOptions={listOptions}
            />
        </Col>
            <Col lg={12} className="p-4 pb-3 mt-4 card-backend mb-5">
                <h2 className="fw-bold rokkitt-font">{t('QRCode.listInactive')}</h2>
                <SortableList
                    listOptions={listOptionsInactive}
                />
            </Col>
        </>

    )

}