import {Button, Card, Col, Row, Spinner} from "reactstrap";
import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import useApiCallService from "../../Common/Helpers/ApiCall";
import ResponsePay from "../Models/Response/ResponsePay";
import {PAY_PATHS} from "../../config";
import {useNavigate} from "react-router-dom";
import {CustomButton} from "../../Common/Components/Button/CustomButton";
import {AttestPayViews} from "../../Attest/Components/AttestPayViews";

export function PayInfo () {
    const { t } = useTranslation();
    const {ApiCall} = useApiCallService();
    const navigate = useNavigate();

    const [responsePay, setResponsePay] = useState(new ResponsePay());
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id') ?? "";

    useEffect(() => {
        const handlePayResponse  = (response) => {
            setResponsePay(response);
        };
        const PayApiCall = async () => {
            try {
                const apiCallOptions = {
                    method: "get",
                    url: `/pay?id=${id}`,
                };
                await ApiCall(apiCallOptions, handlePayResponse);
            } catch (error) {
                console.log('error', error);
            }
        };
        PayApiCall();
    }, []);

    return (
        <>
            <Row>
                <Col lg={1} className="px-0">
                    <CustomButton
                        text={t('button.back')}
                        icon={'bx bx-chevron-left'}
                        buttonSize={'small'}
                        customClasses={'ms-3 mb-3'}
                        onClick={() => {
                            navigate(PAY_PATHS.PAY)
                        }}
                    />
                </Col>
            </Row>
            <Col lg={12} className="pb-5 px-0">
                <Row className="px-3">
                    <Col xl={6} className="py-4 card-backend px-0 px-lg-4">
                        {responsePay && responsePay.id !== "" ? (
                            <Row className="px-4 justify-content-center">
                                <AttestPayViews data={responsePay} type="payView"/>
                            </Row>

                        ) : ""}
                    </Col>
                    {responsePay.approvedDeniedByName && responsePay.approvedDeniedByName !== "" && (responsePay.status === "Nekad" || responsePay.status === "Godkänd") ?
                        <Col lg={6}>
                            <Card className="py-4 card-backend px-0 px-lg-4">
                                <Row>
                                    <Col lg={12} className="text-center">
                                        <h2 className={`${responsePay.status === "Nekad" ? "text-red" : "text-green"} rokkitt-font`}>{responsePay.status === "Nekad" ? t('attest.denied') : t("attest.approved")}</h2>
                                    </Col>
                                    <Col lg={12}>
                                        <Card className="bg-card-grey p-3">
                                            <h5 className='rokkitt-font'>{t('modelLabel.approvedDeniedByName')}</h5>
                                            {responsePay.approvedDeniedByName}
                                            {responsePay.reason ?
                                                <>
                                                    <h5 className='rokkitt-font mt-4'>{t('modelLabel.reason')}</h5>
                                                    {responsePay.reason}
                                                </>
                                                : ""}
                                        </Card>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    : ""}
                </Row>
            </Col>
        </>
    );
}