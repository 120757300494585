import i18n from "i18next";
import { ChangeDateFormat } from "../../Common/Helpers/ChangeDateFormat";

class ResponseSieModel {
    constructor({
                    id = '',
                    visualId = '',
                    path = '',
                    url = '',
                    fileName = '',
                    type = '',
                    generated = '',
                    createdAt = '',
                    markdownString = '',
                } = {}) {
        this.id = id;
        this.visualId = visualId;
        this.path = path;
        this.url = url;
        this.fileName = fileName;
        this.type = type;
        this.generated = generated;
        this.createdAt = createdAt;
        this.markdownString = markdownString;
    }

    static getAttributes(filter = null, validationSchema = null) {

        const attributes = {
            id :{
                'label': 'ID',
                'sortable': true,
                'sortField': 'id',
                'value': (item) => item.id || "",
            },
            visualId :{
                'label': i18n.t('modelLabel.id'),
                'sortable': true,
                'sortField': 'visualId',
                'value': (item) => item.visualId || "",
            },
            path :{
                'label': i18n.t('modelLabel.firstName'),
                'sortable': true,
                'sortField': 'path',
                'value': (item) => item.path || "",
            },
            url :{
                'label': i18n.t('modelLabel.lastName'),
                'sortable': true,
                'sortField': 'url',
                'value': (item) => item.url || "",
            },
            fileName :{
                'label': i18n.t('modelLabel.fileName'),
                'sortable': true,
                'sortField': 'fileName',
                'value': (item) => {
                    if (item.generated && item.generated.includes("Inga")) {
                        return `Inga transaktioner`;
                    }
                    return item.fileName || "";
                },
            },
            type :{
                'label': i18n.t('modelLabel.email'),
                'sortable': true,
                'sortField': 'type',
                'value': (item) => item.type || "",
            },
            generated :{
                'label': i18n.t('modelLabel.generated'),
                'sortable': true,
                'sortField': 'generated',
                'value': (item) => item.generated || "",
            },
            createdAt :{
                'label': i18n.t('modelLabel.lastRunAt'),
                'sortable': true,
                'sortField': 'createdAt',
                'value': (item) => item.createdAt ? ChangeDateFormat('dateTime', item.createdAt) : "" || "",
            },
            markdownString :{
                'label': i18n.t('modelLabel.email'),
                'sortable': true,
                'sortField': 'type',
                'value': (item) => item.markdownString || "",
            },
        }

        return attributes;
    }
}

export default ResponseSieModel;