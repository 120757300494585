export const esp = {
  footer: {
    text: 'Diseñado y desarrollado por Trust Anchor Group',
  },

  userNotifications: {
    notifications: 'Notificaciones',
    text: 'Marcar todo como leído',
  },
  roterLogin:{
    buttonBack: 'Volver a la cuenta de prueba',
    spinner: 'Cargando datos del usuario...',
    loginQR: 'Acceso QR'
  },
  notificationsDropdown:{
    notifications: 'Notificaciones',
    text: 'Marcar todo como leído',
    icon: 'Ver Más...',
  },
  sortableList:{
    search: 'Buscar',
    buttonTable: 'Tabla',
    buttonGrid: 'Grid',
    loading: 'Cargando...',
    itemsPage: 'elementos por página',
    noResults: 'No se encontraron resultados.',
    apiErrorResult: 'No fue posible conectarse con la API',
    gettingData:'Obtenitendo datos...',
    exportData: 'Exportar ',
    all:'Todos los elementos'
  },
  reportProceeding:{
    calculations:{
      title: 'Cálculos',
      subtitle: 'Desarrollo',
      salary: 'Salario',
      gross: 'Bruto',
      hours: 'Horas * Salario por hora o fijo',
      mileage: 'Kilometraje',
      taxFree:{
        title: 'Libre de impuestos:',
        text: 'Kilómetros / 10 * Viaje deducible por 10Km',
      },
      taxable: {
        title: 'Taxable:',
        text: 'KM kr + Total por pasajeros KM kr - Tax Free',
      },
      incomeTax:{
        title: 'Base imponible:',
        text: 'Base imponible * Impuesto de renta % / 100',
        textGross: 'Bruto * Impuesto sobre la renta % / 100'
      },
      net:{
        title: 'Neto:',
        text: 'Cuota tributaria + Base imponible - IRPF',
      },
      employmentTax: {
        title: 'Impuesto sobre el empleo:',
        text: 'Base imponible * Impuesto de empleo % / 100',
        textGross: 'Bruto * I.R.P.F. % / 100'
      },
      expenses: 'Gastos',
      amount: 'Importe:',
      amountWithout: 'Importe sin IVA:',
      tax: 'Tasas',
      total: 'Total',
      totalText: 'Importe + IVA',
      amountTotal: 'Importe - Impuestos'
    }
  },
  ////////////////////////////////////////////////////////////
  payReceive: {
    payReceiveTitle: 'Pagos Recivos',
    payReceive: 'Pagos/Recibos',
    pay: 'Pagos',
    quickPay: 'Pagos',
    bankAccount: 'Cuenta bancaria',
    bankgiro: 'Bankgiro',
    plusgiro: 'Plusgiro',
    selectPayment: 'Seleccione el método de pago y rellene los datos de pago',
    payment:'Pagar',
    transfers: 'Transferencias',
    sign: 'Firmar para el pago',
    // buttonPay: 'Pagar factura',
    // buttonManagement: 'Gestionar gastos',
    // buttonTransfer: 'Transferencia directa',
    quickPayment: 'Pago Rapido',
    quickPayments: 'Pagos Rapidos',
    quickPaymentSubtitle: 'Rellene los datos de pago',
    //temporalAlert:'Esta página es sólo visual, no tiene funcionalidad.',
    payInvoice:'Factura de pago',
    paymentType: 'Tipo de pago',
    email: 'Correo electrónico',
    description: 'Descripción',
    amountQuickPayment: 'Importe',
    infoEmail: 'Escriba o pegue las direcciones de correo electrónico de los destinatarios. Las direcciones pueden ir separadas por una nueva línea, un espacio, una coma o un punto y coma.',
  },
  payDirectTransmission:{
    transfer: 'Transferencia directa',
    paymentRefers: 'El pago hace referencia a*',
    number: 'Número de autorización*',
    accountNumber: 'Número de cuenta*',
    notes: 'Notas*',
    amount: 'Importe*',
    //save: 'Guardar en notas',
    //sign: 'Firmar para pagar',
    second: 'Segunda pestaña',
    managing: 'Gestión de facturas',
    selectInvoice: 'Seleccione una factura de la lista para gestionar los pagos',
  },
 /* payExpenses:{
    notifications: 'Notificaciones',
    number: 'Número de pagos atrasados',
    unpaid: 'Gastos pendientes de pago',
    from: 'Desde el',
    paid: 'Gastos pagados',
    add: 'Añadir gastos',
    new: 'Nuevo recibo',
    manage: 'Gestionar facturas',
    select: 'Seleccione una factura de la lista para gestionar los pagos'
  },
  payInvoice:{
    notifications: 'Notificaciones',
    number: 'Número de pagos atrasados',
    unpaid: 'Facturas pendientes de pago',
    date: 'Fecha de vencimiento',
    paid: 'Facturas pagadas',
    add: 'Añadir factura',
    new: 'Nueva factura',
    download: 'Descargar documentos',
    sign: 'Firmar para el pago',
    manage: 'Gestionar facturas',
    select: 'Seleccione una factura de la lista para gestionar los pagos'
  },*/
  /////////////////////////////New////////////////////////////////////////
  modelLabel: {
    id: 'Id',
    org:'Organización',
    orgId: 'Id de la organización',
    orgName: 'Nombre de la Organización',
    orgType: 'Tipo de Organización',
    orgDestination:'Organization de Destino',
    orgDetails:'Detalles de la Organización',
    orgNumber:'Numero de la organización',
    costPlace: 'Lugar de coste',
    createdAt:'Creado el',
    createdBy:'Creado por',
    creatorName:'Nombre del creador',
    attestAmount:'Cantidad de la transacción',
    updatedBy:'Actualizado por',
    updatedAt: 'Actualizado el',
    updatedByName: 'Actualizado por (Nombre)',
    modelId:'Model Id',
    user: 'User',
    userId: 'Id del usuario',
    userName: 'Nombre del usuario',
    name:'Nombre',
    parentId: 'Id del padre',
    parentName: 'Nombre del Padre',
    fullName: 'Nombre completo ',
    firstName: 'Nombre',
    lastName: 'Apellido',
    info: 'Información',
    country: 'País ',
    sweden: 'Suecia',
    pnr: 'PNR',
    email: 'Email',
    phone: 'Telefono',
    employmentTax: 'Impuesto sobre el empleo',
    incomeTax: 'Impuesto sobre la renta',
    net:'Neto',
    date:'Fecha',
    dateReimbursement:'Fecha de Actividad',
    dateReceive: 'Fecha de recepción',
    status:'Estado',
    amount:'Importe',
    amountInvoice:'Importe',
    addAmount: 'Añadir Importe', ///,mirar si esta traduccion se usa en algun sitio, si no borrarla
    type:'Tipo',
    typeId:'Id del tipo',
    types:'Tipos',
    vatAmount: 'IVA',
    vatAmountInvoice: 'IVA',
    accountingId:'Id de la Contabilidad',
    accounting:'Contabilidad',
    fileId: 'Id del archivo',
    fileName:'Nombre del archivo',
    file: 'Archivo',
    files: 'Archivos',
    additionalFileId: 'Id del archivo adicional',
    additionalFileName:'Nombre del archivo adicional',
    kr: 'kr',
    km: 'km',
    hours: 'Horas',
    kilometers: 'Kilometros',
    total: 'Total',
    totalAmount: 'Importe Total',
    welcome: 'Bienvenido',
    profile: 'Perfil',
    logOut: 'Cerrar sesión',
    paid: 'Pagado',
    unpaid: 'Sin pagar',
    yearly: 'Anual',
    monthly: 'Mensual',
    description:'Descripción',
    model:'Model',
    balance: 'Balance',
    currency:'Divisa',
    from:'Desde',
    to:'Hasta',
    added:'Añadido',
    grossAmount:'Suma',
    soon: 'Lo antes posible',
    whenToPay:'Cuando pagar',
    reason: "Razón",
    creditorName: 'Nombre del acreedor',
    forYou: 'Para ti',
    forYourOrganization: 'Para tu organización',
    userSalary: 'Salario del usuario',
    initialized: 'Inicializado',
    aborted: 'Cancelada',
    message: 'Mensaje',
    recipient: 'Destinatario',
    paymentMethod: "Metodo de pago",
    transactionDate: "Fecha de la Transacción",
    approvedDeniedByName: "Supervisado por",
    approvedDeniedAt: "Supervisado el",
    generated: "Generado",
    salarySheet: "Salario",
    active: "Activo",
    deleted: "Borrado",
    taxAuthority: "Resportes a la autoridad fiscal",
    lastRunAt: 'Última ejecución',
    paymentType: 'Tipo de pago',
    credited:'Acreditada',
    revoked: 'Revocada',
    attestQueue: 'Certificado en cola',
    TheSwedishTaxAgency: 'Agencia tributaria sueca',
    comment:'Comentario',
    download: 'Descargar',
    downloading: 'Descargando...',
    transaction: 'Open Payment Transacciones',
    bookedDate: 'Fecha de reserva',
    balanceAfterTransaction: 'Saldo después de la transacción',
    openPaymentAccountId: 'ID de la cuenta de pago abierto',
    openPaymentTransactionId: 'ID de transacción de pago abierto',
    openPaymentStatus: 'Estado de pago abierto',
    transactionListId: 'ID de la lista de transacciones',
    updateTransactions: 'Actualizar transacciones',
    consent: 'Consentimiento',
    consentCode: 'Código de consentimiento',
    invoiceNumber: 'Número de factura',
    paidOut: 'Pagado',
    sentAt: 'Enviado a',
    daily: 'Diariamente (Test)',
    collect: 'Recolectar',
    reports:'Informes',
    totalDistance: 'Distancia total (km)',
    refresh: 'Refrescar',
    refreshExpired: 'Su codigo QR ha expirado',
    provider: 'Métodos de pago',
    method: 'Número',
    canceled: 'Cancelado',
    fromDateReimbursement: 'Desde',
    toDateReimbursement: 'Hasta',
    multipleDayEvent: 'Evento de varios días',
    KST: 'KST',
    header: 'Cabecera',
  },
  adminLabel: {
    admin:'Administrador',
    organizations: 'Adminstrar Organizaciones',
    details:'Detalles del administrador de la organización',
    users:'Administrar usuarios',
    systemRoles: 'Roles del sistema',
    orgUser: 'Usuario de la organización',
    orgUsers:'Usuarios de la organización',
    orgRoles: 'Roles de la organización',
    digitalAccount: 'Administrar cuenta digital',
    systemAdmin: 'Administrador del sistema',
    system:'Sistema',
    allAccount: 'Todas las cuentas',
    assignRoles: "Asignar roles",
    assignOrg: "Asignar organización",
    userLoggedOnLastTime: 'Conectado por última vez',
    assignCostPlaces: 'Lugares de coste primarios',
  },
  button:{
    back: 'Volver',
    cancel: 'Cancelar',
    delete: 'Borrar',
    send: 'Enviar a atestado',
    sendToAttest: 'Send to attest',
    sendAndCreateCopy: 'Enviar y crear copia',
    save: 'Guardar',
    loading: 'Cargando...',
    edit: "Editar",
    info: 'Info',
    accept:'Acceptar',
    continue:'Continuar',
    moreInfo:'Mas info',
    hideInfo:'Ocultar info',
    create: 'Crear',
    assign: 'Asignar',
    active: 'Activar',
    renew: 'Renovar',
    authorize: 'Autorizar',
    home: 'Inicio',
    close: "Cerrar ventana",
    saveReimbursement: "Guardar borrador"
  },
  validationMessage:{
    sendError: 'Se ha producido un error, inténtelo de nuevo más tarde o póngase en contacto con LRF',
    sendErrorShort: 'Se ha producido un error',
    sendSuccessfully: 'Los datos se han enviado correctamente',
    fileDeleted: 'Archivo eliminado correctamente',
    fileFormatIncorrect: 'El formato del archivo es incorrecto',
    fileFormatCorrect: 'El formato del archivo es correcto',
    sendForm: 'Tus datos están siendo enviados, por favor espera...',
    modalVar: '¿Estás segura de que quieres eliminar',
    modalImage: '¿Estás seguro de que deseas eliminar esta imagen?',
    modalItem: '¿Estás segura de que quieres eliminar este artículo?',
    modalAmount: '¿Está seguro de que desea eliminar esta cantidad?',
    fieldRequired: 'Campo requerido',
    validOption: 'Por favor, seleccione una opción válida',
    greater0: 'El valor debe ser mayor que 0',
    digits4: 'El número debe tener 4 dígitos',
    digits7: 'El número debe tener 7 dígitos',
    digits2: 'El número debe tener 2 dígitos',
    max12: 'El número debe tener un máximo de 12 dígitos',
    max140: 'Max 140 caracteres',
    max40: 'Max 40 caracteres',
    between05and24: 'El valor debe ser un número entero entre 0 y 24',
    minDigit4: 'El número debe tener al menos 4 dígitos',
    vatLessAmount: 'El IVA debe ser inferior al importe',
    fileDeletedImportant: 'IMPORTANTE: Si se elimina la imagen, al hacer clic en cancelar no volverás a tener la imagen',
    wrong: 'Algo salió mal', //mirar si este mensaje se va a usar o no, por que es para el sortablelist
    changeNotSave:' Tienes cambios que no se guardan. Estás seguro que quieres irte?',
    reimbursementSuccessfully:'El procedimiento de informe se ha creado correctamente',
    reimbursementSavedSuccessfully:'El procedimiento de informe se ha guardado correctamente',
    internalTransactionSuccessfully: 'La transacción interna se ha creado correctamente',
    honorariumSuccessfully:'Los honorario ha sido creado correctamente',
    honorariumUpdate:'Los honorario se ha actualizado correctamente',
    bankAccountSave:'La cuenta bancaria se ha guardado correctamente',
    bankAccountUpdate:'La cuenta bancaria se ha actualizado correctamente',
    attestApproved:'La transacción ha sido aprobada correctamente',
    attestDenied:'La transacción ha sido denegada correctamente',
    paymentDenied:'La transacción has been successfully denied',
    roleSuccessfully:'El rol se ha creado correctamente',
    permissionUpdate:'Los permisos se han actualizado correctamente',
    typeSuccessfully:'El tipo se ha creado correctamente',
    notAccount:'El usuario de la transación no tiene una cuenta bancaria asociada',
    insufficientBalance:'La cuenta de la organización no tiene fondos suficientes',
    modalAttest:'¿Estás segura de que quieres aprobar esta transacción?',
    modalAttestDeny:'¿Estás segura de que quieres denegar esta transacción?',
    modalAttestDenyHeader: "Denegar transacción",
    modalPaymentDeny:'¿Estás segura de que quieres denegar esta transacción?',
    modalPaymentDenyHeader:'Denegar transacción',
    canNotBeEmpty: "Campo no puede ser vacio",
    paySuccessfully: 'Pago realizado correctamente',
    selectPayment: 'Debe seleccionar un pago',
    sendReimbursement:'¿Realmente desea enviar el Acta?',
    saveReimbursement:'¿Realmente desea guardar el Acta?',
    sendReimbursementNotSaved: "Tiene datos sin guardar, ¿Desea enviar el Proceeding?",
    sendReimbursementNotSavedSave: "Tiene datos sin guardar, ¿Desea guardar el Proceeding?",
    organizationNotMoney: 'La organización no tiene suficiente dinero',
    sameOrg: 'Las organizaciónes no puedes ser la misma',
    payAborted: 'El pago se ha cancelado correctamente',
    needAccount: 'Es necesario tener una cuenta para enviar un trámite.',
    goAddAccount: 'Ir a agregar cuenta',
    invalidEmail: 'El correo electrónico debe contener al menos una @ y un dominio válido (por ejemplo, example@domain.com).',
    deleteUser: '¿Estás seguro de que quieres eliminar este usuario?',
    activeUser: '¿Estás seguro de que quieres activar este usuario?',
    client: 'El cliente ha sido creado correctamente',
    invoiceSave: 'La factura se ha guardado correctamente',
    invoiceSend: 'La factura se ha enviado correctamente',
    invoiceSendToAttest: 'ILa factura se ha enviado para el atestado correctamente',
    needSave: 'La factura debe estar guardada',
    QRCode:'El QR se ha creado correctamente',
    clientPermission: 'Necesita el permiso de cliente para ver este campo',
    loadingPayment:'Cargando metodo de pago',
    dontLetter:'Solo se permiten números',
    onlyNumbers: 'Formato de número incorrecto, por favor, use números enteros o con hasta dos decimales.',
    creditInvoice :'La factura de crédito se creó correctamente.',
    sendCreditInvoice:'¿Realmente desea crear un crédito de factura?',
    changeStatus:'¿Estás segura de que quieres cambiar el estado a pagado?',
    consentExpired: 'El consentimiento ha caducado',
    consentNotFound: 'No se ha encontrado el consentimiento',
    accountIdNotValid: 'La cuenta no es válida',
    serviceUnavailableTryAgain: 'Servicio no disponible, inténtelo de nuevo más tarde',
    renewConsentSuccessfully: "El consentimiento se ha renovado con éxito",
    consentAuthorizationPage: "La página de autorización de consentimiento se abrirá pronto",
    activeConsentSuccessfully: "El consentimiento se ha activado con éxito",
    updateTransactionsSuccessfully: "Las transacciones se han actualizado correctamente",
    consentRejected: "El consentimiento ha sido rechazado",
    sendingReimbursement: "Enviando informe, por favor espera...",
    savingReimbursement: "Guardando informe, por favor espera...",
    sendingPay: "Envia el pago, por favor espera...",
    savingClient: "Guardando cliente, por favor espera...",
    sendingInvoice: "Enviando factura, por favor espera...",
    savingInvoice: "Guardando factura, por favor espera...",
    sendingQr: "Enviando QR, por favor espera...",
    sendingInternalTransaction: "Enviando transacción interna, por favor espera...",
    sendingHonorarium: "Enviando honorario, por favor espera...",
    loadingSieData: "Cargando datos SIE, por favor espera...",
    assigningRoles: "Asignando roles, por favor espera...",
    assigningOrgs: "Asignando organizaciones, por favor espera...",
    sendingConsent: "Enviando consentimiento, por favor espera...",
    deleteFixed: "¿Estás seguro de que quieres eliminar el salario?",
    deleteMileage: "¿Estás seguro de que quieres eliminar el kilometraje?",
    deleteExpense: "¿Estás seguro de que quieres eliminar el gasto?",
    consentIsValidated: "El consentimiento ha sido validado",
    consentIsValidating: "Validando consentimiento, por favor espera...",
    selectAmountType: "Es necesario seleccionar un tipo de cantidad",
    dateMustBeLessThanToDate: "La fecha debe ser menor que la fecha hasta",
    totalHourMustBeMoreThan0: "El total de horas debe ser mayor que 0",
    totalAmountMustBeMoreThan0: "El importe total debe ser mayor que 0",
    assigningCostPlaces: "Asignando lugares de coste, por favor espera...",
  },
  formPlaceholder: {
    selectDate: 'Seleccionar fecha',
    selectOption: 'Selecciona una opción',
    datePicker: 'desde - hasta',
    enterRole: 'Introducir Rol',
    enterPermissions: 'Introducir permiso',
    enterType: 'Introducir tipo',
    enterQuantity: 'Introducir cantidad',
    enterKm: 'Introducir Km',
    enterAmount: 'Introducir importe',
    dragAndDrop: 'Arrastrar y soltar',
    enterDate:'Introducir fecha',
    enterDateReimbursement:'Introducir fecha de la actividad',
    enterPurpose:'Introducir asunto',
    enterVatAmount: 'Introducir IVA',
    enterHours: 'Introducir horas',
    enterOrigin: 'Introducir origen',
    enterDeparture: 'Introducir lugar de salida',
    enterDestination: 'Introducir destino',
    enterPassengers: 'Introducir ppasajeros',
    enterTotalAmount: 'Introducir importe total',
    selectUserName: 'Seleccionar nombre de usuario',
    selectOrg:'Seleccionar Organización',
    firstPayment:'Fecha del primer pago',
    modelEnterClearingNumber:'Introducir Número de compensación',
    modelEnterAccountNumber:'Introducir Número de cuenta',
    reason: 'Introducir razón',
    digits: 'dígitos',
    enterCreditorName:'Introduzca el nombre del acreedor',
    selectClientName: 'Seleccione el nombre del cliente',
    enterRecipient: 'Introduzca la referencia del destinatario',
    enterReference: 'Introducir referencia',
    enterPrice: 'Introduce precio',
    enterClientName: 'Introduzca el nombre del cliente',
    enterOrgNumber: 'Introduzca el CIF',
    enterAddress: 'Introducir dirección',
    enterPostalCode: 'Introducir postal code',
    enterCity: 'Introduccir ciudad',
    enterEmail: 'Introduccir email',
    enterTitle: 'Introducir titulo',
    enterQRName: 'Introduzca, por ejemplo, nombres que sólo sean visibles internamente',
    enterDescription: 'Introducir descripción',
    enterComment: 'Introducir comentario'
  },

  reimbursement: {
    reportProcessing: 'Reporte del proceso',
    reportProcessings: 'Autorizaciones',
    newProcessing: 'Nuevo reporte',
    sendInfo: "Para enviar el informe de actas deberá contener ya sea Salario, Kilometraje o Gastos",
    salary: 'Salario',
    hourly: 'Por hora',
    movable: 'Movil',
    fixed: 'Fijo',
    addSalary: 'Añadir Salario',
    purpose: 'Asunto',
    costBearer: 'Portador del coste',
    costBearerName: 'Nombre del portador',// quitarlo si no hace falta
    expense:'Gastos',
    expenseTitle:'Gastos',
    addExpense: 'Añadir Gastos',
    mileageCompensation: 'Compensación de kilometraje',
    mileage:'Kilometraje',
    addMileageCompensation: 'Añadir compensación',
    passengers: 'Pasajeros',
    numberPassengers: 'Número de pasajeros',
    addCompensation: 'Añadir compensación',
    addPassengers: 'Agregar compañeros de viaje',
    addMileage: 'Agregar kilometraje',
    cancelPassenger: 'Cancelar pasajero',
    origin: 'Origen',
    destination: 'Destino',
    receipt: 'Recibo',
    programInvitation: 'Programa o Invitación',
    listParticipants: 'Lista de participantes',
    amount:'Importe (IVA incluido)',
    addReceipts:'Añadir Recibos',
    newReimbursementId: "Referencia del duplicado del reporte del proceso",
    deniedMessage: "Este Reporte del proceso se denego. Puedes crear un duplicado y editarlo",
    deniedMessageCreate: "Anterior Reporte del proceso fue denegado",
    sent: 'Enviado',
    draft: 'Borrador',
    passengersHelpText: 'Suma las distancias recorridas por cada copiloto e introduce',
  },
  honorarium: {
    honorarium:'Honorario',
    update: 'Acctualizar Honorario',
    create: 'Crear Honorario',
    annual: 'Honorario anual',
    payment: 'Intervalo de pago',
    first: 'Primer pago',
    queued: 'Lista de pagos',
    newHonorarium: 'Nuevo Honorario',
  },
  role:{
    userRole:'Rol del Usuario',
    orgRole:'Rol de la Organización',
    orgRoles:'Roles de la organización',
    orgType:'Tipo de Organización',
    roles:'Roles',
    permissions:'Permisos',
    infoAlert:' Recuerde presionar enter para insertar los permisos',
    addRole: 'Añadir Rol',
    addType:'Añadir Tipo',
    role:'Rol',
    theseAreThePermissions: 'Estos son los permisos posibles',
    explanation: 'Si seleciona la primera palabra, tiene permiso total. ("attest" es como "attest.approve" y "attest.view").Si añades los permisos de "create","update", "approve"," deny" de forma indvidial, tambien debes seleccionar la "view"'
  },
  userSetting: {
    settings:'Configuración',
    bankAccount:'Cueta Bancaria',
    modelClearingNumber:'Número de compensación',
    modelAccountNumber:'Número de cuenta',
  },
  digitalAccount:{
    label: 'Cuenta digital',
    list:'Movimientos',
    accountRowList:'Movimientos',
    accountRowInfo:'Información del movimiento',
    swedbankFee: 'Tasa Swedbank',
    totalBalance: 'Saldo Total de Cuentas Digitales',
    totalBalanceSearch: 'Saldo total de las cuentas buscadas',
  },
  attest: {
    attest:'Transacción',
    labelListPending:'Transacciones actuales pendientes',
    labelListPendingPrimary:'Mis transacciones pendientes',
    labelListHistory: 'Historial de transacciones',
    labelInfo:'Informacion de las transacciones',
    attested: 'Transacción',
    approved:'Aprobada',
    pending:'Pendiente',
    approve:'Aprobar',
    deny: "Denegar",
    denied: "Denegado",
    seniedBy: 'Denegado por',
    paymentInformation: 'Informacion de pago',
    recipientAccount: 'Cuenta del Beneficiario',
    paidDateTime: 'Fecha de Pago',
    notEnoughFunds: 'Fondos insuficientes',
    thisProceeding: 'Este procedimiento',
    missingFunds: 'Fondos faltantes',
    balanceAccount: 'Saldo de la cuenta',
  },
  dashboard: {
    dashboard: 'Pagina principal',
    bodyTitle: '¡Gracias por probar con nosotros nuestra nueva plataforma!',
    bodyText: 'Gracias por tomarse el tiempo para probarlo. Sus comentarios son importantes para que podamos corregir errores y mejorar la experiencia.',
  },
  exportSieFile: {
    label:'Exportar SIE',
    moddelExportDocument:'¿Desea previsualizar el archivo o exportarlo directamente?',
    modelPreview:'Previsualizar',
    modelErrorMessageExport:'No hay información para exportar en este intervalo de fechas.',
    periodNotFound: 'No hay datos en este período de tiempo.',
  },
  openPayment: {
    creditorName: 'Acreedor',
    creditorAccountIban: 'Iban del acreedor',
    creditorAccountBban: 'Bban del acreedor',
    bankTransfers: 'Transferencias bancarias',
    pendingBankList:'Lista de transferencias bancarias pendientes',
    historyBankList: 'Historial de transferencias bancarias',
    history: 'Historial',
    bulkPayment: 'Pago General',
    sign:'Firmar',
    instructedAmount:'Cantidad indicada',
    activeConsentCode: 'Tu código de consentimiento activo es',
    consentCodeNotFound: 'Código de consentimiento no encontrado',
    infoMessage: `Pasos para Autorizar y Activar una sesión de Consentimiento:\n 1. Haga clic en el botón "Renovar" para crear/renovar un Consentimiento si no tiene uno o ha caducado (primer paso).\n 2. Luego de hacer clic en el botón "Autorizar" (2do paso), se abrirá una página para verificar el usuario con BankId o similar, y finalmente serás redirigido a una página donde se activará el consentimiento.\n 3. Cuando haya terminado estos pasos, puede hacer clic en el botón "Actualizar transacciones".`,
    infoBankTransfer:'Información sobre transferencia bancaria',
    myItems: 'Mis elementos',
    otherItems: 'Elementos actuales',
  },
  internalTransaction: {
    internalTransaction: 'Transacción interna',
    create: 'Crear transacción interna',
    from:'Desde',
    to:'Para',
  },
  accessibility:{
    selectLanguage:'Selector de idioma',
    logo:'Logo kassan puedes ir a dashbord desde este link',
    goFirst:'ir a la primera página',
    goLast:'ir a la ultima página',
    prevPage:'página anterior',
    backPage: 'siguiente página',
    userOptions:'Opciones de usuario',
    registered:'Registrado',
  },
  invoice: {
    invoice: 'Factura',
    creditInvoice: 'Factura de credito',
    createInvoice: 'Crear factura',
    creditInvoiceRef: 'Referencia de factura de crédito',
    createCreditInvoice: 'Crear factura de credito',
    clientName: 'Nombre del cliente',
    invoiceList: 'Lista de facturas',
    addClient:'Añadir cliente',
    billingAddress: 'Dirección de Envio',
    invoiceRecipient: 'Destinatario de la factura',
    expiringDate: 'Fecha de vencimiento',
    date: 'Due date',
    recipientRef:'Referencia del destinatario',
    reference:'Referencia',
    invoiceRow: 'Líneas de factura',
    productName: 'Nombre/Descripción',
    quantity:'Cantidad',
    enterQuantity:'Introducir cantidad',
    unitPrice: 'Precio unitario (Sin IVA)',
    vatAmount: 'IVA',
    addInvoiceRow: 'Agregar línea de factura',
    draft:'Guardar como borrador',
    sendAttest: 'Enviar para certificar',
    previewInvoice: 'Vista previa',
    address: 'Dirección',
    postalCode:'Codigo postal',
    city: 'Ciudad',
    totalPriceRow: 'Cantidad de fila',
    changeStatus: 'Cambiar status',
    paid:'Pagado',
    sentInvoices: 'Facturas enviadas',
  },
  QRCode: {
    title: 'Titulo',
    name: 'Nombre interno para colecciones (opcional)',
    orgQRLabel: "Codigo QR",
    thereIsComment: 'Requerir que los pagadores ingresen un comentario',
    generate: 'Generar codigo QR',
    create: 'código QR para colecciones',
    totalPaid:'Total pagado',
    totalPayments:'Pagos totales',
    amount: 'Cantidad personalizada a contribuir',
    choosePaymentMethod: 'Elegir método de pago',
    label: 'Códigos QR para recaudación de fondos',
    totalCollected: 'Total recaudado',
    disable: 'Deshabilitar colección',
    enable: 'Habilitar colección',
    addCollection: 'Añadir colección',
    listActive: 'Lista QR activos',
    listInactive: 'Lista QR inactivos',
    defaultAmounts: 'Importe por defecto',
    defaultAmountsToContribute: 'Importe por defecto para contribuir',
    addCustomAmount: 'Añadir importe personalizado',
    paymentsReceived: 'Pagos recibidos',
  },
  infoBox: {
    internalTransaction: 'Här kan du föra över pengar mellan olika organisationer inom Digitala Kassan. En genomförd överföring äger rum omedelbart och syns på båda kontonas kontoutdrag. \nEn intern överföring skapar ingen verklig bokföring, och funktionen kan inte heller användas för att flytta pengar ut ur systemet.',
    reportProceeding: 'Här kan du lämna in ersättningsbegäran för arvode, bilersättning och utlägg du haft. \nEn inlämnad begäran attesteras av personal inom LRF och betalas ut löpande till det konto du angett. \nEn ersättningsbegäran registreras för den organisation du tillhör och blir en utgift där. \nOm du tillhör flera organisationer behöver du säkerställa att du lämnar in begäran för rätt organisation. \nOBS att moms på utlägg kommer inte belasta organisationens konto.',
    salarySheet: 'Här hittar du dina lönespecifikationer med information om den ersättning du fått utbetald. \nLönespecifikationer genereras i början av en månad och innehåller information om utbetalningar som genomförts föregående månad.',
    quickPayment: 'Här kan du skicka ut e-postmeddelande till en eller flera mottagare där du ber om pengar. \nMeddelandet innehåller en länk till Digitala Kassans betalsida, och de pengar som betalas in blir omedelbart tillgängliga på organisationens konto här i kassan. \nDenna funktion kan användas t ex för att enkelt ta in deltagaravgifter innan ett arrangemang ägt rum. \nOBS att beloppet innehåller moms samt 2,5% avgift för tjänsten. \nExempel: begär du 100 kr kommer 20 kr avgå i moms samt 2.50 kr i avgift, så organisationens kassa kommer öka med 77.50 kr ',
    qrPayment:'Här kan du skapa en QR-kod för insamling av pengar, till exempel för entréavgifter, parkering eller annat som görs på plats vid ett arrangemang. \nQR-koden leder till en betalsida där man sedan kan välja betalsätt (Swish eller kort) och du kan i förväg bestämma vilka belopp som ska vara möjliga att ange. \nOBS att det inte är en swish-kod utan man ska använda mobilkameran för att skanna. ',
    createInvoice: 'Här kan du skapa fakturor som skickas ut via e-post till den mottagare du anger. \nFakturan ställs ut med Lantbrukarnas Ekonomi AB som avsändare och behöver attesteras av personal inom LRF innan den skickas ut.',
    attestPrimary: "Aquí puede ver los casos que pertenecen a los centros de costos primarios que pertenecen a su usuario. Si algo no está bien, comuníquese con el soporte técnico.",
    attest: "Aquí puede ver los casos que pertenecen a su organización, pero no a ninguno de sus centros de costos principales. Si algo no está bien, comuníquese con el servicio de asistencia",
    duplicatePayment: 'Este pago ha sido eliminado de la cesta, ya que no está permitido firmar dos pagos con el mismo importe y número de cuenta, por favor realice este pago en otra sesta.',
    duplicatePaymentStatus: 'Este pago ha sido eliminado de la cesta, todavía hay un pago pendiente de firmar en la lista con el mismo creditor name, accounting iban y amount , por favor espere hasta que esté totalmente completado para que pueda añadirlo a una nueva cesta.'
},
  sidebar: {
    reimbursement:'Autorizaciones',
    salarySheet: 'Salario',
    digitalAccount:'Cuenta digital',
    payInvoice:'Factura de pago',
    collect:'Recolectar',
    quickPayments:'Pagos rapidos',
    QRcode: "Codigo QR",
    invoice:'Factura',
    orgUsers: 'Usuarios de la organización',
    orgDetails: 'Detalles de la organización',
    attest:'Transacción',
    allAccount: 'Todas las cuentas',
    allAttest: 'Todas las transacciones',
    internalTransaction:'Transacción interna',
    bankTransfers: 'Transferencias bancarias',
    sentInvoices: 'Facturas enviadas',
    honorarium:'Honorario',
    reports:'Informes',
    taxAuthority: "Reportes a la autoridad fiscal",
    exportSie:'Exportar SIE',
    org:'Organización',
    user: 'Usuarios',
    roles:'Roles',
    OPtransaction: 'Open Payment Transactions',
  }

};
