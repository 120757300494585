import React, {useEffect, useState} from 'react';
import {
    Card,
    Col,
    Row,
    Button, Spinner
} from "reactstrap";
import {CustomButton} from "../../Common/Components/Button/CustomButton";
import useApiCallService from "../../Common/Helpers/ApiCall";
import RequestReimbursementFixed from "../Models/Request/RequestReimbursementFixed"
import {CustomInput} from "../../Common/Components/Input/CustomInput";
import RequestReimbursementMileage from "../Models/Request/RequestReimbursementMileage";
import RequestReimbursementExpense from "../Models/Request/RequestReimbursementExpense";
import $ from 'jquery';
import {CustomSubmitButton} from "../../Common/Components/Input/CustomSubmitButton";
import ResponseReimbursementFixed from "../Models/Response/ResponseReimbursementFixed";
import ResponseReimbursementMileage from "../Models/Response/ResponseReimbursementMileage";
import ResponseReimbursementExpense from "../Models/Response/ResponseReimbursementExpense";
import RequestReimbursement from "../Models/Request/RequestReimbursement";
import ResponseReimbursement from "../Models/Response/ResponseReimbursement";
import {FlashMessage} from "../../Common/Helpers/FlashMessage";
import {Link, useNavigate} from "react-router-dom";
import {REPORT_PROCEEDING_PATHS, USER_PATHS} from "../../config";
import CostBearerModel from "../../CostBearer/Models/Response/CostBearerModel";
import {ReimbursementFixed} from "../Components/ReimbursementFixed";
import {ReimbursementMileage} from "../Components/ReimbursementMileage";
import {CustomModal} from "../../Common/Components/Modal/CustomModal";
import {ReimbursementExpense} from "../Components/ReimbursementExpense";
import {ChangeDateFormat} from "../../Common/Helpers/ChangeDateFormat";
import RequestReimbursementCommon from "../Models/Request/RequestReimbursementCommon";
import RequestPassenger from "../Models/Request/RequestPassenger";
import AccountingAccountModel from "../../Accounting/Models/Response/ResponseAccountingAccountModel";
import {StringToLowerCaseFirst} from "../../Common/Helpers/StringToLowerCaseFirst";
import {useTranslation} from "react-i18next";
import {CheckDecimal} from "../../Common/Helpers/CheckDecimal";
import {CustomUnsavedModal} from "../../Common/Components/Modal/CustomUnsavedModal";
import {ToCustomDecimal} from "../../AppSystem/Helpers/ToCustomDecimal";
import {CustomReimbursementView} from "../Components/CustomReimbursementView";
import {CustomReimbursementFixedView} from "../Components/CustomReimbursementFixedView";
import {CustomReimbursementMileageView} from "../Components/CustomReimbursementMileageView";
import {CustomReimbursementExpenseView} from "../Components/CustomReimbursementExpenseView";
import {ClearEmptyJson} from "../../AppSystem/Helpers/ClearEmptyJson";
import {CustomReload} from "../../AppSystem/Helpers/CustomReload";
import ResponseUserSettingsModel from "../../UserSettings/Model/Response/ResponseUserSettingsModel";
import {useSelector} from "react-redux";
import {CalculateTax} from "../../Common/Helpers/CalculateTax";
import {InputMessage} from "../../AppSystem/Helpers/InputMessage";
import {InfoPageMessage} from "../../Common/Components/Button/InfoPageMessage";
import {LoadingData} from "../../Common/Components/LoadingData";
import {CheckUserPermission} from "../../AppSystem/Helpers/CheckUserPermission";
import _ from "lodash";

export function ReportProceeding() {
    const { t } = useTranslation()
    const {ApiCall} = useApiCallService();
    const navigate = useNavigate();

    const { userData, orgData } = useSelector(state => state.auth);
    const taxPercent = CalculateTax(userData.pnr);

    // REQUESTS
    const [requestReimbursement, setRequestReimbursement] = useState(new RequestReimbursement());
    const [requestReimbursementFixed, setRequestReimbursementFixed] = useState(new RequestReimbursementFixed());
    const [requestReimbursementFixedIsFixed, setRequestReimbursementFixedIsFixed] = useState(new RequestReimbursementFixed());
    const [requestReimbursementFixedList, setRequestReimbursementFixedList] = useState([]);
    const [requestReimbursementMileage, setRequestReimbursementMileage] = useState(new RequestReimbursementMileage());
    const [requestReimbursementExpense, setRequestReimbursementExpense] = useState(new RequestReimbursementExpense());
    const [requestReimbursementExpenseList, setRequestReimbursementExpenseList] = useState([]);
    const [fileList, setFileList] = useState([]);

    // API RESPONSES
    const [responseReimbursement, setResponseReimbursement] = useState(new ResponseReimbursement());
    const [responseReimbursementFixedList, setResponseReimbursementFixedList] = useState([]);
    const [responseReimbursementFixed, setResponseReimbursementFixed] = useState(new ResponseReimbursementFixed());
    const [responseReimbursementMileage, setResponseReimbursementMileage] = useState(new ResponseReimbursementMileage());
    const [responseReimbursementExpense, setResponseReimbursementExpense] = useState(new ResponseReimbursementExpense());
    const [responseReimbursementExpenseList, setResponseReimbursementExpenseList] = useState([]);
    const [deniedReimbursement, setDeniedReimbursement] = useState(null);

    const [responseUserSettings, setResponseUserSettings] = useState(null);

    const [deleteApiResponse, setDeleteApiResponse] = useState(null);

    // EDIT MODE
    const [editModeFixed, setEditModeFixed] = useState(false);
    const [editModeMileage, setEditModeMileage] = useState(false);
    const [editModeExpense, setEditModeExpense] = useState(false);

    const [fileAttribute, setFileAttribute] = useState(false);

    // CHECK UNSAVED
    const [checkUnsaved, setCheckUnsaved] = useState(false);

    // ADDED STATES
    const [addedFixed, setAddedFixed] = useState(false);
    const [addedMileage, setAddedMileage] = useState(false);
    const [addedExpense, setAddedExpense] = useState(false);

    // STATES
    const [expenseIndex, setExpenseIndex] = useState(null);
    const [requestName , setRequestName] = useState("");
    const [requestName2 , setRequestName2] = useState("");
    const [fileType, setFileType] = useState("");
    const [extraField , setExtraField] = useState(null);
    const [extraField2 , setExtraField2] = useState(null);
    const [done, setDone] = useState(false);
    const [save, setSave] = useState(false);

    // ACCOUNTING RESPONSES
    const [accountingResponse, setAccountingResponse] = useState(null);
    const [aSettingResponse, setASettingResponse] = useState(null);
    const [aExtraResponse, setAExtraResponse] = useState(null);

    // VALIDATION SCHEMAS
    const completeSchemaFixed = RequestReimbursementFixed.getAttributes( null, true);
    const completeSchemaMileage = RequestReimbursementMileage.getAttributes(null, true);
    const completeSchemaPassenger = RequestPassenger.getAttributes(null, true);
    const completeSchemaExpense = RequestReimbursementExpense.getAttributes( null, true);
    const completeSchema = RequestReimbursement.getAttributes( null, true);
    const completeSchemaCommon = RequestReimbursementCommon.getAttributes( null, true);

    // COST BEARER
    const [costResponse, setCostResponse] = useState(null);
    const [costSelected, setCostSelected] = useState(null);
    const [costBearerIdDraft, setCostBearerIdDraft] = useState("");

    // ORG RESPONSE
    const [orgResponse, setOrgResponse] = useState(null);
    const [orgSelected, setOrgSelected] = useState(null);
    const [selectedOrgId, setSelectedOrgId] = useState(null);
    const [defaultOrg, setDefaultOrg] = useState(false);

    // FILE RESPONSE
    const [fileResponse, setFileResponse] = useState(null);

    // HOURLY SALARY RESPONSE
    const [hourlyResponse, setHourlyResponse] = useState(null);

    // PAYABLE RESPONSES
    const [payableResponse, setPayableResponse] = useState(null);
    const [payablePassengerResponse, setPayablePassengerResponse] = useState(null);

    // DISPLAY FORMS
    const [displayFixed, setDisplayFixed] = useState(true);
    const [displayMileage, setDisplayMileage] = useState(true);
    const [displayExpense, setDisplayExpense] = useState(true);

    // SPINNER
    const [displaySpinner, setDisplaySpinner] = useState(false);

    // MODAL OPTIONS
    const [displayDeleteModal, setDisplayDeleteModal] = useState(false);
    const [reimbursementToDelete, setReimbursementToDelete] = useState("");
    const [displaySendModal, setDisplaySendModal] = useState(false);
    const [displayDeleteModalReimbursement, setDisplayDeleteModalReimbursement] = useState(false);

    const [isDeletedFile, setIsDeletedFile] = useState(false);
    const [isDeletedExtraFile, setIsDeletedExtraFile] = useState(false);
    const [isFixed, setIsFixed] = useState(false);
    const [wasFixed, setWasFixed] = useState(false);
    const [deletedFixed, setDeletedFixed] = useState(false);

    // SELECTED CHECK COLUM
    const [selectedCheckColumn, setSelectedCheckColumn] = useState(false);

    // GROSS AMOUNT
    const [grossAmount, setGrossAmount] = useState(0);

    // BRANCH
    const [branch, setBranch] = useState("");

    // IS SEND
    const [isSend, setIsSend] = useState(false);

    let permissionCreate = false;
    if (CheckUserPermission("reimbursement") || CheckUserPermission("reimbursement.create")) {
        permissionCreate = true;
    }

    // GET ID IF EXISTS FOR DRAFT MODE
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id') ?? "";

    // GET REIMBURSEMENT FOR DRAFT MODE
    useEffect(() => {
        $("#submitReimbursement, #saveReimbursement").prop("disabled", true);
        if (id !== "") {
            $("#saveReimbursement").prop("disabled", false);
            const handleDraftResponse = (response) => {
                if (response !== null) {
                    setRequestReimbursement(new RequestReimbursement(response));
                    setCostBearerIdDraft(response.costBearerId);
                    setCostSelected(response.costBearerName);
                    if (response.fixeds && response.fixeds.length > 0) {
                        let thereIsFixed = false;
                        response.fixeds.map(item => {
                           if (item.type === "Fixed") {
                               setRequestReimbursementFixed(new RequestReimbursementFixed(item));
                               setRequestReimbursementFixedIsFixed(new RequestReimbursementFixed(item));
                               setIsFixed(true);
                               setWasFixed(true);
                               thereIsFixed = true;
                           }
                        });
                        if (response.toDate && response.toDate !== "") {
                            $("#checkInput").prop("checked", true);
                            setSelectedCheckColumn(true);
                        }
                        setTimeout(() => {
                            $("#date").prop("disabled", true);
                            $("#toDate").prop("disabled", true);
                            $("#checkInput").prop("disabled", true);
                        }, 250);
                        setDisplayFixed(false);
                        setEditModeFixed(false);
                        setAddedFixed(true);
                        if (thereIsFixed === false) {
                            const list = [];
                            response.fixeds.map(item => {
                                list.push(new RequestReimbursementFixed(item));
                            });
                            setRequestReimbursementFixed(new RequestReimbursementFixed(response.fixeds[0]));
                            setRequestReimbursementFixedList(list);
                        }
                    }
                    if (response.mileage) {
                        setDisplayMileage(false);
                        setEditModeMileage(false);
                        setAddedMileage(true);
                        setRequestReimbursementMileage(new RequestReimbursementMileage(response.mileage));
                    }
                    if (response.expenses && response.expenses.length > 0) {
                        setAddedExpense(true);
                        setEditModeExpense(false);
                        setRequestReimbursementExpenseList(response.expenses);
                    }
                }
            };
            const fetchData = async () => {
                try {
                    const apiCallOptions = {
                        method: "get",
                        url: `/reimbursement?id=${id}`
                    };
                    await ApiCall(apiCallOptions, handleDraftResponse);
                } catch (error) {
                    console.log('error', error);
                }
            };
            fetchData();
        }
    }, []);

    // CHECK ORG NAME
    useEffect(() => {
        if (defaultOrg === false && requestReimbursement.orgId === "") {
            setRequestReimbursement((prevFormData) => ({
                 ...prevFormData,
                 orgId: orgData.orgId,
            }));
            setRequestReimbursementFixed((prevFormData) => ({
                ...prevFormData,
                orgId: orgData.orgId,
            }));
            setRequestReimbursementMileage((prevFormData) => ({
                ...prevFormData,
                orgId: orgData.orgId,
            }));
            setRequestReimbursementExpense((prevFormData) => ({
                ...prevFormData,
                orgId: orgData.orgId,
            }));
        }
    }, [defaultOrg]);
    useEffect(() => {
        if (defaultOrg === false && orgResponse !== null && Array.isArray(orgResponse) && requestReimbursement.orgId !== "") {
            orgResponse.map(item => {
                if (item.id === requestReimbursement.orgId) {
                    setOrgSelected(item.name);
                    setSelectedOrgId(item.id);
                }
            });
            setDefaultOrg(true);
        }
    }, [orgResponse]);

    // GET REIMBURSEMENT IF THERE IS DENIED REIMBURSEMENT
    useEffect(() => {
        if (id !== "") {
            const handleDeniedResponse = (response) => {
                if (response !== null) {
                    setDeniedReimbursement(response);
                }
            };
            const fetchData = async () => {
                try {
                    const apiCallOptions = {
                        method: "get",
                        url: `/reimbursement/check-denied?id=${id}`
                    };
                    await ApiCall(apiCallOptions, handleDeniedResponse);
                } catch (error) {
                    //console.log('error', error);
                }
            };
            fetchData();
        }
    }, []);

    // CHECK SEND AND SAVE BUTTONS
    useEffect(() => {
        if ((addedFixed === true || addedMileage === true || addedExpense === true) && requestReimbursement.date !== "" && requestReimbursement.purpose !== "" && requestReimbursement.costBearerId !== "" && requestReimbursement.orgId !== "") {
            $("#submitReimbursement, #saveReimbursement").prop("disabled", false);
        } else {
            $("#submitReimbursement").prop("disabled", true);
        }
        if (addedFixed === false && addedMileage === false && addedExpense === false) {
            $("#submitReimbursement").prop("disabled", true);
        }
    }, [addedFixed, addedMileage, addedExpense, requestReimbursement.date, requestReimbursement.purpose, requestReimbursement.costBearerId, requestReimbursement.orgId]);

    // CHECK IF THERE ARE MORE THAN ONE FIXED
    useEffect(() => {
        if (requestReimbursement.date && requestReimbursement.date !== "" || deletedFixed === true) {
                // DATE AND TODATE
                if (requestReimbursement.toDate && requestReimbursement.toDate !== "") {
                    // FORMAT DATES
                    const dateFormated = new Date(requestReimbursement.date);
                    const toDateFormated = new Date(requestReimbursement.toDate);

                    // DIFFERENCE IN SECONDS
                    const secondsDifference = toDateFormated.getTime() - dateFormated.getTime();

                    // GET DAYS
                    const days = (secondsDifference / (1000 * 60 * 60 * 24)) + 1;

                    // CHECK IF DAY IS POSITIVE
                    if (days > 0) {
                        // MULTIPLE
                        if (days > 1) {
                            // IF THERE ARE MORE FIXEDS THAN DAYS, DELETE THE OUT OF RANGE
                            if (requestReimbursementFixedList.length > days) {
                                // HIDE TOOLTIP ERROR
                                $('#ErrorFormDate').addClass('d-none');
                                setRequestReimbursementFixedList(requestReimbursementFixedList.filter(item => {
                                    const itemSalaryDate = new Date(item.salaryDate);
                                    if (itemSalaryDate < dateFormated) {
                                        // FIT
                                        //item.salaryDate = itemDate;
                                        // DELETE
                                        return false;
                                    }
                                    if (itemSalaryDate > toDateFormated) {
                                        // FIT
                                        //item.salaryDate = itemToDate;
                                        // DELETE
                                        return false;
                                    }
                                    return true;
                                }));
                            }
                            // IF THERE ARE NO FIXEDS CREATE NUMBER OF DAYS
                            else if (requestReimbursementFixedList.length === 0) {
                                // HIDE TOOLTIP ERROR
                                $('#ErrorFormDate').addClass('d-none');
                                const newFixeds = [];
                                for (let i = 0; i < days - 1; i++) {
                                    const tempReimbursement = _.cloneDeep(requestReimbursementFixed);
                                    const newSalaryDate = new Date(dateFormated);
                                    newSalaryDate.setDate(newSalaryDate.getDate() + i);
                                    tempReimbursement.id = null;
                                    tempReimbursement.salaryDate = ChangeDateFormat("date", newSalaryDate);
                                    tempReimbursement.hours = 0;
                                    tempReimbursement.amount = 0;
                                    tempReimbursement.type = "Hourly";
                                    newFixeds.push(tempReimbursement);
                                }
                                setRequestReimbursementFixedList((prevFormData) => [
                                    ...prevFormData,
                                    ...newFixeds
                                ]);
                            }
                            // IF THERE ARE LESS FIXEDS THAN DAYS, ADD THE MISSING
                            else if (requestReimbursementFixedList.length < days) {
                                // HIDE TOOLTIP ERROR
                                $('#ErrorFormDate').addClass('d-none');
                                const existingDates = requestReimbursementFixedList.map(item => new Date(item.salaryDate));
                                existingDates.sort((a, b) => a - b);
                                const lastDate = existingDates[existingDates.length - 1];
                                const firstDate = existingDates[0];
                                const missingFixeds = days - requestReimbursementFixedList.length;
                                const newFixeds = [];
                                if (dateFormated < firstDate) {
                                    for (let i = 1; i <= missingFixeds; i++) {
                                        const tempReimbursement = _.cloneDeep(requestReimbursementFixed);
                                        const newSalaryDate = new Date(firstDate);
                                        newSalaryDate.setDate(newSalaryDate.getDate() - i);
                                        tempReimbursement.id = null;
                                        tempReimbursement.salaryDate = ChangeDateFormat("date", newSalaryDate);
                                        tempReimbursement.hours = 0;
                                        tempReimbursement.amount = 0;
                                        tempReimbursement.type = "Hourly";
                                        // ADD FIRST
                                        newFixeds.unshift(tempReimbursement);
                                    }
                                } else if (toDateFormated > lastDate) {
                                    for (let i = 1; i <= missingFixeds; i++) {
                                        const tempReimbursement = _.cloneDeep(requestReimbursementFixed);
                                        const newSalaryDate = new Date(lastDate);
                                        newSalaryDate.setDate(newSalaryDate.getDate() + i);
                                        tempReimbursement.id = null;
                                        tempReimbursement.salaryDate = ChangeDateFormat("date", newSalaryDate);
                                        tempReimbursement.hours = 0;
                                        tempReimbursement.amount = 0;
                                        tempReimbursement.type = "Hourly";
                                        // ADD LAST
                                        newFixeds.push(tempReimbursement);
                                    }
                                }
                                setRequestReimbursementFixedList((prevFormData) => [
                                    ...prevFormData,
                                    ...newFixeds
                                ]);
                            }
                        }
                        // IF ONY HAVE 1 DAY
                        else {
                            // HIDE TOOLTIP ERROR
                            $('#ErrorFormDate').addClass('d-none');
                            let foundFirst = false;
                            setRequestReimbursementFixedList(requestReimbursementFixedList.filter(item => {
                                if (foundFirst === false) {
                                    item.salaryDate = requestReimbursement.date;
                                    foundFirst = true;
                                    return true;
                                }
                                return false;
                            }));
                        }
                    }
                    // SHOW TOOLTIP ERROR
                    else {
                        $('#ErrorFormDate').removeClass('d-none');
                    }
                }
                // ONLY DATE
                else {
                    // IF THERE ARE MORE FIXEDS THAN 1 DELETE THE OUT OF RANGE
                    if (requestReimbursementFixedList.length > 0) {
                        // HIDE TOOLTIP ERROR
                        $('#ErrorFormDate').addClass('d-none');
                        let foundFirst = false;
                        setRequestReimbursementFixedList(requestReimbursementFixedList.filter(item => {
                            if (foundFirst === false) {
                                item.salaryDate = requestReimbursement.date;
                                foundFirst = true;
                                return true;
                            }
                            return false;
                        }));
                    }
                    // IF THERE ARE NO FIXEDS CREATE ONE
                    else {
                        // HIDE TOOLTIP ERROR
                        $('#ErrorFormDate').addClass('d-none');
                        setRequestReimbursementFixedList([requestReimbursementFixed]);
                    }
                }
                // UPDATE DELETE FIXED
                if (deletedFixed === true) {
                    setDeletedFixed(false);
                }
            }
    }, [requestReimbursement.date, requestReimbursement.toDate, deletedFixed]);

    // API CALL TO GET COST BEARER
    useEffect(() => {
        if (selectedOrgId !== null) {
            const handleCostResponse = (costResponse) => {
                if (costResponse !== null && costResponse.length > 0) {
                    if (costSelected !== null) {
                        let found = false;
                        costResponse.map(item => {
                            if (item.name === costSelected) {
                                found = true;
                            }
                        })
                        if (found === false) {
                            setCostSelected(null);
                            setRequestReimbursement((prevFormData) => ({
                                ...prevFormData,
                                costBearerId: "",
                            }));
                            setRequestReimbursementFixed((prevFormData) => ({
                                ...prevFormData,
                                costBearerId: "",
                            }));
                            setRequestReimbursementMileage((prevFormData) => ({
                                ...prevFormData,
                                costBearerId: "",
                            }));
                            setRequestReimbursementExpense((prevFormData) => ({
                                ...prevFormData,
                                costBearerId: "",
                            }));
                            if (requestReimbursementFixedList.length > 0) {
                                requestReimbursementFixedList.map(item => {
                                    item["costBearerId"] = "";
                                });
                                setRequestReimbursementFixedList(requestReimbursementFixedList);
                            }
                            if (requestReimbursementExpenseList.length > 0) {
                                requestReimbursementExpenseList.map(item => {
                                    item["costBearerId"] = "";
                                });
                                setRequestReimbursementExpenseList(requestReimbursementExpenseList);
                            }
                        }
                    }
                } else {
                    setCostSelected(null);
                    setRequestReimbursement((prevFormData) => ({
                        ...prevFormData,
                        costBearerId: "",
                    }));
                    setRequestReimbursementFixed((prevFormData) => ({
                        ...prevFormData,
                        costBearerId: "",
                    }));
                    setRequestReimbursementMileage((prevFormData) => ({
                        ...prevFormData,
                        costBearerId: "",
                    }));
                    setRequestReimbursementExpense((prevFormData) => ({
                        ...prevFormData,
                        costBearerId: "",
                    }));
                    if (requestReimbursementFixedList.length > 0) {
                        requestReimbursementFixedList.map(item => {
                            item["costBearerId"] = "";
                        });
                        setRequestReimbursementFixedList(requestReimbursementFixedList);
                    }
                    if (requestReimbursementExpenseList.length > 0) {
                        requestReimbursementExpenseList.map(item => {
                            item["costBearerId"] = "";
                        });
                        setRequestReimbursementExpenseList(requestReimbursementExpenseList);
                    }
                }
                setCostResponse(costResponse);
            };
            const CostApiCall = async () => {
                try {
                    const apiCallOptions = {
                        method: "get",
                        url: "/costbearer/list-org?id=" + selectedOrgId,
                    };
                    await ApiCall(apiCallOptions, handleCostResponse);
                } catch (error) {
                    console.log('error', error);
                }
            };
            CostApiCall();
        }
    }, [selectedOrgId]);

    // API CALL TO GET HOURLY SALARY
    useEffect(() => {
        const handleHourlyResponse  = (hourlyResponse) => {
            setHourlyResponse(hourlyResponse);
        };
        const HourlyApiCall = async () => {
            try {
                const apiCallOptions = {
                    method: "get",
                    url: "/systemsetting?id=HourlySalary",
                };
                await ApiCall(apiCallOptions, handleHourlyResponse);
            } catch (error) {
                console.log('error', error);
            }
        };
        HourlyApiCall();
    }, []);

    // API CALL TO GET ACCOUNTING
    useEffect(() => {
        const handleAccountingResponse  = (accountingResponse) => {
            setAccountingResponse(accountingResponse);
        };
        const AccountingApiCall = async () => {
            try {
                const apiCallOptions = {
                    method: "get",
                    url: "/accounting/list",
                };
                await ApiCall(apiCallOptions, handleAccountingResponse);
            } catch (error) {
                console.log('error', error);
            }
        };
        AccountingApiCall();
    }, []);

    // API CALL TO GET PAYABLE
    useEffect(() => {
        const handlePayable = (payableResponse) => {
            setPayableResponse(payableResponse);
        };
        const PayableApiCall = async () => {
            try {
                const apiCallOptions = {
                    method: "get",
                    url: "/systemsetting?id=PayableTravelPer10Km",
                };
                await ApiCall(apiCallOptions, handlePayable);
            } catch (error) {
                console.log('error', error);
            }
        };
        PayableApiCall();
    }, []);

    // API CALL TO GET PAYABLE PASSENGER
    useEffect(() => {
        const handlePayablePassenger = (payablePassengerResponse) => {
            setPayablePassengerResponse(payablePassengerResponse);
        };
        const PassengerApiCall = async () => {
            try {
                const apiCallOptions = {
                    method: "get",
                    url: "/systemsetting?id=PayablePassangerTravelPer10Km",
                };
                await ApiCall(apiCallOptions, handlePayablePassenger);

            } catch (error) {
                console.log('error', error);
            }
        };
        PassengerApiCall();
    }, []);

    // API CALL TO GET ACCOUNTING SETTINGS
    useEffect(() => {
        if (requestReimbursementExpense.accountingId !== "") {
            const ASettingApiCall = async () => {
                try {
                    const apiCallOptions = {
                        method: "get",
                        url: `/accounting/setting?accountingId=${requestReimbursementExpense.accountingId}&name=iva`,
                    };
                    await ApiCall(apiCallOptions, setASettingResponse);
                } catch (error) {
                    console.log('error', error);
                }
            };
            ASettingApiCall();
        }
    }, [requestReimbursementExpense.accountingId]);

    // API CALL TO GET ACCOUNTING EXTRA
    useEffect(() => {
        if (requestReimbursementExpense.accountingId !== "") {
            const AExtraApiCall = async () => {
                try {
                    const apiCallOptions = {
                        method: "get",
                        url: `/accounting/extra?accountingId=${requestReimbursementExpense.accountingId}`,
                    };
                    await ApiCall(apiCallOptions, setAExtraResponse);
                } catch (error) {
                    console.log('error', error);
                }
            };
            AExtraApiCall();
        }
    }, [requestReimbursementExpense.accountingId]);

    // API CALL TO GET ORGANIZATIONS
    useEffect(() => {
        const OrgApiCall = async () => {
            const branchInfo = await fetch('/branch-info.json');
            const data = await branchInfo.json();
            const currentBranch = data.branch;
            setBranch(currentBranch);
            try {
                const apiCallOptions = {
                    method: "get",
                    url: "/org/list-dropdown?userId=" + userData.id + "&list=true" + (currentBranch.includes("develop") ? "" : "&leab=false"),
                };
                await ApiCall(apiCallOptions, setOrgResponse);
            } catch (error) {
                console.log('error', error);
            }
        };
        OrgApiCall();
    }, []);

    let orgJson = {};
    if (orgResponse !== null && Array.isArray(orgResponse)) {
        const orgArray = [];
        orgResponse.map(item => {
            orgArray.push({
                id: item.id,
                name: item.name,
            });
        });
        orgJson = {
            org: orgArray
        };
    }

    let costJson = {};
    if (costResponse !== null && Array.isArray(costResponse) && costResponse.length > 0) {
        const costBearerArray = [];
        costResponse.map(item => {
            const cosBearerModel = new CostBearerModel(item);
            costBearerArray.push(cosBearerModel);
        });
        costJson = {
            costBearer: costBearerArray
        };
    }

    let accountingJson = {};
    if (accountingResponse !== null && Array.isArray(accountingResponse)) {
        const accountingArray = [];
        accountingResponse.map(item => {
            const accountingModel = new AccountingAccountModel(item);
            accountingArray.push(accountingModel);
        });
        accountingJson = {
            accounting: accountingArray,
        };
    }

    // SUBMIT FORMS
    const SubmitFixed = async (type = null) => {
        const handleResponseReimbursementFixed = (fixed) => {
            setResponseReimbursementFixed(fixed);
            if (fixed !== null && fixed.id !== "") {
                setResponseReimbursementFixedList((prevFormData) => ([
                    ...prevFormData,
                    fixed.id
                ]));
            }
        };
        if (isFixed === true) {
            setRequestReimbursementExpenseList([requestReimbursementFixedIsFixed]);
        }
        requestReimbursementFixedList.sort((a, b) => new Date(a.salaryDate) - new Date(b.salaryDate));
        if (requestReimbursementFixedList.length > 0) {
            requestReimbursementFixedList.map(async (item) => {
                if (type != null) {
                    if (JSON.stringify(item) !== JSON.stringify(new RequestReimbursementFixed())) {
                        item.status = "Förslag";
                    }
                } else {
                    if (addedFixed === true) {
                        item.status = "Skickat";
                    }
                }
                const tempItem = ClearEmptyJson(item);
                if (isFixed !== true) {
                    if (tempItem.hours === 0 || tempItem.hours === undefined || tempItem.hours === null || tempItem.hours === "") {
                        tempItem.hours = 0;
                    }
                }
                if (tempItem.amount === 0 || tempItem.amount === undefined || tempItem.amount === null || tempItem.amount === "") {
                    tempItem.amount = 0;
                }
                const apiCallOptions = {
                    method: "post",
                    url: "/reimbursement/fixed",
                    data: tempItem,
                };
                try {
                    await ApiCall(apiCallOptions, handleResponseReimbursementFixed);
                } catch (error) {
                    console.log(error);
                    FlashMessage(t('validationMessage.sendError'), 'flash-messageError');
                }
            });
        }
    };

    const SubmitMileage = async (type = null) => {
        if (type != null) {
            if (JSON.stringify(requestReimbursementMileage) !== JSON.stringify(new RequestReimbursementMileage())) {
                requestReimbursementMileage.status = "Förslag";
            }
        } else {
            if (addedMileage === true) {
                requestReimbursementMileage.status = "Skickat";
            }
        }
        const apiCallOptions = {
            method: "post",
            url: "/reimbursement/mileage",
            data: ClearEmptyJson(requestReimbursementMileage),
        };
        try {
            await ApiCall(apiCallOptions, setResponseReimbursementMileage);
        } catch (error) {
            console.log(error);
            FlashMessage(t('validationMessage.sendError'), 'flash-messageError');
        }
    };

    const SubmitExpense = async (type = null) => {
        const handleResponseReimbursementExpense = (expense) => {
            setResponseReimbursementExpense(expense);
            if (expense !== null && expense.id !== "") {
                setResponseReimbursementExpenseList((prevFormData) => ([
                    ...prevFormData,
                    expense.id
                ]));
            }
        };
        if (requestReimbursementExpenseList.length > 0) {
            requestReimbursementExpenseList.map(async (item) => {
                if (type != null) {
                    if (JSON.stringify(item) !== JSON.stringify(new RequestReimbursementExpense())) {
                        item.status = "Förslag";
                    }
                } else {
                    if (addedExpense === true) {
                        item.status = "Skickat";
                    }
                }
                const finalItem = ClearEmptyJson(item);
                if (item.vatAmount !== null && item.vatAmount === 0) {
                    finalItem.vatAmount = 0;
                }
                const apiCallOptions = {
                    method: "post",
                    url: "/reimbursement/expense",
                    data: finalItem,
                };
                try {
                    await ApiCall(apiCallOptions, handleResponseReimbursementExpense);
                } catch (error) {
                    console.log(error);
                    FlashMessage(t('validationMessage.sendError'), 'flash-messageError');
                }
            });
        }
    };

    // REDIRECT WHEN FINISH
    useEffect(() => {
        if (save === true) {
            FlashMessage(t('validationMessage.reimbursementSavedSuccessfully'), 'flash-messageSuccess');
            setTimeout(() => {
                CustomReload(REPORT_PROCEEDING_PATHS.REPORT_PROCEEDING_LIST)
            }, 2000);
        }
        else {
            if (responseReimbursement && responseReimbursement.id !== "") {
                FlashMessage(t('validationMessage.reimbursementSuccessfully'), 'flash-messageSuccess');
                setTimeout(() => {
                    CustomReload(REPORT_PROCEEDING_PATHS.REPORT_PROCEEDING_LIST);
                }, 2000);
            }
        }
    }, [responseReimbursement]);

    // UPDATE REQUEST REIMBURSEMENT
    useEffect(() => {
        let fixedIds = null;
        if (responseReimbursementFixedList.length > 0) {
            fixedIds = responseReimbursementFixedList;
        }
        setRequestReimbursement((prevFormData) => ({
            ...prevFormData,
            fixedIds: fixedIds,
        }));
    }, [responseReimbursementFixedList]);

    useEffect(() => {
        let mileageId = null;
        if (responseReimbursementMileage && responseReimbursementMileage.id !== "") {
            mileageId = responseReimbursementMileage.id;
        }
        setRequestReimbursement((prevFormData) => ({
            ...prevFormData,
            mileageId: mileageId,
        }));
    }, [responseReimbursementMileage]);

    useEffect(() => {
        let expenseIds = null;
        if (responseReimbursementExpenseList.length > 0) {
            expenseIds = responseReimbursementExpenseList;
        }
        setRequestReimbursement((prevFormData) => ({
            ...prevFormData,
            expenseIds: expenseIds,
        }));
    }, [responseReimbursementExpenseList]);

    // FINAL SUBMIT
    useEffect(() => {
        if (done === true) {
            const SubmitApiCall = async () => {
                if (save === true) {
                    requestReimbursement.status = "Förslag";
                } else {
                    requestReimbursement.status = "Skickat";
                }
                if (save === false && grossAmount <= 0) {
                    FlashMessage(t('validationMessage.totalAmountMustBeMoreThan0'), 'flash-messageError');
                    return;
                }
                const apiCallOptions = {
                    method: "post",
                    url: "/reimbursement",
                    data: ClearEmptyJson(requestReimbursement),
                };
                try {
                    if (save === true) {
                        await completeSchemaCommon.validate(requestReimbursement, {abortEarly: false});
                    } else {
                        await completeSchema.validate(requestReimbursement, {abortEarly: false});
                    }
                    await ApiCall(apiCallOptions, setResponseReimbursement);
                } catch (error) {
                    console.log(error);
                    FlashMessage(t('validationMessage.sendError'), 'flash-messageError');
                }
            }
            SubmitApiCall();
        }
    }, [done]);

    // HANDLE FILE
    const HandleUploadFile = async (e, setFileResponse, fileInfo) => {
        const formData = new FormData();
        let isValidFile = false;
        e.map(item => {
            if (item.type === 'image/png' || item.type === 'image/jpg' || item.type === 'image/gif' || item.type === 'image/jpeg' || item.type === "application/pdf" || item.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || item.type === "application/msword" || item.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || item.type === "application/vnd.ms-excel" || item.type === "text/plain") {
                formData.append('file', item);
                isValidFile = true;
                if (fileInfo === "fileId") {
                    setRequestReimbursementExpense((prevFormData) => ({
                        ...prevFormData,
                        fileExtension: item.type,
                        fileName: item.name,
                    }));
                } else {
                    setRequestReimbursementExpense((prevFormData) => ({
                        ...prevFormData,
                        additionalFileExtension: item.type,
                        additionalFileName: item.name,
                    }));
                }
            }
        })
        if (isValidFile) {
            const apiCallOptions = {
                method: "post",
                url: "/file",
                data: formData,
                dataTypes: "file"
            };
            try {
                await ApiCall(apiCallOptions, setFileResponse);
            } catch (error) {
                console.log('error', error);
            }
        } else {
            setFileResponse(null);
        }
    };

    /*const HandleDeleteFile = async (fileId) => {
        const [test, setTest] = useState(null);
        const apiCallOptions = {
            method: "delete",
            url: `/file?id=${fileId}`,
        };

        try {
            await ApiCall(apiCallOptions, setTest);
            setFileResponse(null);
        } catch (error) {
            console.log(error);
        }
    }*/

    // HANDLE CHANGES
    const HandleChangeCommon = (e, name) => {
        setCheckUnsaved(true);
        $("#saveReimbursement").prop("disabled", false);
        let value;
        let salaryDateValue = requestReimbursementFixed.salaryDate;
        let dateReceiveValue = requestReimbursementExpense.dateReceive;
        switch (name) {
            case "orgId":
                if (orgResponse !== null && Array.isArray(orgResponse)) {
                    orgResponse.map(item => {
                        if (item.id === e.target.value) {
                            setOrgSelected(item.name);
                            setSelectedOrgId(item.id);
                        }
                    });
                    value = e.target.value;
                }
                break;
            case "costBearerId":
                if (costResponse !== null && Array.isArray(costResponse) && costResponse.length > 0) {
                    costResponse.map(item => {
                        if (item.id === e.target.value) {
                            setCostSelected(item.name);
                        }
                    });
                    value = e.target.value;
                } else {
                    value = "";
                    setCostSelected(null);
                }
                break;
            case "date":
                if (e !== null && e !== '') {
                    value = ChangeDateFormat('date', e);
                }
                if (requestReimbursementExpense.dateReceive === "") {
                    dateReceiveValue = value;
                }
                salaryDateValue = value;
                break;
            case "toDate":
                if (e !== null && e !== '') {
                    value = ChangeDateFormat('date', e);
                }
                break;
            default:
                value = e.target.value;
                break;
        }
        setRequestReimbursement((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
        setRequestReimbursementFixed((prevFormData) => ({
            ...prevFormData,
            [name]: value,
            salaryDate: salaryDateValue,
        }));
        setRequestReimbursementMileage((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
        setRequestReimbursementExpense((prevFormData) => ({
            ...prevFormData,
            [name]: value,
            dateReceive: dateReceiveValue,
        }));
        if (requestReimbursementFixedList.length > 0) {
            requestReimbursementFixedList.map(item => {
                item[name] = value;
            });
            setRequestReimbursementFixedList(requestReimbursementFixedList);
        }
        if (requestReimbursementExpenseList.length > 0) {
            requestReimbursementExpenseList.map(item => {
                item[name] = value;
            });
            setRequestReimbursementExpenseList(requestReimbursementExpenseList);
        }
    };

    const HandleChangeFixed = (e, name, index = null, isFixed = null) => {
        setCheckUnsaved(true);
        setIsFixed(false);
        let hoursValue = null;
        let typeValue = null;
        let amountValue = null;
        if (index !== null) {
            hoursValue = requestReimbursementFixedList[index].hours;
            typeValue = requestReimbursementFixedList[index].type;
            amountValue = requestReimbursementFixedList[index].amount;
        } else if (isFixed !== null) {
            setIsFixed(true);
            hoursValue = requestReimbursementFixedIsFixed.hours;
            typeValue = requestReimbursementFixedIsFixed.type;
            amountValue = requestReimbursementFixedIsFixed.amount;
        }
        switch (name) {
            case "hours":
                typeValue = "Hourly";
                hoursValue = 0;
                amountValue = 0;
                if (e && e.target && e.target.value !== "") {
                    hoursValue = CheckDecimal(e.target.value);
                    if (hourlyResponse !== null) {
                        amountValue = (hoursValue <= 14 ? hoursValue : 14) * hourlyResponse.value;
                    }
                }
                break;
            case "amount":
                typeValue = "Fixed";
                hoursValue = null;
                amountValue = 0;
                if (e && e.target && e.target.value !== "") {
                    amountValue = CheckDecimal(e.target.value);
                }
                break;
            case "fixed":
                setIsFixed(true);
                typeValue = "Fixed";
                hoursValue = null;
                amountValue = 0;
                break;
            case "hourly":
                typeValue = "Hourly";
                if (requestReimbursementFixed.hours !== null && requestReimbursementFixed.hours !== 0) {
                    hoursValue = 0;
                    amountValue = 0;
                }
                break;
        }
        if (typeValue !== "") {
            if (index !== null) {
                const tempList = [...requestReimbursementFixedList];
                tempList[index] = { ...tempList[index], hours: hoursValue, type: typeValue, amount: amountValue };
                setRequestReimbursementFixedList(tempList);
            } else if (isFixed !== null) {
                const tempFixed = requestReimbursementFixed;
                tempFixed.hours = hoursValue;
                tempFixed.type = typeValue;
                tempFixed.amount = amountValue;
                setRequestReimbursementFixed(tempFixed);
                setRequestReimbursementFixedIsFixed(tempFixed);
            }
        }
    };

    const HandleChangeMileage = (e, name) => {
        setCheckUnsaved(true);
        let fromValue = requestReimbursementMileage.from;
        let toValue = requestReimbursementMileage.to;
        let kmValue = requestReimbursementMileage.km;
        let amountValue = requestReimbursementMileage.amount;
        let passengersValue = requestReimbursementMileage.passengers !== null && requestReimbursementMileage.passengers.length > 0 ? requestReimbursementMileage.passengers : null;
        let quantityPassengerValue = 0;
        let kmPassengerValue = 0;
        let amountPassengerValue = 0;
        if (requestReimbursementMileage.passengers !== null && requestReimbursementMileage.passengers.length > 0) {
            quantityPassengerValue = requestReimbursementMileage.passengers[0].quantity;
            kmPassengerValue = requestReimbursementMileage.passengers[0].km;
            amountPassengerValue = requestReimbursementMileage.passengers[0].amount;
        }
        let totalAmountValue = requestReimbursementMileage.totalAmount;
        switch (name) {
            case "from":
                fromValue = e.target.value;
                break;
            case "to":
                toValue = e.target.value;
                break;
            case "km":
                kmValue = 0;
                amountValue = 0;
                if (e && e.target && e.target.value !== "") {
                    kmValue = CheckDecimal(e.target.value);
                    if (payableResponse !== null) {
                        const calculateAmount = (kmValue * payableResponse.value) / 10;
                        amountValue = parseFloat(calculateAmount.toFixed(2));
                    }
                }
                break;
            case "addPassenger":
                passengersValue = [];
                break;
            case "cancelPassenger":
                passengersValue = null;
                quantityPassengerValue = 0;
                kmPassengerValue = 0;
                break;
            case "quantityPassenger":
                quantityPassengerValue = 0;
                if (e && e.target && e.target.value !== "") {
                    quantityPassengerValue = CheckDecimal(e.target.value);
                }
                break;
            case "kmPassenger":
                kmPassengerValue = 0;
                if (e && e.target && e.target.value !== "") {
                    kmPassengerValue = CheckDecimal(e.target.value);
                }
                break;
        }

        totalAmountValue = amountValue;
        if (passengersValue !== null && payablePassengerResponse !== null) {
            const calculateAmount = kmPassengerValue * payablePassengerResponse.value;
            let totalAmount = calculateAmount / 10;
            amountPassengerValue = parseFloat(totalAmount.toFixed(2));
            totalAmountValue = amountValue + amountPassengerValue;
            passengersValue[0] = {
                quantity: quantityPassengerValue,
                km: kmPassengerValue,
                amount: amountPassengerValue,
            };
        }
        setRequestReimbursementMileage((prevFormData) => ({
            ...prevFormData,
            from: fromValue,
            to: toValue,
            km: kmValue,
            amount: amountValue,
            passengers: passengersValue,
            totalAmount: totalAmountValue,
        }));
    };

    const HandleChangeExpense = async (e, name, extraFieldName = null) => {
        setCheckUnsaved(true);
        let dateReceiveValue = requestReimbursementExpense.dateReceive;
        let amountValue = requestReimbursementExpense.amount;
        let vatAmountValue = requestReimbursementExpense.vatAmount;
        let accountingIdValue = requestReimbursementExpense.accountingId;
        let programValue = requestReimbursementExpense.additionalInfo && Object.keys(requestReimbursementExpense.additionalInfo).length > 0 && requestReimbursementExpense.additionalInfo.program && requestReimbursementExpense.additionalInfo.program !== "" ? requestReimbursementExpense.additionalInfo.program : "";
        switch (name) {
            case "fileId":
                HandleUploadFile(e, setFileResponse, "fileId");
                setFileType("fileId");
                setFileAttribute(false);
                break;
            case "dateReceive":
                dateReceiveValue = ChangeDateFormat('date', e);
                break;
            case "amount":
                amountValue = 0;
                if (e && e.target && e.target.value !== "") {
                    amountValue = CheckDecimal(e.target.value);
                }
                break;
            case "vatAmount":
                vatAmountValue = 0;
                if (e && e.target && e.target.value !== "") {
                    vatAmountValue = CheckDecimal(e.target.value);
                }
                break;
            case "accountingId":
                if (accountingResponse !== null) {
                    accountingIdValue = e.target.value;
                }
                break;
            case "dynamic":
                if (e && Array.isArray(e)) {
                    if (extraFieldName !== null) {
                        setFileType(extraFieldName);
                    }
                    HandleUploadFile(e, setFileResponse, "dynamic");
                } else {
                    programValue = e.target.value;
                    setRequestReimbursementExpense((prevFormData) => ({
                        ...prevFormData,
                        additionalInfo: {
                            ...prevFormData.additionalInfo,
                            program: programValue
                        }
                    }));
                }
                break;
        }
        setRequestReimbursementExpense((prevFormData) => ({
            ...prevFormData,
            dateReceive: dateReceiveValue,
            amount: amountValue,
            vatAmount: vatAmountValue,
            accountingId: accountingIdValue,
        }));
    };

    // UPDATE VAT AMOUNT
    useEffect(() => {
        let vatAmountValue = requestReimbursementExpense.vatAmount;
        if (aSettingResponse !== null && aSettingResponse.value === "false") {
            vatAmountValue = null;
        } else {
            vatAmountValue = 0;
        }
        setRequestReimbursementExpense((prevFormData) => ({
            ...prevFormData,
            vatAmount: vatAmountValue,
        }));
    }, [aSettingResponse]);

    // UPDATE ADDITIONAL INFO
    useEffect(() => {
        let additionalInfoValue = requestReimbursementExpense.additionalInfo;
        if (aExtraResponse !== null && aExtraResponse.extraFields && aExtraResponse.extraFields.length > 0) {
            const requestNameLower = StringToLowerCaseFirst(aExtraResponse.extraFields[0].requestName);
            additionalInfoValue = {
                [requestNameLower]: requestReimbursementExpense.additionalInfo !== null && Object.keys(requestReimbursementExpense.additionalInfo).length > 0 && requestReimbursementExpense.additionalInfo[requestNameLower] && requestReimbursementExpense.additionalInfo[requestNameLower] !== "" ? requestReimbursementExpense.additionalInfo[requestNameLower] : "",
            };
            setRequestName(requestNameLower);
            setExtraField(aExtraResponse.extraFields[0]);
            // IF EXTRA FIELDS ARE MORE THAN 1
            if (aExtraResponse.extraFields.length > 1) {
                const requestNameLower2 = StringToLowerCaseFirst(aExtraResponse.extraFields[1].requestName);
                additionalInfoValue[requestNameLower2] = requestReimbursementExpense.additionalInfo !== null && Object.keys(requestReimbursementExpense.additionalInfo).length > 0 && requestReimbursementExpense.additionalInfo[requestNameLower2] && requestReimbursementExpense.additionalInfo[requestNameLower2] !== "" ? requestReimbursementExpense.additionalInfo[requestNameLower2] : "";
                setRequestName2(requestNameLower2);
                setExtraField2(aExtraResponse.extraFields[1]);
            }
        } else {
            if (requestReimbursementExpense.additionalInfo && Object.keys(requestReimbursementExpense.additionalInfo).length > 0 && requestReimbursementExpense.additionalInfo[requestName] && requestReimbursementExpense.additionalInfo[requestName] !== "") {
                // BORRAR FILE
                //HandleDeleteFile(requestReimbursementExpense.additionalInfo[requestName]);
            }
            additionalInfoValue = null;
        }
        setRequestReimbursementExpense((prevFormData) => ({
            ...prevFormData,
            additionalInfo: additionalInfoValue,
        }));
    }, [aExtraResponse]);

    // UPDATE FILE UPLOADED
    useEffect(() => {
        if (fileResponse !== null) {
            if (fileType === "fileId") {
                setRequestReimbursementExpense((prevFormData) => ({
                    ...prevFormData,
                    [fileType]: fileResponse.id,
                }));
            } else {
                setRequestReimbursementExpense((prevFormData) => ({
                    ...prevFormData,
                    additionalInfo: {
                        ...prevFormData.additionalInfo,
                        [fileType]: fileResponse.id
                    }
                }));
            }
        }
    }, [fileResponse]);

    // HANDLE VALIDATE FORMS
    const HandleValidateFixed = async (fixeds = null) => {
        try {
            // CHECK IF HOURS IS MORE THAN 0
            if (isFixed === false) {
                let hoursSum = 0;
                if (fixeds !== null && fixeds.length > 0) {
                    fixeds.map(async (item) => {
                        hoursSum += item.hours;
                    });
                }
                // EXIT IF HOURS IS 0
                if (hoursSum === 0) {
                    $("#ErrorFormHours").removeClass('d-none');
                    return;
                } else {
                    $("#ErrorFormHours").addClass('d-none');
                }
            }

            if (isFixed === false) {
                if (fixeds !== null && fixeds.length > 0) {
                    await Promise.all(
                        fixeds.map(async (item) => {
                            item.status = "Förslag";
                            return completeSchemaFixed.validate(item, {abortEarly: false});
                        })
                    );
                    setRequestReimbursementFixedList(fixeds);
                }
            } else {
                setWasFixed(true);
                requestReimbursementFixedIsFixed.status = "Förslag";
                await completeSchemaFixed.validate(requestReimbursementFixedIsFixed, { abortEarly: false });
                setRequestReimbursementFixedList([requestReimbursementFixedIsFixed]);
            }
            // DISABLE DATE AND TO DATE INPUTS
            $("#date").prop("disabled", true);
            $("#toDate").prop("disabled", true);
            $("#checkInput").prop("disabled", true);
            setDisplayFixed(false);
            setEditModeFixed(false);
            setAddedFixed(true);
            setTimeout(() => {
                setRequestReimbursementFixed((prevFormData) => ({
                    ...prevFormData,
                    type: "",
                    hours: 0,
                    amount: 0,
                    status: ""
                }));
            }, 80);
        } catch (err) {
            console.log(err)
        }
    }

    const HandleValidateMileage = async () => {
        try {
            requestReimbursementMileage.status = "Förslag";
            await completeSchemaMileage.validate(requestReimbursementMileage, { abortEarly: false });
            if (requestReimbursementMileage.passengers !== null && requestReimbursementMileage.passengers.length > 0) {
                await completeSchemaPassenger.validate(requestReimbursementMileage.passengers[0], {abortEarly: false});
            }
            setDisplayMileage(false);
            setEditModeMileage(false);
            setAddedMileage(true);
        } catch (err) {
            console.log(err)
        }
    }

    const HandleValidateExpense = async () => {
        let exit = false;
        if (requestReimbursementExpense.additionalInfo && Object.keys(requestReimbursementExpense.additionalInfo).length > 0) {
            if (requestReimbursementExpense.additionalInfo.program && requestReimbursementExpense.additionalInfo.program === "") {
                exit = true;
            }
            if (requestReimbursementExpense.additionalInfo.participantsFileId && requestReimbursementExpense.additionalInfo.participantsFileId === "") {
                exit = true;
            }
            if (requestReimbursementExpense.additionalInfo.programFileId && requestReimbursementExpense.additionalInfo.programFileId === "") {
                exit = true;
            }
        }
        if (exit === false) {
            try {
                requestReimbursementExpense.status = "Förslag";
                if (requestReimbursementExpense.vatAmount >= 0 || requestReimbursementExpense.vatAmount === null) {
                    if (requestReimbursementExpense.amount > requestReimbursementExpense.vatAmount) {
                        await completeSchemaExpense.validate(requestReimbursementExpense, {abortEarly: false});
                        setFileAttribute(true);
                        if (editModeExpense === true) {
                            setRequestReimbursementExpenseList((prevFormData) => {
                                return prevFormData.map((item, i) => {
                                    if (i === expenseIndex) {
                                        return requestReimbursementExpense;
                                    } else {
                                        return item;
                                    }
                                });
                            });
                            setExpenseIndex(null);
                        } else {
                            setRequestReimbursementExpenseList((prevFormData) => ([
                                ...prevFormData,
                                requestReimbursementExpense
                            ]));
                        }
                        setEditModeExpense(false);
                        setAddedExpense(true);
                        setTimeout(() => {
                            setRequestReimbursementExpense((prevFormData) => ({
                                ...prevFormData,
                                fileId: "",
                                fileExtension: "",
                                amount: 0,
                                vatAmount: 0,
                                accountingId: "",
                                additionalInfo: null,
                                additionalFileExtension: ""
                            }));
                            $("#accountingId").val("");
                        }, 80);
                    }
                }
            } catch (err) {
                console.log(err)
            }
        }
    }

    // HANDLE CANCEL FORMS
    const HandleCancelFixed = (fixeds, fixed) => {
        // RELOAD OLD VALUES IN DATE AND TO DATE
        if (wasFixed === true) {
            HandleChangeCommon(fixed.date, "date", true);
            $("#date").val(ChangeDateFormat('date', fixed.date));
            if (fixed.toDate && fixed.toDate !== "") {
                HandleChangeCommon(fixed.toDate, "toDate", true);
                $("#toDate").val(ChangeDateFormat('date', fixed.toDate));
            }
            setRequestReimbursementFixedIsFixed(fixed);
        } else {
            if (fixeds.length > 0) {
                setTimeout(() => {
                    $("#date").val(ChangeDateFormat('date',fixeds[0].date));
                    HandleChangeCommon(fixeds[0].date, "date");
                    let eObject = null;
                    if (fixeds[0].toDate && fixeds[0].toDate !== "") {
                        HandleChangeCommon(fixeds[0].toDate, "toDate");
                        setTimeout(() => {
                            $("#toDate").val(ChangeDateFormat('date',fixeds[0].toDate));
                            $("#toDate").prop("disabled", true);
                        }, 90);
                        eObject = {
                            target: {
                                checked: true
                            }
                        }
                    } else {
                        eObject = {
                            target: {
                                checked: false
                            }
                        }
                    }
                    if (eObject !== null) {
                        actionCheckColumn(eObject);
                    }
                    setRequestReimbursementFixedList(fixeds);
                }, 250);
            }
        }
        setTimeout(() => {
            setDisplayFixed(false);
            setEditModeFixed(false);
            setIsFixed(wasFixed);
            $("#date").prop("disabled", true);
            $("#toDate").prop("disabled", true);
            $("#checkInput").prop("disabled", true);
            setTimeout(() => {
                setRequestReimbursementFixed((prevFormData) => ({
                    ...prevFormData,
                    type: "",
                    hours: 0,
                    amount: 0,
                    status: ""
                }));
            }, 80);
        },250);
    }

    const HandleCancelMileage = (mileage) => {
        setRequestReimbursementMileage(mileage);
        setDisplayMileage(false);
        setEditModeMileage(false);
    }

    const HandleCancelExpense = (expense) => {
        if (editModeExpense === true) {
            setRequestReimbursementExpenseList((prevFormData) => {
                return prevFormData.map((item, i) => {
                    if (i === expenseIndex) {
                        return expense;
                    } else {
                        return item;
                    }
                });
            });
            setExpenseIndex(null);
        } else {
            setRequestReimbursementExpenseList((prevFormData) => ([
                ...prevFormData,
                expense
            ]));
        }
        setEditModeExpense(false);
        setTimeout(() => {
            setRequestReimbursementExpense((prevFormData) => ({
                ...prevFormData,
                fileId: "",
                fileExtension: "",
                amount: 0,
                vatAmount: 0,
                accountingId: "",
                additionalInfo: null,
                additionalFileExtension: ""
            }));
            $("#accountingId").val("");
        }, 80);
    }

    // DELETE FORMS
    const DeleteFixed = () => {
        let fixedAmount = 0;
        fixedAmount += requestReimbursementFixedIsFixed.amount;
        if (requestReimbursementFixedList.length > 0) {
            requestReimbursementFixedList.map(item => {
                fixedAmount += item.amount;
            });
        }
        if (fixedAmount > 0) {
            setGrossAmount(grossAmount - fixedAmount);
        }
        const tempFixed = new RequestReimbursementFixed();
        tempFixed.date = requestReimbursement.date;
        tempFixed.toDate = requestReimbursement.toDate;
        tempFixed.purpose = requestReimbursement.purpose;
        tempFixed.costBearerId = requestReimbursement.costBearerId;
        setRequestReimbursementFixed(tempFixed);
        setRequestReimbursementFixedIsFixed(tempFixed);
        setRequestReimbursementFixedList([]);
        setDisplayFixed(true);
        setEditModeFixed(false);
        setAddedFixed(false);
        setIsFixed(false);
        $("#date").prop("disabled", false);
        $("#toDate").prop("disabled", false);
        $("#checkInput").prop("disabled", false);
        setTimeout(() => {
            setRequestReimbursementFixed((prevFormData) => ({
                ...prevFormData,
                type: "",
                hours: 0,
                amount: 0,
                status: ""
            }));
        }, 80);
        setDeletedFixed(true);
    };

    const DeleteMileage = () => {
        setGrossAmount(grossAmount - requestReimbursementMileage.totalAmount);
        const tempMileage = new RequestReimbursementMileage();
        tempMileage.date = requestReimbursement.date;
        tempMileage.purpose = requestReimbursement.purpose;
        tempMileage.costBearerId = requestReimbursement.costBearerId;
        setRequestReimbursementMileage(tempMileage);
        setDisplayMileage(true);
        setAddedMileage(false);
    };

    const DeleteExpense = (index = null) => {
        if (index !== null) {
            let expenseAmount = 0;
            if (requestReimbursementExpenseList.length > 0) {
                requestReimbursementExpenseList.map(item => {
                    expenseAmount += item.amount;
                });
            }
            if (expenseAmount > 0) {
                setGrossAmount(grossAmount - expenseAmount);
            }
            if (requestReimbursementExpenseList[index].fileId !== "") {
                // BORRAR FILE
                //HandleDeleteFile(requestReimbursementExpenseList[index].fileId);
            }
            if (requestReimbursementExpenseList[index].additionalInfo !== null && Object.keys(requestReimbursementExpenseList[index].additionalInfo).length > 0) {
                // BORRAR FILE
                //HandleDeleteFile(requestReimbursementExpenseList[index].additionalInfo[requestName]);
            }
            const expenseList = requestReimbursementExpenseList.filter((item, i) => i !== index);
            setRequestReimbursementExpenseList((prevFormData) => {
                return prevFormData.filter((item, i) => i !== index);
            });
            setExpenseIndex(null);
            if (expenseList.length === 0) {
                setAddedExpense(false);
            }
            setTimeout(() => {
                setRequestReimbursementExpense((prevFormData) => ({
                    ...prevFormData,
                    fileId: "",
                    fileExtension: "",
                    amount: 0,
                    vatAmount: 0,
                    accountingId: "",
                    additionalInfo: null,
                    additionalFileExtension: ""
                }));
                $("#accountingId").val("");
            }, 80);
        }
    };

    // MODAL OPTIONS
    const handleModalDelete = () => {
        switch (reimbursementToDelete) {
            case "Fixed":
                DeleteFixed();
                break;
            case "Mileage":
                DeleteMileage();
                break;
            case "Expense":
                DeleteExpense(expenseIndex);
                break;
            default:
                break;
        }
        setDisplayDeleteModal(false);
    };

    const modalContent = (
        <div>
            {reimbursementToDelete === "Fixed" ? t('validationMessage.deleteFixed') : reimbursementToDelete === "Mileage" ? t('validationMessage.deleteMileage') : t('validationMessage.deleteExpense')}
        </div>
    );
    const modalFooter = (
        <>
            <Button className="box-green border-0" onClick={handleModalDelete}>
                {t('button.delete')}
            </Button>
            <Button className="box-red border-0" onClick={() => setDisplayDeleteModal(false)}>{t('button.cancel')}</Button>
        </>
    );
    const modalContentSend = (
        <div id="sendMessageDiv">
            {isSend === false ? t('validationMessage.saveReimbursement') : t('validationMessage.sendReimbursement')}
        </div>
    );
    const modalFooterSend = (
        <>
            <Button className="box-green border-0" id="sendButton" onClick={async () => {
                setDisplayFixed(false);
                setDisplayMileage(false);
                setDisplayExpense(false);
                if (isSend === false) {
                    setSave(true);
                }
                $("#submitReimbursement, #saveReimbursement, #sendButton").prop("disabled", true);
                if (addedFixed === true) {
                    await SubmitFixed(isSend === false ? "save" : null);
                }
                if (addedMileage === true) {
                    await SubmitMileage(isSend === false ? "save" : null);
                }
                if (addedExpense === true) {
                    await SubmitExpense(isSend === false ? "save" : null);
                }
                setDisplaySpinner(true);
                setCheckUnsaved(false);
                setTimeout(() => {
                    setDone(true);
                }, branch.includes("develop") && requestReimbursementExpenseList.length >= 6 ? 12000 : 7000);
                setDisplaySendModal(false)
            }}>
                {isSend === false ? t('button.save') : t('button.send')}
            </Button>
            <Button className="box-red border-0" onClick={() => setDisplaySendModal(false)}>{t('button.cancel')}</Button>
        </>
    );

    // CHECK IF HAVE SOME CHANGES TO CHANGE MODAL MESSAGE
    const checkIfHaveChanges = (typeSend = null) => {
        // FIXED
        const hours = document.getElementsByClassName("hourDiv");
        const fixedAmount = $("#fixedAmount").val();
        // MILEAGE
        const origin = $("#origin").val();
        const destination = $("#destination").val();
        const km = $("#km").val();
        const number = $("#number").val();
        const distance = $("#distance").val();
        // EXPENSE
        const accounting = $("#accounting").val();
        const expenseAmount = $("#expenseAmount").val();
        if ((fixedAmount && fixedAmount !== "") || (origin && origin !== "") || (destination && destination !== "") || (km && km !== "") || (number && number !== "") || (distance && distance !== "") || (accounting && accounting !== "") || (expenseAmount && expenseAmount !== "")) {
            setTimeout(() => {
                $("#sendMessageDiv").html(typeSend === null ? t('validationMessage.sendReimbursementNotSaved') : t('validationMessage.sendReimbursementNotSavedSave'));
            }, 80);
        }
        if (hours) {
            const hourArray = Array.from(hours);
            hourArray.map(input => {
                if ($(input).find("input").val() !== "") {
                    setTimeout(() => {
                        $("#sendMessageDiv").html(typeSend === null ? t('validationMessage.sendReimbursementNotSaved') : t('validationMessage.sendReimbursementNotSavedSave'));
                    }, 80);
                }
            });
        }
    }

    // GET USER SETTING
    useEffect(() => {
        const handleUserSettingsResponse = (responseUserSettings) => {
            if (responseUserSettings !== null) {
                //$('#submitReimbursement').removeClass('d-none');
                $('#formReimbursement').removeClass('d-none');
                $('#viewReimbursement').removeClass('d-none');
                setResponseUserSettings(new ResponseUserSettingsModel(responseUserSettings));
            } else {
                $('#pendingAccountDiv').removeClass('d-none');
            }
        };
        const CostApiCall = async () => {
            try {
                const apiCallOptions = {
                    method: "get",
                    url: "/usersetting/bank-account",
                };
                await ApiCall(apiCallOptions, handleUserSettingsResponse);
            } catch (error) {
                console.log('error', error);
            }
        };
        CostApiCall();
    }, []);

    // DELETE FILE FROM REQUEST
    useEffect(() => {
        if (isDeletedFile === true) {
            setRequestReimbursementExpense((prevFormData) => ({
                ...prevFormData,
                fileId: "",
                fileExtension: "",
                fileName: "",
            }));
        }
    }, [isDeletedFile]);

    // DELETE EXTRA FILE FROM REQUEST
    useEffect(() => {
        if (isDeletedExtraFile === true) {
            setRequestReimbursementExpense((prevFormData) => ({
                ...prevFormData,
                additionalInfo: {
                    ...prevFormData.additionalInfo,
                    [fileType]: ""
                },
                additionalFileExtension: "",
                additionalFileName: "",
            }));
        }
    }, [isDeletedExtraFile]);

    // ADDITIONAL INFO
    /*useEffect(() => {
        if (requestReimbursementExpense.additionalInfo !== null && Object.keys(requestReimbursementExpense.additionalInfo).length > 0) {
            for (const key of Object.keys(requestReimbursementExpense.additionalInfo)) {
                setAdditionalKey(key);
                setAdditionalValue(requestReimbursementExpense.additionalInfo[key]);
            }
        }
    }, [requestReimbursementExpense.additionalInfo]);*/

    // CALCULATIONS VARIABLES
    /*const netFixed = ToCustomDecimal(requestReimbursementFixed?.amount * 0.7);
    const incomeTaxFixed = ToCustomDecimal(requestReimbursementFixed?.amount * 0.3);
    const employmentTaxFixed = ToCustomDecimal(requestReimbursementFixed?.amount * taxPercent);
    const taxFreeMileage = ToCustomDecimal((requestReimbursementMileage?.km / 10) * 25);
    const taxableMileage = ToCustomDecimal(requestReimbursementMileage?.amount + (requestReimbursementMileage?.passengers != null && requestReimbursementMileage.passengers?.length > 0 ? requestReimbursementMileage?.passengers[0]?.amount : 0) - parseFloat(taxFreeMileage));
    const incomeTaxMileage = ToCustomDecimal(parseFloat(taxableMileage) * 0.3);
    const netMileage = ToCustomDecimal(parseFloat(taxFreeMileage) + parseFloat(taxableMileage) - parseFloat(incomeTaxMileage));
    const employmentTaxMileage = ToCustomDecimal(parseFloat(taxableMileage) * taxPercent);*/

    // CALCULATE GROSS AMOUNT
    useEffect(() => {
        let tempAmount = 0;
        // SUM FIXEDS AMOUNT
        if (wasFixed) {
            tempAmount += requestReimbursementFixedIsFixed.amount;
        } else {
            if (requestReimbursementFixedList.length > 0) {
                requestReimbursementFixedList.map(item => {
                    tempAmount += item.amount;
                });
            }
        }

        // SUM MILEAGE AMOUNT
        tempAmount += requestReimbursementMileage.totalAmount;

        // SUM EXPENSES AMOUNT
        if (requestReimbursementExpenseList.length > 0) {
            requestReimbursementExpenseList.map(item => {
                tempAmount += item.amount;
            });
            // ORDER EXPENSES BY DATERECEIVE
            setRequestReimbursementExpenseList((prevFormData) => {
                return prevFormData.sort((a, b) => {
                    return new Date(a.dateReceive) - new Date(b.dateReceive);
                });
            });
        }

        setGrossAmount(tempAmount);
    }, [addedFixed, addedMileage, addedExpense, editModeFixed, editModeMileage, editModeExpense, requestReimbursementExpenseList]);

    // HANDLE MODAL DELETE
    const handleModalDeleteReimbursement = async () => {
        if (id !== "") {
            const apiCallOptions = {
                method: "delete",
                url: `/reimbursement/`,
                data: {
                    id: id
                }
            };
            try {
                await ApiCall(apiCallOptions, setDeleteApiResponse);
                setDisplayDeleteModal(false);
            } catch (error) {
                console.log(error);
            }
        }
    }

    // HANDLE CHECK MULTIPLE DAYS
    const actionCheckColumn = async (e) => {
        requestReimbursement.toDate = '';
        requestReimbursementFixed.toDate = '';
        setSelectedCheckColumn(e.target.checked);
        if (e.target.checked === true) {
            if (addedFixed === true && editModeFixed === false) {
                setTimeout(() => {
                    $("#toDate").prop("disabled", true);
                }, 80);
            } else {
                setDisplayFixed(true);
            }
        }
        /*else if (e.target.checked === false && requestReimbursementFixedList.length > 0) {
            setDisplayFixed(false);
        }*/
        setRequestReimbursementFixed(requestReimbursementFixed);
    }

    useEffect(() => {
        if (deleteApiResponse !== null && id !== "") {
            navigate(REPORT_PROCEEDING_PATHS.REPORT_PROCEEDING_LIST);
        }
    }, [deleteApiResponse]);

    const modalContentDelete = (
        <div>
            {`${t('validationMessage.modalVar')}?`}
        </div>
    );
    const modalFooterDelete = (
        <>
            <Button className="box-green border-0" onClick={handleModalDeleteReimbursement}>
                {t('button.delete')}
            </Button>
            <Button className="box-red border-0" onClick={() => setDisplayDeleteModalReimbursement(false)}>{t('button.cancel')}</Button>
        </>
    );
    const messageCostBearer = InputMessage(costResponse, requestReimbursement.costBearerId);

    return (
        <>
            {permissionCreate === true ?
                <Col lg={12} className="px-0">
                    <CustomButton
                        text={t('button.back')}
                        icon={'bx bx-chevron-left'}
                        buttonSize={'small'}
                        customClasses={'mb-3 ms-3 backButton'}
                        onClick={() => {
                            navigate(REPORT_PROCEEDING_PATHS.REPORT_PROCEEDING_LIST)
                        }}
                    />
                </Col>
                : ""
            }

            <Col lg={10} id='pendingAccountDiv' className={`box-red mt-2 p-1 d-none mx-auto`}>{t('validationMessage.needAccount')} <Link className='underline' to={USER_PATHS.SETTINGS}>{t('validationMessage.goAddAccount')} </Link>
            </Col>

            <Col lg={12} className="px-0">
            <Row className="pb-4">
                {id !== "" ?
                    <CustomModal isOpen={displayDeleteModalReimbursement} toggle={() => setDisplayDeleteModalReimbursement(!displayDeleteModalReimbursement)}
                             body={modalContentDelete} footer={modalFooterDelete}/>
                : ''}
                <CustomModal isOpen={displayDeleteModal} toggle={() => setDisplayDeleteModal(!displayDeleteModal)}
                             body={modalContent} footer={modalFooter}/>
                <CustomModal isOpen={displaySendModal} toggle={() => setDisplaySendModal(!displaySendModal)}
                             body={modalContentSend} footer={modalFooterSend}/>
                {/* UNSAVED MODAL */}
                {checkUnsaved === true ? <CustomUnsavedModal backButtonUrl={REPORT_PROCEEDING_PATHS.REPORT_PROCEEDING_LIST} /> : ''}
                {/* FORMS */}
                <Col lg={6} id="formReimbursement" className="d-none">
                    <Card className="card-backend p-4">
                        <Row>
                            <Col xs='auto'>
                                <h2 className='rokkitt-font'> {t('reimbursement.reportProcessing')}</h2>
                            </Col>
                            <InfoPageMessage message={t('infoBox.reportProceeding')}/>
                        </Row>
                        {accountingResponse !== null &&  accountingResponse !== '' ?
                            <>
                                <Row>
                                    <Col lg={6} onClick={() => $('.react-datepicker__tab-loop').removeClass('d-none')}>
                                    <label className="d-block form-label">
                                    {selectedCheckColumn === true ? t('modelLabel.fromDateReimbursement') : t('modelLabel.dateReimbursement')}: </label>
                                        <div className="position-relative">
                                            <CustomInput model={RequestReimbursementCommon} attribute='date' data={requestReimbursement.date}
                                                         customChange={(e) => HandleChangeCommon(e, 'date')}
                                            />
                                            <div id="ErrorFormDate" className="error tooltip-custom show-custom d-none" style={{ color: '#f56e6e' }}>{t('validationMessage.dateMustBeLessThanToDate')}</div>
                                        </div>

                                         {selectedCheckColumn === true ? 
                                        <CustomInput model={RequestReimbursementCommon} attribute='toDate' data={requestReimbursement.toDate} minDate={requestReimbursement.date}
                                                     customChange={(e) => HandleChangeCommon(e, 'toDate')}
                                        />: ''}
                                        <div className='mb-3' style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                            <label>{t('modelLabel.multipleDayEvent')}: </label>
                                            <input  style={{ marginTop: '4px' }} id="checkInput" checked={selectedCheckColumn} type="checkbox" onChange={(e) => {
                                                 actionCheckColumn(e)
                                            }} />
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <CustomInput model={RequestReimbursementCommon} attribute='orgId' data={requestReimbursement.orgId}
                                                     customChange={(e) => HandleChangeCommon(e, 'orgId')} customOptions={orgJson}/>
                                    </Col>
                                    <Col lg={6}>
                                        <CustomInput model={RequestReimbursementCommon} attribute='purpose' data={requestReimbursement.purpose}
                                                     customChange={(e) => HandleChangeCommon(e, 'purpose')}/>
                                    </Col>
                                    <Col lg={6}>
                                        {costResponse !== null && costResponse.length > 0 && selectedOrgId !== null ?
                                            <>
                                                <CustomInput model={RequestReimbursementCommon} attribute='costBearerId' data={requestReimbursement.costBearerId}
                                                             customChange={(e) => HandleChangeCommon(e, 'costBearerId')}
                                                             customOptions={costJson}/>
                                                {messageCostBearer}
                                            </>
                                        : ''}
                                    </Col>
                                </Row>
                                {/* FIXED COMPONENT */}
                                {displayFixed ? <ReimbursementFixed data={requestReimbursementFixedList} dataFixed={requestReimbursementFixed} onChange={HandleChangeFixed} onValidate={HandleValidateFixed} onCancel={HandleCancelFixed} editMode={editModeFixed} showDate={selectedCheckColumn} isFixed={isFixed}/> : ''}
                                {/* MILEAGE COMPONENT */}
                                {displayMileage ? <ReimbursementMileage data={requestReimbursementMileage} onChange={HandleChangeMileage} onValidate={HandleValidateMileage} onCancel={HandleCancelMileage} editMode={editModeMileage} /> : ''}
                                {/* EXPENSE COMPONENT */}
                                {displayExpense ? <ReimbursementExpense data={requestReimbursementExpense} onChange={HandleChangeExpense} onValidate={HandleValidateExpense} onCancel={HandleCancelExpense} editMode={editModeExpense} accountingJson={accountingJson} requestName={requestName} extraField={extraField}  setIsDeletedFile={setIsDeletedFile} setIsDeletedExtraFile={setIsDeletedExtraFile} extraField2={extraField2} attributeValue={fileAttribute} /> : ''}
                            </>
                            :
                             <LoadingData data={accountingResponse}/>
                        }

                    </Card>
                </Col>
                {/* DATA VIEW */}
                <Col lg={6} id="viewReimbursement" className="d-none">
                    {deniedReimbursement !== null && deniedReimbursement.status === "Nekad" && deniedReimbursement.reason !== null ?
                        <Card className="card-backend p-4 mb-3">
                            <Row>
                                <Col lg={12} className="text-center">
                                    <h2 className="text-red rokkitt-font">{t('reimbursement.deniedMessageCreate')}</h2>
                                </Col>
                                <Col lg={12} className="mb-3">
                                    <Card className="bg-card-grey p-3">
                                        <h5 className='rokkitt-font'>{t('attest.deniedBy')}</h5>
                                        {deniedReimbursement.deniedBy}
                                        <h5 className='rokkitt-font mt-4'>{t('modelLabel.reason')}</h5>
                                        {deniedReimbursement.reason}
                                    </Card>
                                </Col>
                            </Row>
                        </Card>
                        : ""}
                    <Card className="card-backend p-4">
                        <Row>
                            <Col lg={12} className="text-start">
                                <h2 className='rokkitt-font'> {t('modelLabel.added')}</h2>
                                <h5 className="rokkitt-font">{t('reimbursement.reportProcessings')}</h5>
                            </Col>
                            <Col lg={12}>
                                {/* COMMON */}
                                {requestReimbursement?.date !== '' || requestReimbursement?.costBearerId !== '' || requestReimbursement?.purpose !== '' || requestReimbursement?.orgId !== '' ?
                                    <CustomReimbursementView data={requestReimbursement} costSelected={costSelected} orgSelected={orgSelected} />
                                : ''}
                            </Col>
                            <Col lg={12}>
                                {/* FIXED */}
                                {requestReimbursementFixedList?.length > 0 && addedFixed === true && editModeFixed === false ?
                                    <>
                                    <h5 className='rokkitt-font mt-2'>{t('reimbursement.salary')}</h5>
                                        <CustomReimbursementFixedView data={requestReimbursementFixedList} editMode={editModeFixed} dataFixed={requestReimbursementFixedIsFixed}
                                                isFixed={isFixed}
                                                onClickDelete={() => {
                                                    setDisplayDeleteModal(true);
                                                    setReimbursementToDelete('Fixed');
                                                }}
                                                onClickEdit={(item) => {
                                                    $("#date").prop("disabled", false);
                                                    $("#toDate").prop("disabled", false);
                                                    $("#checkInput").prop("disabled", false);
                                                    setDisplayFixed(true);
                                                    if (item !== null) {
                                                        setWasFixed(false);
                                                        setRequestReimbursementFixed(item);
                                                    } else {
                                                        setWasFixed(true);
                                                        setRequestReimbursementFixed(requestReimbursementFixedIsFixed);
                                                    }
                                                    setEditModeFixed(true);
                                                }}
                                        />
                                    </>
                                : ''}
                            </Col>
                            {/* Mileage */}
                            {addedMileage === true && editModeMileage === false ?
                                <Col lg={12}>
                                    <h5 className='rokkitt-font mt-2'>{t('reimbursement.mileage')}</h5>
                                    <CustomReimbursementMileageView data={requestReimbursementMileage} editMode={editModeMileage}
                                                                    onClickDelete={() => {
                                                                         setDisplayDeleteModal(true);
                                                                         setReimbursementToDelete('Mileage');
                                                                     }}
                                                                    onClickEdit={() => {
                                                                         setDisplayMileage(true);
                                                                         setEditModeMileage(true);
                                                                      } }
                                    />
                                </Col>
                                : ''}
                            {/* EXPENSE */}
                            {requestReimbursementExpenseList?.length > 0 && addedExpense === true && editModeExpense === false ?
                                <>
                                    <h5 className='rokkitt-font mt-2'>{t('reimbursement.expenseTitle')}</h5>
                                    <CustomReimbursementExpenseView data={requestReimbursementExpenseList} accountingResponse={accountingResponse} editMode={editModeExpense}
                                                                    onClickDelete={(item,index) => {
                                                                          setDisplayDeleteModal(true);
                                                                          setReimbursementToDelete('Expense');
                                                                          setExpenseIndex(index);
                                                                      }}
                                                                    onClickEdit={(item,index) => {
                                                                        setRequestReimbursementExpense(item);
                                                                        setExpenseIndex(index);
                                                                        setEditModeExpense(true);
                                                                        $("#accountingId").val(item.accountingId);
                                                                     }}
                                    />
                                </>
                            : ''}
                            {/* SEND FORM */}
                            <Col lg={12} className=" text-center pt-4">
                                <Row>
                                    {grossAmount > 0 && ((requestReimbursementFixedList.length > 0 && addedFixed === true) || addedMileage === true || (addedExpense === true && requestReimbursementExpenseList.length > 0 )) && editModeFixed === false && editModeMileage === false && editModeExpense === false ?
                                        <Col lg={12} className="pb-4 rokkitt-font fs-5">
                                            <span className="fw-bold fs-5 text-green"> {t('modelLabel.grossAmount')}: </span> {ToCustomDecimal(grossAmount)} kr
                                        </Col>
                                    : ''}

                                    <Col lg={12} className="text-center pb-3">
                                        {displaySpinner === true ?
                                            <>
                                                {save === true ? t('validationMessage.savingReimbursement') : t('validationMessage.sendingReimbursement')} <Spinner color='success'/>
                                            </>
                                        : ""}
                                        {grossAmount <= 0 ?
                                            t("reimbursement.sendInfo")
                                        : ""}
                                    </Col>
                                    <Col lg={12} className="d-flex justify-content-center justify-content-lg-end">
                                        {id !== "" ?
                                            <Button className=" rounded-2 box-red border-0  position-relative me-2 d-flex align-items-center" onClick={() => {
                                                setDisplayDeleteModalReimbursement(true);
                                            }}>
                                                <i className="bx bx-x-circle fs-5 pe-1"/> {t("button.delete")}
                                            </Button>
                                        : ''}
                                        {editModeFixed === false && editModeMileage === false && editModeExpense === false ?
                                            <>
                                                <CustomSubmitButton customClass="me-2" text={t('button.saveReimbursement')}  id='saveReimbursement' icon='bx bx-save pe-2' doClick={false} customClick={async () => {
                                                        // CHECK IF HAVE SOME CHANGES TO CHANGE MODAL MESSAGE
                                                        setIsSend(false);
                                                        checkIfHaveChanges("save");
                                                        setDisplaySendModal(true);
                                                }} />
                                                <CustomSubmitButton text={t('button.send')} id='submitReimbursement' icon='bx bx-send pe-2'
                                                                    //customClass='d-none'
                                                                    customClick={async () => {
                                                                        // CHECK IF HAVE SOME CHANGES TO CHANGE MODAL MESSAGE
                                                                        setIsSend(true);
                                                                        checkIfHaveChanges();
                                                                        setDisplaySendModal(true);
                                                }} />
                                            </>
                                        : ''}
                                       {/* <div id='pendingAccountDiv' className={`box-red mt-2 p-1 w-100 d-none`}>{t('validationMessage.needAccount')} <Link className='underline' to={USER_PATHS.SETTINGS}>{t('validationMessage.goAddAccount')} </Link> </div>*/}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>
                    {/* CALCULATIONS */}
                    {/*<h5 className="mt-5">{t('reportProceeding.calculations.title')} - <span className="fw-bold text-warning">{t('reportProceeding.calculations.subtitle')}</span></h5>
                    <Card className="bg-light float-end p-4">
                        <h5>{t('reportProceeding.calculations.salary')}</h5>
                        <Row>
                            <Col lg="6" className="mt-2">
                                <div className="fs-6 fw-bold">{t('reportProceeding.calculations.gross')}:</div>
                                <div>{t('reportProceeding.calculations.hours')}</div>
                                <div className="box-beig"><span
                                    className="fw-bold">{ToCustomDecimal(requestReimbursementFixed?.amount || 0)} kr</span></div>
                            </Col>
                            <Col lg="6" className="mt-2">
                                <div className="fs-6 fw-bold">{t('reportProceeding.calculations.net.title')} </div>
                                <div>{t('reportProceeding.calculations.gross')} * 70 / 100</div>
                                <div className="box-beig">{`${ToCustomDecimal(requestReimbursementFixed?.amount || 0)} * 70 / 100 =`} <span
                                    className="fw-bold">{netFixed} kr</span></div>
                            </Col>
                            <Col lg="6" className="mt-2">
                                <div className="fs-6 fw-bold">{t('reportProceeding.calculations.incomeTax.title')} </div>
                                <div>{t('reportProceeding.calculations.incomeTax.textGross')}</div>
                                <div className="box-beig">{`${ToCustomDecimal(requestReimbursementFixed?.amount || 0)} * 30 / 100 =`} <span
                                    className="fw-bold">{incomeTaxFixed} kr</span></div>
                            </Col>
                            <Col lg="6" className="mt-2">
                                <div className="fs-6 fw-bold">{t('reportProceeding.calculations.employmentTax.title')}</div>
                                <div>{t('reportProceeding.calculations.employmentTax.textGross')}</div>
                                <div className="box-beig">{`${ToCustomDecimal(requestReimbursementFixed?.amount || 0)} * ${taxPercent} =`} <span
                                    className="fw-bold">{employmentTaxFixed} kr</span></div>
                            </Col>
                        </Row>
                        <hr/>
                        <h5>{t('reportProceeding.calculations.mileage')}</h5>
                        <Row>
                            <Col lg="6" className="mt-2">
                                <div className="fs-6 fw-bold">{t('reportProceeding.calculations.taxFree.title')}</div>
                                <div>{t('reportProceeding.calculations.taxFree.text')}</div>
                                <div className="box-beig">{`${ToCustomDecimal(requestReimbursementMileage?.km || 0)} / 10 * 25 =`} <span
                                    className="fw-bold">{taxFreeMileage} kr</span></div>
                            </Col>
                            <Col lg="6" className="mt-2">
                                <div className="fs-6 fw-bold">{t('reportProceeding.calculations.taxable.title')}</div>
                                <div>{t('reportProceeding.calculations.taxable.text')}</div>
                                <div
                                    className="box-beig">{`${ToCustomDecimal(requestReimbursementMileage?.amount || 0)} + ${requestReimbursementMileage?.passengers != null && requestReimbursementMileage?.passengers.length > 0 && requestReimbursementMileage?.passengers[0]?.amount ? ToCustomDecimal(requestReimbursementMileage?.passengers[0]?.amount || 0) : 0} - ${ToCustomDecimal((requestReimbursementMileage?.km || 0 / 10) * 25)} =`} <span className="fw-bold">{taxableMileage} kr</span></div>
                            </Col>
                            <Col lg="6" className="mt-2">
                                <div className="fs-6 fw-bold">{t('reportProceeding.calculations.incomeTax.title')}</div>
                                <div>{t('reportProceeding.calculations.incomeTax.text')}</div>
                                <div className="box-beig">{`${taxableMileage} * 30 / 100 =`} <span className="fw-bold">{incomeTaxMileage} kr</span></div>
                            </Col>
                            <Col lg="6" className="mt-2">
                                <div className="fs-6 fw-bold">{t('reportProceeding.calculations.net.title')}</div>
                                <div>{t('reportProceeding.calculations.net.text')}</div>
                                <div
                                    className="box-beig">{`${taxFreeMileage} + ${taxableMileage} - ${incomeTaxMileage} =`} <span className="fw-bold">{netMileage} kr</span></div>
                            </Col>
                            <Col lg="6" className="mt-2">
                                <div className="fs-6 fw-bold">{t('reportProceeding.calculations.employmentTax.title')}</div>
                                <div>{t('reportProceeding.calculations.employmentTax.text')}</div>
                                <div className="box-beig">{`${taxableMileage} * ${taxPercent} =`} <span className="fw-bold">{employmentTaxMileage} kr</span></div>
                            </Col>
                        </Row>
                        <hr/>
                        <h5>{t('reportProceeding.calculations.expenses')}</h5>
                        {requestReimbursementExpenseList?.length > 0 ?
                            requestReimbursementExpenseList?.map((item, index) => {
                                return (
                                    <div key={`expenseCalc${index}`}>
                                        {index > 0 ? <hr className="ps-5 w-50" /> : ''}
                                        <Row>
                                            <Col lg={item.vatAmount && item.vatAmount !== null ? "4" : "6"} className="mt-2">
                                                <div className="fs-6 fw-bold">{t('reportProceeding.calculations.amount')}</div>
                                                <div className="box-beig">
                                                    <span className="fw-bold">{ToCustomDecimal(item.amount)} kr</span>
                                                </div>
                                            </Col>
                                            {item.vatAmount && item.vatAmount !== null ?
                                                <>
                                                    <Col lg="4" className="mt-2">
                                                        <div className="fs-6 fw-bold">{t('reportProceeding.calculations.tax')}</div>
                                                        <div className="box-beig">
                                                            <span className="fw-bold">{ToCustomDecimal(item.vatAmount)} kr</span>
                                                        </div>
                                                    </Col>
                                                    <Col lg="4" className="mt-2">
                                                        <div className="fs-6 fw-bold">{t('reportProceeding.calculations.amountWithout')}</div>
                                                        <div>{t('reportProceeding.calculations.amountTotal')}</div>
                                                        <div className="box-beig">
                                                            {`${ToCustomDecimal(item.amount)} - ${ToCustomDecimal(item.vatAmount)} =`} <span className="fw-bold">{ToCustomDecimal(item.amount - item.vatAmount)} kr</span>
                                                        </div>
                                                    </Col>
                                                </>
                                            : ''}
                                        </Row>
                                    </div>
                                );
                            })
                        : ''}
                    </Card>*/}
                </Col>
            </Row>
            </Col>
        </>
    );
}