import React, {useEffect,useState} from 'react';
import {Card, Col, Row} from "reactstrap";
import ImgDashboard from "../../AppSystem/assets/img/dashboard_img.png";
import ImgNewDashboard from "../../AppSystem/assets/img/new_dashboard_img.png";
import ImgNewDashboardWinter from "../../AppSystem/assets/img/new_dashboard_img_winter.png";
import LogoLRF from "../../AppSystem/assets/img/LRF_Logga.png"
import {useTranslation} from "react-i18next";
import i18next from 'i18next';
import i18n from 'i18next';
import {LoadLocalStorage} from "../Helpers/LoadLocalStorage";

export function Dashboard() {
    const { t } = useTranslation();
    const [executeEffect, setExecuteEffect] = useState(false);

    useEffect(() => {
        if (i18next.language !== LoadLocalStorage('selectedLanguage')){
            i18n.changeLanguage(LoadLocalStorage('selectedLanguage'));
        }
        if (executeEffect === false) {
            setExecuteEffect(true);
        }
    }, [executeEffect]);


    return (
        <>
            <Col lg={6} className=' pb-5 offset-md-3'>
                <Card className='card-backend p-3 p-lg-5'>

                    <img src={ImgNewDashboardWinter} className='img-fluid'/>
                    <Row className='pt-4 justify-content-center'>
                        <Col lg={12}>
                            <h1 className='fw-bold'>{t('modelLabel.welcome')}!</h1>
                            <p className='fw-bold text-sm text-lg'>
                                {t('dashboard.bodyTitle')}
                            </p>
                            <p>
                                {t('dashboard.bodyText')}
                            </p>
                            <p>
                                Kontaktuppgifter: e-post <a href="mailto:digitalakassan@lrf.se" class='custom-link'>digitalakassan@lrf.se</a>  eller telefon 010 – 184 25 70 telefontid helgfria vardagar kl 09.00 – 12.00. 
                            </p>
                        </Col>
                        <Col className='justify-content-center col-auto'>
                            <img src={LogoLRF} />
                        </Col>
                    </Row>
                </Card>
            </Col>
        </>
    );
}