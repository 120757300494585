import i18n from "../../../AppSystem/i18n";
import { ChangeDateFormat } from "../../../Common/Helpers/ChangeDateFormat";
import {ToCustomDecimal} from "../../../AppSystem/Helpers/ToCustomDecimal";

class ResponseSwedbankPay {
    constructor({
                    id = '',
                    visualId = '',
                    userId = '',
                    orgId = '',
                    orgName = '',
                    swedbankId = '',
                    costBearerId = '',
                    costBearerName = '',
                    accountingId = '',
                    accountingName = '',
                    email = '',
                    amount = 0,
                    vatAmount = 0,
                    description = '',
                    status = '',
                    displayStatus = '',
                    createdAt = '',
                    createdByName = '',
                    updatedAt = '',
                    paymentMethod = '',
                    header = '',
                } = {}) {
        this.id = id;
        this.visualId = visualId;
        this.userId = userId;
        this.orgId = orgId;
        this.orgName = orgName;
        this.swedbankId = swedbankId;
        this.costBearerId = costBearerId;
        this.costBearerName = costBearerName;
        this.accountingId = accountingId;
        this.accountingName = accountingName;
        this.email = email;
        this.amount = amount;
        this.vatAmount = vatAmount;
        this.description = description;
        this.status = status;
        this.createdAt = createdAt;
        this.createdByName = createdByName;
        this.updatedAt = updatedAt;
        this.paymentMethod = paymentMethod;
        this.displayStatus = displayStatus;
        this.header = header;
    }

    static getAttributes(filter = null, validationSchema = null, optionsData = null, customField = null) {
        const costBearerId = i18n.t('reimbursement.costBearer');
        const accountingId = i18n.t('modelLabel.accounting');
        const status = i18n.t('modelLabel.status');
        const initialize =i18n.t('modelLabel.initialized');
        const aborted =i18n.t('modelLabel.aborted');
        const paid =i18n.t('modelLabel.paid');


        const attributes =  {
            id :{
                'label': 'Id',
                'sortable': true,
                'sortField': "id",
                'value': (item) => item.id || "",
            },
            visualId :{
                'label': i18n.t('modelLabel.id'),
                'sortable': true,
                'sortField': 'visualId',
                'value': (item) => item.visualId || "",
            },
            userId :{
                'label': 'User Id',
                'sortable': true,
                'sortField': "userId",
                'value': (item) => item.userId || "",
            },
            orgId :{
                'label': 'Org Id',
                'sortable': true,
                'sortField': "orgId",
                'value': (item) => item.orgId || "",
            },
            orgName :{
                'label': i18n.t('modelLabel.orgName'),
                'sortable': true,
                'sortField': "orgName",
                'value': (item) => item.orgName || "",
            },
            swedbankId :{
                'label': 'Swedbank Id',
                'sortable': true,
                'sortField': "swedbankId",
                'value': (item) => item.swedbankId || "",
            },
            costBearerId :{
                'label': costBearerId,
                'sortable': true,
                'sortField':'costBearer',
                'value': (item) => item.costBearerId || "",
            },
            costBearerName :{
                'label': costBearerId,
                'sortable': true,
                'sortField':'costBearerName',
                'value': (item) => item.costBearerName || "",
            },
            accountingId :{
                'label': accountingId,
                'sortable': true,
                "sortField":'accounting',
                'value': (item) => item.accountingId || "",
            },
            accountingName :{
                'label': accountingId,
                'sortable': true,
                "sortField":'accountingName',
                'value': (item) => item.accountingName || "",
            },
            email :{
                'label': i18n.t('payReceive.email'),
                'sortable': true,
                "sortField":'email',
                'value': (item) => item.email || "",
            },
            amount: {
                'label': i18n.t('payReceive.amountQuickPayment'),
                'sortable': true,
                "sortField":'amount',
                "thClass": "ps-5",
                'insideClass' : (item)=> item.amount >= 0 ? 'text-green text-end fixed-width' : 'text-red text-end fixed-width',
                "value": (item) => item.amount ? ToCustomDecimal(item.amount) +' kr' : ""
            },
            vatAmount :{
                'label': i18n.t('modelLabel.vatAmount'),
                'sortable': true,
                "sortField":'vatAmount',
                'value': (item) => item.vatAmount || "",
            },
            description: {
                'label': i18n.t('modelLabel.description'),
                'sortable': true,
                "sortField":'description',
                'value': (item) => item.description || '',
            },
            status: {
                'label': status,
                'sortable': true,
                "sortField":'status',
                'value': (item) => item.status === 'Initialiserad' ? initialize : item.status === 'Avbruten' ? aborted : item.status === 'Betald' ? paid : '' || "",
            },
            displayStatus: {
                'label': status,
                'sortable': true,
                "sortField":'status',
                'value': (item) => item.displayStatus === 'Initialiserad' ? initialize : item.displayStatus === 'Avbruten' ? aborted : item.displayStatus === 'Betald' ? paid : '' || "",
            },
            createdAt: {
                'label': i18n.t('modelLabel.createdAt'),
                'sortable': true,
                "sortField":'createdAt',
                'value': (item) => item.createdAt ? ChangeDateFormat('dateTime', item.createdAt) : ''|| "",
            },
            createdByName: {
                'label': i18n.t('modelLabel.createdBy'),
                'sortable': true,
                "sortField":'createdByName',
                'value': (item) => item.createdByName || '',
            },
            UpdatedAt: {
                'label': i18n.t('modelLabel.transactionDate'),
                'sortable': true,
                "sortField":'updatedAt',
                'value': (item) => item.updatedAt ? ChangeDateFormat('dateTime', item.updatedAt) : ''|| "",
            },
            paymentMethod: {
                'label': i18n.t('modelLabel.paymentMethod'),
                'sortable': true,
                "sortField":'paymentMethod',
                'value': (item) => item.paymentMethod || '',
            },
            header: {
                'label': i18n.t('modelLabel.header'),
                'sortable': true,
                "sortField":'header',
                'value': (item) => item.header || '',
            },
        };

        return attributes;
    }
}

export default ResponseSwedbankPay;